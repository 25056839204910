import React from 'react';
import { convertToTimeZone } from 'date-fns-timezone';
import { checkServiceOfficeOpenStatus } from 'utils/timeAndDate';
import { ShouldRender } from 'components/ShouldRender';
import { StatusLine } from './components/StatusLine';

export const OfficeAndCallCenterStatus = ({ office }) => {
  const localDate = new Date();
  const seDate = convertToTimeZone(localDate, { timeZone: 'Europe/Stockholm' });
  const officeOpen = checkServiceOfficeOpenStatus(seDate);

  return (
    <>
      <ShouldRender condition={office}>
        <StatusLine 
          prefixIntl="officeIs" 
          opened={officeOpen} 
        />
      </ShouldRender>
    </>
  );
};
