import React from 'react';
import { useLocale } from 'services/user/locale/hook';
import styles from './styles.module.scss';

export const LanguageChangeWrapper = ({ children, newLocale }) => {
  const { setLocale } = useLocale();

  return (
    <span
      onClick={() => setLocale(newLocale)}
      className={styles.locale}
    >
      {children}
    </span>
  );
};
