import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import accessAppImg from 'assets/media/eden/access-app/access-phone-girl.png';
import { Manuals } from './components/Manuals';
import { Download } from './components/Download';

export const AccessAppModal = () => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="accessApp" />

    <Grid.Column>
      <Brikk.Heading
        main
        heading="accessApp"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={accessAppImg}
            alt="Access app"
          />

          <ModalIntro.Text intl="accessAppDescription" />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="list">
        <Manuals />
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="download">
        <Download />
      </Brikk.Heading>
    </Grid.Column>

  </Grid>
);
