import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import { DndProvider } from "react-dnd";
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import {orderBy} from "lodash";
import {usePrevious} from "../../../utils/hooks/usePrevious";

export const DragAndDropContext = createContext(null);

export const DragAndDrop = ({ enabled, draggables, order = [], onChange }) => {
    const [draggablesOrder, setDraggablesOrder] = useState(order);

    const prevEnabled = usePrevious(enabled);

    useEffect(() => {
        if (order.length) {
            setDraggablesOrder(order);
        }
    }, [order]);

    useEffect(() => {
        if (prevEnabled && !enabled) {
            onChange(draggablesOrder);
        }
    });

    const positionedDraggables = useMemo(() =>
        draggables.map((draggable, index) => {
            let position = index;
            if (draggablesOrder.length) {
                const element = draggablesOrder.find(el => el.id === draggable.id);
                if (element) {
                    position = element.position;
                }
            }
            return {
                ...draggable,
                position,
            }
    }), [draggables, draggablesOrder]);

    const orderedDraggables = useMemo(() =>
        orderBy(positionedDraggables, 'position').map((item, index) => item.component(index)
        ), [positionedDraggables]);

    const changePosition = useCallback((prevPos, newPos) => {
        setDraggablesOrder(positionedDraggables.map(draggable => {
            if (draggable.position === prevPos) {
                return { ...draggable, position: newPos};
            }
            if (draggable.position === newPos) {
                return { ...draggable, position: prevPos }
            }

            return draggable;
        }));
    }, [positionedDraggables]);

    return (
        <DndProvider
          backend={MultiBackend}
          options={HTML5toTouch}
        >
            <DragAndDropContext.Provider value={{ changePosition, enabled }}>
                {orderedDraggables}
            </DragAndDropContext.Provider>
        </DndProvider>
    );
};
