const mapSearchParamsToObject = (searchParams) => {
  const result = {};

  searchParams.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const parseQuery = (queryString) => mapSearchParamsToObject(new URLSearchParams(queryString));
