import { useMutation } from 'react-query';
import { HOME_PAGE } from 'global/routes';
import * as ReactRouter from 'react-router-dom';
import { useModal } from 'store/modals';
import { Auth } from 'aws-amplify';
import { useAuth } from 'services/auth/index';
import { completeNewPasswordAPI } from './api';

export function useCompleteNewPassword() {
  const history = ReactRouter.useHistory();

  const { setUser, setIsAuthenticated } = useAuth();
  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (confirmNewPasswordData) => completeNewPasswordAPI(confirmNewPasswordData),
    {
      onSuccess: () => {
        openModal({
          modalType: 'PopupModal',
          modalProps: {
            title: 'confirmNewPasswordModalTitleSuccess',
            description: 'confirmNewPasswordModalTitleDescriptionSuccess',
          },
        });
        Auth.signOut().then(() => {
          setIsAuthenticated(false);
          setUser(null);
          history.replace(HOME_PAGE);
        });
      },
      onError: (error, confirmNewPasswordData) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'confirmNewPasswordModalTitleError',
          description: 'confirmNewPasswordModalTitleDescriptionError',
          showActions: true,
          onRetry: () => mutate(confirmNewPasswordData),
        },
      }),
    },
  );

  return {
    completeNewPassword: mutate,
    isLoading,
    isError,
  };
}
