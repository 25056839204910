import { all } from 'redux-saga/effects';
import { userAvatarEffects } from 'services/user/avatar/effects';
import { bankIDLoginEffects } from 'services/auth/bank-id-login/effects';
import { confirmEmailEffects } from 'services/auth/confirm-email/effects';
import { loginEmailEffects } from 'services/auth/login-email/effects';
import { initPassResetEffects } from 'services/auth/init-pass-reset/effects';
import { resetPassEffects } from 'services/auth/reset-pass/effects';
import { floorPlanEffects } from 'services/living-info/floor-plan/effects';
import { livingInfoEffects } from 'services/living-info/living-info/effects';
import { localeEffects } from 'services/user/locale/effects';
import { bankIDSigningEffects } from 'services/auth/bank-id-signing/effects';
import { bankIDOrderStatusEffects } from 'services/auth/bank-id-order-status/effects';

export function* rootSaga() {
  yield all([
    ...userAvatarEffects,
    ...bankIDLoginEffects,
    ...bankIDSigningEffects,
    ...confirmEmailEffects,
    ...loginEmailEffects,
    ...initPassResetEffects,
    ...resetPassEffects,
    ...floorPlanEffects,
    ...livingInfoEffects,
    ...localeEffects,
    ...bankIDOrderStatusEffects,
  ]);
}
