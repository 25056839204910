import { TenantConfig } from 'global/tenant-config/tenantConfig';

export const getTenantsByCity = (city) => {
  const tenants = [];
  for (const [key, tenant] of Object.entries(TenantConfig)) {
    if (tenant.city && tenant.city === city) {
      tenants.push(key);
    }
  }
  return tenants;
};
