import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Ref, Message } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Text } from 'components/typography';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { PasswordInput } from 'components/form-elements/PasswordInput';
import { ShouldRender } from 'components/ShouldRender';
import { useTheme } from 'global/themes';
import { useSearchQuery } from 'utils/url';
import { registerValidationSchema } from 'utils/validation/schemas';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useCompleteNewPassword } from 'services/auth/complete-new-password';
import { useEmailLogin } from 'services/auth/email-login';
import styles from './styles.module.scss';

export const Register = () => {

  const searchQuery = useSearchQuery();

  const { completeNewPassword, isLoading } = useCompleteNewPassword();

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(registerValidationSchema),
    mode: 'onSubmit',
  });

  const userData = watch();

  const { emailLogin, isLoading: isEmailLoginLoading, isError, error } = useEmailLogin({
    onSuccess: (user) => {
      const completeNewPasswordData = {
        user,
        newPassword: userData.newPassword,
      };
      completeNewPassword(completeNewPasswordData);
    },
  });

  const { theme } = useTheme();
  const intl = useIntl();

  const submitRef = useRef(null);

  const onSubmit = () => {
    emailLogin(searchQuery);
  };

  return (
    <BasicPageLayout intlTitle="changePassword">
      <Form
        size="large"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          element="h1"
          size={32}
          intl="welcome"
        />

        <div className={styles.subtitle}>
          <Text
            size={16}
            gray
            intl="setNewPassword"
          />
        </div>

        <Controller
          defaultValue=""
          name="newPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.newPassword && 'error')}
              fluid
              id="new-password"
              name="new-password"
              label={intl.formatMessage({ id: 'newPassword' })}
              placeholder={intl.formatMessage({ id: 'newPassword' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <Controller
          defaultValue=""
          name="confirmNewPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.confirmNewPassword && 'error')}
              fluid
              id="confirm-new-password"
              name="confirm-new-password"
              label={intl.formatMessage({ id: 'confirmNewPassword' })}
              placeholder={intl.formatMessage({ id: 'confirmNewPassword' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <ShouldRender condition={!!errors.newPassword || !!errors.confirmNewPassword || isError}>
          <Message negative>
            <Message.Content>
              <FormattedMessage 
                id={errors.newPassword?.message || errors.confirmNewPassword?.message || error?.message}
                defaultMessage=""
              />
            </Message.Content>
          </Message>
        </ShouldRender>

        <Ref innerRef={submitRef}>
          <Button
            loading={isEmailLoginLoading || isLoading}
            name="change-password"
            type="submit"
            size="large"
            floated="right"
          >
            <FormattedMessage id="send" />
          </Button>
        </Ref>
      </Form>
    </BasicPageLayout>
  ); 
};

