import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { useLocale } from 'services/user/locale/hook';
import { MenusItem } from '../MenusItem';

export const Menus = () => {
  const { locale } = useLocale();
  const isEnglish = locale === 'en-US';

  return (
    <>
      <Brikk.Heading heading="list" />

      <Grid
        twoEqualColumns
        style={{
          gridGap: 15,
          padding: 5,
        }}
      >
        <MenusItem
          intl="bcEdenKonferensprislista"
          name={isEnglish ? 'BC Eden Konferensprislista.pdf' : 'BC Eden Konferensprislista.pdf'}
        />
      </Grid>
    </>
  
  ); 
};
