import React from 'react';
import { Text } from 'components/typography/Text';
import { useWeather } from 'services/weather';
import { ForecastItem } from '../ForecastItem';
import { getIconForWeather } from '../../../WeatherSlide';
import styles from './styles.module.scss';

export const HourlyForecast = () => {

  const { weather: { temperature, weatherConditions, isDay, hourly } } = useWeather();

  const filteredHours = [];

  const skipHours = 2; // 1 for no skipping
  const showItems = 7;

  // Skipping the first item as it will be added manually, composed of current weather data.
  // Selecting hourly items based on defined skip value.
  for (let i = skipHours - 1; i < hourly.length; i += skipHours) {
    filteredHours.push(hourly[i]);
  }

  return (
    <div className={styles.HourlyForecast}>
      <Text
        className={styles.heading}
        intl="today"
        center
        medium
        uppercase
      />

      <div className={styles.itemsWrapper}>
        {/* Current weather item */}
        <ForecastItem
          labelIntl="now"
          icon={getIconForWeather(weatherConditions, isDay)}
          temp={temperature}
          key="now"
        />

        { filteredHours.map((item, index) => {
          // We have one already so -1:
          if (index < showItems - 1) {
            return (
              <ForecastItem
                label={item.hour}
                icon={getIconForWeather(item.weatherCondition, item.isDay)}
                temp={item.temperature}
                key={index}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
