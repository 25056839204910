import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { createContainer } from "unstated-next";
import { useTenantId } from 'services/user/tenant/hook';
import { getUserAvatar, uploadUserAvatar } from "./actions";

export const useUserAvatarState = () => {
  const { avatar, loading, error } = useSelector(state => state.userAvatar);
  const { tenantId } = useTenantId();

  const dispatch = useDispatch();

  useEffect(() => {
    if (tenantId) {
      dispatch(getUserAvatar());
    }
  }, [tenantId, dispatch]);

  return {
    avatar,
    loading,
    error,
    uploadUserAvatar: (avatar) => dispatch(uploadUserAvatar(avatar))
  }
};

const useAvatar = createContainer(useUserAvatarState);

export const UserAvatarProvider = useAvatar.Provider;
export const useUserAvatar = useAvatar.useContainer;
