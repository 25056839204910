import { Auth } from 'aws-amplify';
import { getRealEstateIdBasedOnURL } from 'utils/real-estate-config';

export function emailLoginAPI(userData) {
  return Auth.signIn({
    username: userData.username,
    password: userData.password,
    validationData: { realEstateId: getRealEstateIdBasedOnURL() },
  });
}
