import React from 'react';
import { createContainer } from 'unstated-next';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useHistory } from 'react-router-dom';
import { getUserTenantRole } from 'utils/auth-token/getUserTenantRole';
import { useAuth } from 'services/auth';

export function useTenant() {
  const [tenantId, setTenantId] = React.useState(getTenantIDBasedOnURL());
  const [tenantRole, setTenantRole] = React.useState();

  const { userTenants } = useAuth();
  const { listen } = useHistory();

  React.useEffect(() => {
    const unlisten = listen((location) => {
      setTenantId(getTenantIDBasedOnURL(userTenants));
    });

    async function getRole() {
      if (tenantId) {
        const role = await getUserTenantRole(tenantId);
        setTenantRole(role);
      }
    }
    getRole();

    return unlisten;
  }, [listen, tenantId, userTenants]);

  return {
    tenantId,
    setTenantId,
    tenantRole,
    setTenantRole,
  };
}

const tenant = createContainer(useTenant);

export const TenantProvider = tenant.Provider;
export const useTenantId = tenant.useContainer;
