/*
* User's apartment info
 */

import { GetLivingInfoActions } from './actions';

const initialState = {
  livingInfo: {},
  loading: false,
  error: '',
  calledOnce: false,
};

export function livingInfoReducer(state = initialState, action) {
  switch (action.type) {
    case GetLivingInfoActions.GET_LIVING_INFO:
      return {
        ...state,
        loading: true,
        error: '',
        calledOnce: true,
      };
    case GetLivingInfoActions.GET_LIVING_INFO_SUCCESS:
      return {
        ...state,
        livingInfo: action.payload,
        loading: false,
      };
    case GetLivingInfoActions.GET_LIVING_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
