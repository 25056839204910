import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { LanguagePicker } from 'components/user-components/LanguagePicker';

export const LanguageSettings = () => (
  <div className={styles.languageSettings}>

    <div className={styles.headingWrapper}>
      <Text
        element="h3"
        thin
        className={styles.heading}
        medium
        uppercase
        white
        intl="language"
      />
    </div>

    <LanguagePicker column />
  </div>
);
