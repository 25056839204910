import { useQuery } from 'react-query';
import { getAssetsAPI } from 'services/assets/get-assets/api';
import { useTenant } from 'services/user/tenant/hook';

const ASSETS = 'assets';

export function useAssets() {
  const { tenantId } = useTenant();
  const { data, isLoading, isError } = useQuery(
    [ASSETS, tenantId],
    () => getAssetsAPI(),
  );

  return {
    assets: data ? data.assets : [],
    isLoading,
    isError,
  };
}
