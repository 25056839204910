import React from "react";
import { Draggable } from "components/drag-and-drop";
import { Brikk } from "components/Brikk";
import { SeeMoreButton } from "components/form-elements/buttons/SeeMoreButton";
import { Image } from "components/media/Image";
import { ReadingIcon } from "assets/icons/brikks/ReadingIcon";
import brikkPreviewSrc from "./assets/mobility-brikk-preview.png";

export const Mobility = ({ position }) => {
  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={true}>
          <Brikk.Header icon={<ReadingIcon />} heading="mobilityBrikk" />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton disabled={true} brikkNameIntl="mobility" />
          </Brikk.Button>
        </Brikk>
      </Draggable>
    </>
  );
};
