import React from 'react';
// eslint-disable-next-line max-len
import { GeneralInfoOptions } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/tabOptions/generalInfoOptions';
// eslint-disable-next-line max-len
import { OfficeOptions } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/tabOptions/officeOptions';

import { MultiTabMenu } from 'components/MultiTabMenu';

export const HQWorkspaceInfo = () => {
  return (
    <MultiTabMenu
      defaultOfficeTabMenu={OfficeOptions[3]}
      defaultOfficeTabSubMenu={null}
      defaultGeneralTabMenu={OfficeOptions[1]}
      defaultGeneralTabSubMenu={0}
      defaultTab='yourOffice'
      tabs={{
        yourOffice: { title: 'workplaceInfo', menus: OfficeOptions },
        generalInfo: { title: 'generalInfo', menus: GeneralInfoOptions },
      }}
    />
  );
};