import { FloorPlanActions } from './actions';

const initialState = {
  floorPlan: '',
  loading: false,
  error: '',
  calledOnce: false,
};

export function floorPlanReducer(state = initialState, action) {
  switch (action.type) {
    case FloorPlanActions.GET_FLOOR_PLAN:
      return {
        ...state,
        loading: true,
        error: '',
        calledOnce: true,
      };
    case FloorPlanActions.GET_FLOOR_PLAN_SUCCESS:
      return {
        ...state,
        floorPlan: action.payload,
        loading: false,
      };
    case FloorPlanActions.GET_FLOOR_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
