import { REAL_ESTATE_ID } from 'global/environment';
import { RealEstates } from 'global/real-estate-config/realEstates';
import jwtDecode from 'jwt-decode';
import { getIdToken } from 'utils/auth-token/token';

export async function getUserTenants() {
  const idToken = await getIdToken();
  const tenantsRole = Object.keys(JSON.parse(jwtDecode(idToken).tenantsRole));

  const tenant = Object.entries(RealEstates).find(([key]) => key.toLocaleLowerCase() === REAL_ESTATE_ID);
  const userTenants = tenant?.[1]?.subTenants?.filter(item => tenantsRole.includes(item)) || [];  
  
  return userTenants;
}
