import React from 'react';
import { useTheme } from 'global/themes';
import classNames from 'classnames';
import { Dashboard } from 'components/Dashboard';
import { WelcomeScreenGuard } from 'components/guards/WelcomeScreenGuard';
import { useUserSettings } from 'services/user/settings';
import { useTenantConfig } from 'global/tenant-config';
import styles from './styles.module.scss';

export const PageContainer = ({ children }) => {
  const { theme } = useTheme();
  const { background } = useUserSettings();
  const { tenantConfig: { backgrounds } } = useTenantConfig();

  return (
    <div
      className={classNames(styles.pageContainer, theme.pageStyles)}
      style={{ backgroundImage: `url(${backgrounds[background]?.background})` }}
    >
      <Dashboard>
        <WelcomeScreenGuard>
          {children}
        </WelcomeScreenGuard>
      </Dashboard>
    </div>
  );
};
