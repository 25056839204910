import React, { useContext } from 'react';
import { Dropdown } from 'components/Dropdown';
import { MultiTabContext } from 'components/MultiTabMenu';
import { Text } from 'components/typography/Text';

export const TabMenu = ({ item, subMenus, disabled, subIntl }) => {
  const {
    activeMenu,
    setActiveMenu,
    activeSubMenu,
    setActiveSubMenu,
  } = useContext(MultiTabContext);

  const handleChangeMenu = () => {
    if (subMenus.length === 0) {
      setActiveMenu(item);
      setActiveSubMenu(null);
    }
  };

  const handleChangeSubmenu = (index) => {
    setActiveMenu(item);
    setActiveSubMenu(index);
  };

  return (
    <Dropdown key={item.menu}>
      <Dropdown.Item
        disabled={disabled}
        intl={item.menu}
        active={activeMenu?.menu === item.menu}
        onClick={() => handleChangeMenu()}
      />

      <Dropdown.Content>
        {subMenus.map((sub, index) => (
          <Dropdown.SubItem
            key={sub.title}
            onClick={() => handleChangeSubmenu(index)}
            disabled={sub.content === ''}
          >
            <Text
              intl={sub.title}
              size={16}
              disabled={sub?.content === null}
              bold={
                activeMenu?.menu === item.menu ? activeSubMenu === index : false
              }
            />
          </Dropdown.SubItem>
        ))}
        {
          subIntl
            && (
            <Text
              intl={subIntl}
              size={16}
            />
            )
        }
      </Dropdown.Content>
    </Dropdown>
  );
};
