import React, { useState, useEffect } from 'react';
import { useAuth } from 'services/auth';
import { Text } from 'components/typography';
import { FormattedMessage } from 'react-intl';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { Dropdown } from 'components/Dropdown';
import { setApiUrl, setTenant, REAL_ESTATE_ID } from 'global/environment';
import { getTenantsCities, getTenantsByCity } from 'utils/tenant-config';
import { useRedirect } from 'utils/redirect';
import styles from './styles.module.scss';

export const HomePage = () => {
  const [activeMenuItem, setActiveMenuItem] = useState('');

  const { userTenants } = useAuth();
  const tenantsCities = getTenantsCities();
  const { onRedirect } = useRedirect();

  const [chooseTenant, setChooseTenant] = useState();

  useEffect(() => {
    if (chooseTenant) {
      onRedirect(`/${chooseTenant}`);
    }
  }, [chooseTenant, onRedirect]);

  const handleChooseTenant = (tenant) => {
    setApiUrl(tenant);
    setTenant(tenant);
    setChooseTenant(tenant);
  };

  const handleMenuChange = (city) => {
    setActiveMenuItem(activeMenuItem !== city ? city : '');
  };

  return (
    <BasicPageLayout intlTitle={REAL_ESTATE_ID}>
      <Text
        element="h1"
        size={42}
        className={styles.title}
      >
        <FormattedMessage
          id="chooseProperty"
          values={{
            span: (...chunks) => (
              <Text
                element="span"
                size={42}
                primary
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>

      <Text
        gray
        xl
        intl="choosePropertyDescription"
      />

      <div className={styles.propertyOptions}>
        {tenantsCities.map((city, i) => (
          <Dropdown key={i}>
            <Dropdown.Item
              intl={city}
              onClick={() => handleMenuChange(city)}
              active={activeMenuItem === city}
            />

            <Dropdown.Content>
              {
                //eslint-disable-next-line
                getTenantsByCity(city)?.map((item, i) => { if (userTenants.includes(item)) return (
                  <Text
                    uppercase
                    underline
                    size={16}
                    key={i}
                    clickable
                    onClick={() => handleChooseTenant(item)}
                  >
                    {item}
                  </Text>
                );
                })
              }
            </Dropdown.Content>
          </Dropdown>
        ))}
      </div>
    </BasicPageLayout>
  );
};
