import { authorize, getRequest, putRequest } from "utils/http";
import { AWS_API_URL } from "global/environment";



export async function getUserAvatarAPI() {
  const url = `${AWS_API_URL}/user/avatar`;
  return getRequest(url, await authorize());
}

export async function uploadUserAvatarGetSignedUrl(avatar) {
  const url = `${AWS_API_URL}/user/avatar`;
  return putRequest(
    url,
    avatar,
    await authorize({
      headers: { 'content-type': avatar.type },
    }));
}

export async function uploadUserAvatarAPI(avatar) {
  const signedUrl = await uploadUserAvatarGetSignedUrl(avatar);
  return putRequest(
    signedUrl.url,
    avatar,
    {
      headers: { 'content-type': avatar.type },
    });
}
