import React from "react";

export const ClockHandIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.996"
      viewBox="0 0 20 19.996"
    >
      <g transform="translate(0 -0.002)">
        <path
          d="M18.226,2.443A8.331,8.331,0,0,0,4.251,10.353a.833.833,0,0,0,1.617-.4,6.666,6.666,0,1,1,11.182,3.1.833.833,0,1,0,1.178,1.178,8.332,8.332,0,0,0,0-11.783Z"
          transform="translate(-0.668 0)"
        />
        <path
          d="M13.416,18.833h-7.6a.521.521,0,1,1,0-1.042h2.1a.833.833,0,0,0,.766-1.161A1.862,1.862,0,0,0,6.97,15.5H3.417A.417.417,0,0,0,3,15.917v5.416a.417.417,0,0,0,.417.417h8.969a1.865,1.865,0,0,0,1.863-1.862v-.221A.833.833,0,0,0,13.416,18.833Z"
          transform="translate(-0.5 -2.584)"
        />
        <path
          d="M.833,14.751A.833.833,0,0,0,0,15.584v6.04a.833.833,0,1,0,1.666,0V15.583a.833.833,0,0,0-.833-.832Z"
          transform="translate(0 -2.459)"
        />
        <path
          d="M17.166,8.5h-2.5V4.333a.833.833,0,1,0-1.666,0v5a.833.833,0,0,0,.833.833h3.333a.833.833,0,0,0,0-1.666Z"
          transform="translate(-2.168 -0.583)"
        />
      </g>
    </svg>
  );
};
