import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';

export const ShoppingRestaurantsGyms = () => (
  <>
    <Text size={16}>
      <FormattedMessage
        id="shoppingRestaurantsGymsDescription"
        values={{ 
          bold: (...chunks) => (
            <span style={{ fontWeight: 700 }}>
              {chunks}
            </span>
          ),
        }}
      />

      <FormattedMessage
        id="shoppingRestaurantsGymsDescription2"
        values={{
          a: (...chunks) => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.hyllie.com/projekt/point-hyllie,-annehem-bygg-projekt-ab.aspx?tag=Kontor"
            >
              {chunks}
            </a>
          ),
          bold: (...chunks) => (
            <span style={{ fontWeight: 700 }}>
              {chunks}
            </span>
          ),
        }}
      />
    </Text>
  </>
);
