import { AWS_API_URL } from 'global/environment';
import { authorize, putRequest } from 'utils/http';

export async function updateUserSettingsAPI(userSettings) {
  const url = `${AWS_API_URL}/user/settings`;

  if (userSettings.brikksOrder) {
    userSettings.brikksOrder = JSON.stringify(userSettings.brikksOrder);
  }

  return putRequest(url, userSettings, await authorize());
}
