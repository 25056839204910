import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { SlideIcon } from 'assets/icons/SlideIcon';
import { ReactComponent as HumidityIcon } from 'assets/icons/weatherConditions/humidity.svg';
import { ReactComponent as WindIcon } from 'assets/icons/weatherConditions/wind_cloud.svg';
import { ReactComponent as ShowerRainIcon } from 'assets/icons/weatherConditions/shower_rain.svg';
import { WeatherSlide, getIconForWeather } from '../../../WeatherSlide';
import styles from './styles.module.scss';

function WeatherSlider({ 
  weather: { temperature, chanceOfPrecipitation, weatherConditions, isDay, windSpeed, humidity }, 
}) {
  const city = 'Lund';

  return (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={0.6}
      totalSlides={4}
      currentSlide={0}
      className={styles.WeatherCarousel}
    >
      <div className={styles.location}>
        {city}
      </div>

      <Slider>
        <Slide index={0}>
          <WeatherSlide
            icon={getIconForWeather(weatherConditions, isDay)}
            value={temperature}
            valuePrefix="°C"
            label={weatherConditions}
            small
          />
        </Slide>

        <Slide index={1}>
          <WeatherSlide
            icon={<ShowerRainIcon />}
            value={chanceOfPrecipitation}
            valuePrefix="%"
            label="chanceOfPrecipitation"
            small
          />
        </Slide>

        <Slide index={2}>
          <WeatherSlide
            icon={<WindIcon />}
            value={windSpeed}
            valuePrefix="m/s"
            label="windSpeed"
            small
          />
        </Slide>

        <Slide index={3}>
          <WeatherSlide
            icon={<HumidityIcon />}
            value={humidity}
            valuePrefix="%"
            label="humidity"
            small
          />
        </Slide>
      </Slider>

      <ButtonBack>
        <SlideIcon direction="left" />
      </ButtonBack>

      <ButtonNext>
        <SlideIcon />
      </ButtonNext>

      <DotGroup />
    </CarouselProvider>
  );
}

export default WeatherSlider;
