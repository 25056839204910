import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import airSrc from 'assets/media/eden/my-workspace/air.png';
import waterSrc from 'assets/media/eden/my-workspace/water.png';
import nourishmentSrc from 'assets/media/eden/my-workspace/nourishment.png';
import lightSrc from 'assets/media/eden/my-workspace/light.png';
import movementSrc from 'assets/media/eden/my-workspace/movement.png';
import thermalSrc from 'assets/media/eden/my-workspace/thermal.png';
import soundSrc from 'assets/media/eden/my-workspace/sound.png';
import materialsSrc from 'assets/media/eden/my-workspace/materials.png';
import mindSrc from 'assets/media/eden/my-workspace/mind.png';
import communitySrc from 'assets/media/eden/my-workspace/community.png';
import innovationsSrc from 'assets/media/eden/my-workspace/innovations.png';
import styles from './styles.module.scss';
 
export const Well = () => (
  <>
    <Text size={16}>
      <FormattedMessage
        id="wellDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    </Text>
    
    <div className={styles.sectionContainer}>
      <SemanticImage
        src={airSrc}
        alt="Air"
        styles={{ width: '100%' }}
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionAir"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={waterSrc}
        alt="Water"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionWater"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={nourishmentSrc}
        alt="Nourishment"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionNourishment"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={lightSrc}
        alt="Light"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionLight"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>
        
    <div className={styles.sectionContainer}>
      <SemanticImage
        src={movementSrc}
        alt="Movement"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionMovement"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={thermalSrc}
        alt="Thermal"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionThermalComfort"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={soundSrc}
        alt="Sound"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionSound"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={materialsSrc}
        alt="Materials"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionMaterials"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={mindSrc}
        alt="Mind"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionMind"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={communitySrc}
        alt="Community"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionCommunity"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>

    <div className={styles.sectionContainer}>
      <SemanticImage
        src={innovationsSrc}
        alt="Innovations"
      />
      <Text size={16}>
        <FormattedMessage
          id="wellDescriptionInnovations"
          values={{
            bold: (...chunks) => (
              <Text
                size={16}
                element="span"
                bold
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text> 
    </div>

    <div className={styles.sectionContainer}>
      <Text
        size={22}
        element="h3"
        uppercase
        intl="wellDescriptionPsykiska"
      /> 

      <Text
        size={16}
      >
        <FormattedMessage
          id="wellDescriptionPsykiskaLink"
          values={{
            a: (...chunks) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.1177.se/Vastra-Gotaland/sjukdomar--besvar/psykiska-sjukdomar-och-besvar/"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </Text> 

      <Text
        size={22}
        element="h3"
        uppercase
        intl="wellDescriptionDatorarbetsplats"
      /> 

      <Text
        size={16}
      >
        <FormattedMessage
          id="wellDescriptionDatorarbetsplatsLink"
          values={{
            a: (...chunks) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medarbetarportalen.gu.se/digitalAssets/1405/1405487_datorarbete_checklista.pdf"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </Text> 
    </div>
  </>
);
