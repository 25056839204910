import React from 'react';
import { ReactComponent as HumidityIcon } from 'assets/icons/weatherConditions/humidity.svg';
import { ReactComponent as WindIcon } from 'assets/icons/weatherConditions/wind_cloud.svg';
import { ReactComponent as ShowerRainIcon } from 'assets/icons/weatherConditions/shower_rain.svg';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { useWeather } from 'services/weather';
import { WeatherSlide, getIconForWeather } from '../WeatherSlide';
import { HourlyForecast, WeekForecast } from './components';

export const WeatherModal = () => {

  const { weather: { weatherConditions, isDay, temperature, chanceOfPrecipitation, windSpeed, humidity } } = useWeather();

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="weather" />

      <Grid.Column>
        <Brikk.Heading
          main
          heading="weather"
        >

          <br /><br />

          <WeatherSlide
            icon={getIconForWeather(weatherConditions, isDay)}
            value={temperature}
            valuePrefix="°C"
            label={weatherConditions}
            large
          />
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="prognosis">

          <br /><br />
          <br /><br />

          <WeekForecast />

          <br /><br />
          <br /><br />

          <HourlyForecast />

        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="weatherData">

          <br />
          <br />

          <WeatherSlide
            icon={<ShowerRainIcon />}
            value={chanceOfPrecipitation}
            valuePrefix="%"
            label="chanceOfPrecipitation"
            medium
          />

          <br />
          <br />

          <WeatherSlide
            icon={<WindIcon />}
            value={windSpeed}
            valuePrefix="m/s"
            label="windSpeed"
            medium
          />

          <br />
          <br />

          <WeatherSlide
            icon={<HumidityIcon />}
            value={humidity}
            valuePrefix="%"
            label="humidity"
            medium
          />
        </Brikk.Heading>
      </Grid.Column>
    </Grid>
  );
};
