import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { Text } from 'components/typography';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { BrikkContext } from 'components/Brikk/index';

export const Header = ({ icon, heading }) => {
  const { disabled } = useContext(BrikkContext);

  return (
    <Card.Content
      className={classNames(
        styles.brikkHeader, {
        [styles.disabled]: disabled,
      },
      )}
    >
      <div className={styles.icon} >
        {icon}
      </div>

      <div className={styles.textContainer}>
        <Text
          bold
          element="h3"
          size={12}
          intl={heading}
          className={styles.heading}
        />
      </div>
    </Card.Content>
  );
};
