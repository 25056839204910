import { REAL_ESTATE_ID } from 'global/environment';
import { RealEstates } from 'global/real-estate-config/realEstates';
import { TenantConfig } from 'global/tenant-config/tenantConfig';

export const getTenantsCities = () => {
  const cities = [];
  const tenant = Object.entries(RealEstates).find(([key]) => key.toLocaleLowerCase() === REAL_ESTATE_ID);
  const subTenants = tenant?.[1]?.subTenants;

  for (const [tenantId, tenant] of Object.entries(TenantConfig)) {
    if (subTenants.includes(tenantId)
     && tenant.city && cities.indexOf(tenant.city) === -1) {
      cities.push(tenant.city);
    }
  }

  return cities;
};
