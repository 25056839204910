import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ClickableIcon } from 'assets/icons/ClickableIcon';

export const HamburgerMenu = ({ size = 24, className, onClick }) => (
  <ClickableIcon
    size={size}
    height={17}
    onClick={onClick}
    className={className}
  >
    <svg
      style={{ width: size }}
      className={classNames(styles.hamburgerMenu, className)}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="2.2"/>
      <rect y="7" width="24" height="2.2"/>
      <rect y="14" width="24" height="2.2"/>
    </svg>
  </ClickableIcon>
);
