import React from 'react';
import { NavLink as Link } from 'react-router-dom';

export const ConditionalLink = ({ children, to, activeClassName, condition }) => (condition ? (
  <Link 
    activeClassName={activeClassName} 
    to={to}
  >
    {children}
  </Link>
)
  : (
    <>{children}</>
  ));
