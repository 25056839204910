import React, { useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Heading } from 'components/typography/Heading';
import { Text } from 'components/typography/Text';
import contactUsImgSrc from 'assets/media/contact-us.png';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { Button } from 'components/form-elements/buttons/Button';
import { Form } from 'components/layout/Form';
import { useForm, Controller } from 'react-hook-form';
import { TextArea } from 'components/form-elements/textareas/TextArea';
import { useUserDetails } from 'services/user/get-details';
import { useSubmitContactInfo } from 'services/contact-us/submit-contact-us';
import { useLocale } from 'services/user/locale/hook';
import { Radio } from 'components/form-elements/Radio';
import contactImage from 'assets/media/eden/contact-us-sm.png';
import { useIntl } from 'react-intl';
import { LinkModal } from 'components/modals/LinkModal';
import { ReportIssueModal } from 'components/brikks/Office/ReportIssue/components/ReportIssueModal';
import { ShouldRender } from 'components/ShouldRender';
import { REAL_ESTATE_ID } from 'global/environment';
import { WG1 } from 'global/real-estate-config/realEstates';
import { ContactUsAction } from './components/ContactUsAction';
import styles from './styles.module.scss';
import { ContactInformation } from './components/ContactInformation';

export const ContactUsModal = ({ setIsModalOpen }) => {
  const { userDetails } = useUserDetails();
  const [contactVia, setContactVia] = useState(userDetails?.workNumber ? 'phoneNumber' : 'email');
  const { isLoading, submitContactInfo } = useSubmitContactInfo({
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });
  const { locale } = useLocale();
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (contactInfo) => {
    const contactInfos = {
      ...contactInfo,
      language: locale,
      [contactVia]:
        contactVia === 'phoneNumber'
          ? userDetails.workNumber
          : userDetails[contactVia],
    };
    submitContactInfo(contactInfos);
  };

  const intl = useIntl();

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="contactUs" />

      <Grid.Column>
        <Brikk.Heading 
          main 
          heading="contactUs"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={contactUsImgSrc}
              alt="Man with headset working in a call center"
            />

            <ModalIntro.Text intl="contactUsDescription" />
            
            <ShouldRender condition={REAL_ESTATE_ID !== WG1}>
              <LinkModal
                intl="goToReportIssue"
                buttonTriggerModal={ContactUsAction}
                brikkModal={ReportIssueModal}
                modalHeading="reportIssue"
              />
            </ShouldRender>
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="writeUs">
          <Text 
            intl="writeUsDescription" 
            large 
          />
          <br />
          <br />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextArea
                  onChange={field.onChange}
                  inputRef={field.ref}
                  rows={5}
                />
              )}
            />

            <Heading className={styles.subTitle}>
              <Text 
                intl="getInTouchLabel" 
                uppercase 
                xxl1 
              />
            </Heading>
            <Text 
              intl="contactedVia" 
              large 
            />
            <br />

            <Radio
              checked={contactVia === 'phoneNumber' && !!userDetails.workNumber}
              onChange={() => !!userDetails.workNumber && setContactVia('phoneNumber')}
              name="contact"
              className={styles.toggle}
              toggle
              label={intl.formatMessage({ id: 'phone' })}
              disabled={!userDetails.workNumber}
            />

            <Radio
              checked={contactVia === 'email'}
              onChange={() => setContactVia('email')}
              name="contact"
              className={styles.toggle}
              toggle
              label={intl.formatMessage({ id: 'email' })}
            />
            <div className={styles.send}>
              <Button
                loading={isLoading}
                intl="send"
                disabled={!formState.isValid}
              />
            </div>
          </Form>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="giveUsACall">
          <ContactInformation 
            office 
            imgSrc={contactImage} 
          />
        </Brikk.Heading>
      </Grid.Column>
    </Grid>
  );
};
