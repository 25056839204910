export function downloadDocument(name, url) {
  fetch(url).then(response => (
      response.blob()
        .then(blob => {
        const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.setAttribute('download', name);
          downloadLink.click();
      })
    ));
}
