import React from 'react';
import { Image } from 'components/media/Image';
import DisabledDownloadBadge from 'assets/media/download-disabled.png';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography';
import styles from './styles.module.scss';

export const Download = () => (
  <>
    <div className={styles.downloadBadges}>
      <Image src={DisabledDownloadBadge} className={styles.icon} />
      <Text bold xl>
        <FormattedMessage id='comingSoon' />
      </Text>
    </div>
  </>
);
