import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { OfficeAndCallCenterStatus } from 'components/brikks/sub-components/OfficeAndCallCenterStatus';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const ContactInformation = ({ office, imgSrc }) => (
  <div className={styles.contactInformation}>
    <div className={styles.imageWrapper}>
      <CircularImage
        className={styles.image}
        src={imgSrc}
        alt="My workspace image"
      />
      <OfficeAndCallCenterStatus office={office} />
    </div>

    <div>
      <Text 
        element="span" 
        large
      >
        <FormattedMessage
          id="serviceDeskThirdColumnDescription"
          values={{
            number: (...chunks) => <a href={`tel:${chunks}`}>{chunks}</a>,
            bold: (...chunks) => (
              <Text 
                element="span" 
                bold 
                large
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </div>
  </div>
);
