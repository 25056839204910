/* eslint-disable import/no-mutable-exports */
import { getRealEstateIDBasedOnURL, getTenantIDBasedOnURL } from 'global/tenant-config';

export let TENANT_ID = getTenantIDBasedOnURL() || process.env.REACT_APP_TENANT_ID;
export const setTenant = (tenantId) => { TENANT_ID = tenantId; };

export let AWS_API_URL = `${process.env.REACT_APP_awsApiUri}/${TENANT_ID}`;
export const setApiUrl = (tenantId) => { AWS_API_URL = `${process.env.REACT_APP_awsApiUri}/${tenantId}`; };

export const REAL_ESTATE_ID = getRealEstateIDBasedOnURL() || process.env.REACT_APP_REAL_ESTATE_ID;
export const AWS_REGION = process.env.REACT_APP_region;
export const USER_POOL = process.env.REACT_APP_userPool;
export const CLIENT_ID = process.env.REACT_APP_clientId;
export const BID_AGGREGATOR_KEY = '';
export const BID_AGGREGATOR_BASE_URL = '';
export const BID_AGGREGATOR_PROVIDER = '';
export const BID_AGGREGATOR_POLICY = '';
export const GA_TRACKING = '';
