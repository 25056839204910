import React from 'react';
import { Text } from 'components/typography';
import { UploadIcon } from 'assets/icons/UploadIcon';
import styles from './styles.module.scss';

export const UploadButton = ({ onClick, loading = false, error = false, fillBlue }) => (
  <div className={styles.buttonGroup}>
    <UploadIcon
      onClick={onClick}
      loading={loading}
      error={error}
      fillBlue={fillBlue}
    />

    <Text intl="upload" />
  </div>
);
