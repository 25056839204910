import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const TextButton = ({ intl, children, onClick, className }) => (
  <button 
    className={classNames(styles.button, className)} 
    onClick={onClick}
  >
    {intl ? <FormattedMessage id={intl} /> : children }
  </button>
);
