import { authorize, putRequest } from "utils/http";
import {AWS_API_URL} from "global/environment";
import { getUserAttributesFromToken } from 'utils/local-storage/attributes';

export async function getLocaleAPI() {
  const userAttributes = await getUserAttributesFromToken();
  return userAttributes.locale;
}

export async function updateLocaleAPI(locale) {
  const url = `${AWS_API_URL}/user/attributes`;
  return putRequest(url, locale, await authorize());
}
