import React, { useCallback, useEffect, useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { NewsIcon } from 'assets/icons/brikks/NewsIcon';
import { useRSSFeeds } from 'services/rss-feeds/get-rss-feeds';
import { RSSFeedPreview, RSSFeedModal, AddRSSFeedModal } from './components';

export const RSSFeed = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const { rssFeeds, isLoading } = useRSSFeeds();

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedArticle(null);
    }
  }, [isModalOpen, setSelectedArticle]);

  const onSelectArticle = useCallback((article) => {
    setSelectedArticle(article);
    setIsModalOpen(true);
  }, [setSelectedArticle, setIsModalOpen]);

  return (
    <>
      <Draggable position={position}>
        <Brikk 
          isLoading={isLoading}
          brikkKey={code} 
          name={name} 
          status={status}
        >
          <Brikk.Header
            icon={<NewsIcon />}
            heading="myNewsBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <RSSFeedPreview
              feed={rssFeeds}
              onSelectArticle={onSelectArticle}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              disabled={!rssFeeds?.length}
              onClick={handleOpenModal}
              brikkNameIntl="myNewsInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        twoGrid
        isOpen={isModalOpen}
        heading="news"
        onClose={() => setIsModalOpen(false)}
      >
        {rssFeeds?.length ? (
          <RSSFeedModal
            feed={rssFeeds}
            preSelectedArticle={selectedArticle}
          />
        ) : <AddRSSFeedModal />}
      </BrikkModal>
    </>
  );
};
