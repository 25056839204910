import React from 'react';
import { DocumentPreview } from 'components/media/DocumentPreview';

export const PDFPreview = ({ url, name, downloadName, showInDocuments }) => (
  <DocumentPreview 
    url={url} 
    name={name} 
    downloadName={downloadName}
  >
    <DocumentPreview.DocumentTitle type="pdf" />
    <DocumentPreview.Preview />

    <DocumentPreview.Actions
      download
      view
      showInDocuments={showInDocuments}
    />
  </DocumentPreview>
);
