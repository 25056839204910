import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { PageLoader } from 'components/loaders/PageLoader';
import { Text } from 'components/typography';
import { SearchInput } from 'components/form-elements/inputs/SearchInput';
import { useUsers } from 'services/admin/get-users';
import { UserItem } from './components/UserItem';

export const UsersList = ({ selectedTab }) => {

  const { users, isFetching } = useUsers();
  const [keyword, setKeyword] = useState('');

  const intl = useIntl();

  const initialUsers = users?.filter(
    user => (selectedTab === 'active' ? user.userStatus === 'active' : user.userStatus !== 'active'),
  ).sort(
    (a, b) => -b.firstName?.localeCompare(a.firstName),
  );

  const data = !keyword ? initialUsers : initialUsers.filter(
    user => (`${user.firstName} ${user.lastName}`).toLowerCase().includes(keyword.toLowerCase()),
  );

  if (isFetching) return <PageLoader />;

  return (
    <>
      <SearchInput
        placeholder={intl.formatMessage({ id: 'search' })}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      {
        data && data.length > 0
          ? data.map((user, index) => (
            <UserItem
              key={index}
              user={user}
            />
          ))
          : (
            <Text
              secondary
              center
              intl="noUsersToDisplay"
              size={16}
            />
          )
      }
    </>
  );
};
