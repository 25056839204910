const mapArticle = (article, feed, feedId) => ({
  title: article.title,
  link: article.link,
  datePublished: new Date(article.datePublished),
  description: article.description,
  image: article.image,
  feed,
  feedId,
});

export const mapDataForClient = (feed) => ({
  id: feed.id,
  default: feed.default,
  url: feed.url,
  title: feed.title,
  articles: feed.articles ? feed.articles.map(article => mapArticle({
    ...article,
    feed: feed.title,
    feedId: feed.id,
  })) : [],
});
