import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { BankIDLogo } from 'components/bank-id/BankIDLogo';
import { PrimaryButton } from 'components/form-elements/buttons/PrimaryButton';
import { MultiStepContext } from 'components/MultiStep';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';

export const BankIDPersonalNumber = ({ onProceed, loading, name, initSuccess }) => {
  const [personalNumber, setPersonalNumber] = useState('');

  const { onNextStep } = useContext(MultiStepContext);

  useEffect(() => {
    if (initSuccess) {
      onNextStep();
    }
  }, [initSuccess, onNextStep])

  return (
    <div className={styles.bankIDPersonalNumber}>

      <BankIDLogo className={styles.logo} centered />

      <LabeledInput
        labelIntl="personalNumber"
        autoFocus
        placeholder="ÅÅÅÅMMDDXXXX"
        value={personalNumber}
        onChange={e => setPersonalNumber(e.target.value)}
      />

      <div className={styles.button}>
        <PrimaryButton
          intl="mobile"
          name={name}
          loading={loading}
          disabled={loading || personalNumber.trim().length <= 10}
          onClick={() => onProceed(personalNumber)}
        />
      </div>
    </div>
  )
}
