import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const InfoIcon = ({ width = 36, gray }) => (
  <svg
    className={styles.icon}
    style={{ width }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  >
    <path className={styles.hole} d="M0,0H36V36H0Z"/>
    <path className={classNames(styles.info, gray && styles.gray)}
          d="M17.04,2a15.04,15.04,0,1,0,15.04,15.04A15.045,15.045,0,0,0,17.04,2Zm1.5,22.559H15.536V15.536h3.008Zm0-12.032H15.536V9.52h3.008Z"
          transform="translate(0.96 0.96)"/>
  </svg>
);
