import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { MyInfoIcon } from 'assets/icons/brikks/MyInfoIcon';
import { useUserDetails } from 'services/user/get-details';
import { MyProfileModal } from './components/MyProfileModal';
import { MyProfilePreview } from './components/MyProfilePreview';
 
export const MyProfile = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loading } = useUserDetails();

  return (
    <>
      <Draggable position={position}>
        <Brikk isLoading={loading}>
          <Brikk.Header
            icon={<MyInfoIcon />}
            heading="myInfoBrikk"
          />

          <Brikk.Content>
            <MyProfilePreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="myInfoInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="myInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <MyProfileModal />
      </BrikkModal>
    </>
  );
};
