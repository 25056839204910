import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Card, Message, Placeholder } from 'semantic-ui-react';
import { Avatar } from './components/Avatar';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { ShouldRender } from 'components/ShouldRender';
import { useUserDetails } from "services/user/get-details";

export const Profile = ({ main }) => {
  const { userDetails, loading } = useUserDetails();
  const [avatarError, setAvatarError] = useState('');

  const { theme } = useTheme();

  return (
    <div>
      <Brikk.Heading main={main} heading="userProfile">
        <ShouldRender condition={avatarError}>
          <Message
            className={styles.errorMessage}
            negative
          >
            <Message.Content>
              <FormattedMessage id={avatarError} />
            </Message.Content>
          </Message>
        </ShouldRender>

        {loading ? (
          <Placeholder>
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
          </Placeholder>
        ) : (
          <Card className={styles.card}>
            <Avatar onError={setAvatarError} />

            <div className={theme.iconBackgroundSecondaryColor}>
              <div className={classNames(theme.textColor, styles.profileInfo)}>
                <Text xl bold>
                  {userDetails?.givenName} {userDetails.familyName}
                </Text>

                <Text xl>
                  {userDetails?.email}
                </Text>

                <Text xl>
                  {userDetails?.workNumber}
                </Text>
              </div>
            </div>
          </Card>
        )}
      </Brikk.Heading>
    </div>
  )
};
