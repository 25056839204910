import React from "react";
import { Draggable } from "components/drag-and-drop";
import { Brikk } from "components/Brikk";
import { SeeMoreButton } from "components/form-elements/buttons/SeeMoreButton";
import { Image } from "components/media/Image";
import { DoorEntryIcon } from "assets/icons/brikks/DoorEntryIcon";
import brikkPreviewSrc from "components/brikks/Office/AccessControl/assets/access-brikk-preview.png";

export const AccessControl = ({ position }) => {
  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={true}>
          <Brikk.Header icon={<DoorEntryIcon />} heading="accessControlBrikk" />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton disabled={true} brikkNameIntl="accessControl" />
          </Brikk.Button>
        </Brikk>
      </Draggable>
    </>
  );
};
