import React, { useState } from 'react';
import { BrikkModal } from '../BrikkModal';

export const LinkModal = ({
  buttonTriggerModal,
  intl,
  buttonText,
  modalHeading,
  isDisabled,
  brikkModal,
  isActive,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const TriggerModalComponent = buttonTriggerModal;
  const BrikkModalComponent = brikkModal;

  return (
    <>
      <TriggerModalComponent
        onClick={() => setIsModalOpen(true)}
        disabled={isDisabled}
        intl={intl}
        isActive={isActive}
        selectedItem={null}
      >
        {buttonText}
      </TriggerModalComponent>
      <BrikkModal
        isOpen={isModalOpen}
        heading={modalHeading}
        onClose={() => setIsModalOpen(false)}
      >
        <BrikkModalComponent />
      </BrikkModal>
    </>
  );
};
