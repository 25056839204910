import { useMemo } from 'react';
import { useUserSettings, useUpdateUserSettings } from 'services/user/settings';
import { useTenantConfig } from 'global/tenant-config';
import flatTheme from './flat.module.scss';
import darkTheme from './dark.module.scss';
import transparentTheme from './transparent.module.scss';

export const FLAT_THEME = 'light';
export const TRANSPARENT_THEME = 'transparent';
export const DARK_THEME = 'dark';

function processThemeColors(themeColors) {
  for (const [key, value] of Object.entries(themeColors)) {
    if (value) {
      document.documentElement.style.setProperty(`--color--${key}`, value.toString());
    }
  }
}

function processThemeSizes(themeSizes) {
  for (const [key, value] of Object.entries(themeSizes)) {
    if (value) {
      document.documentElement.style.setProperty(`--size--${key}`, value.toString());
    }
  }
}

export function useTheme() {
  const { theme } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings();
  const { tenantConfig } = useTenantConfig();

  const themeStyles = useMemo(() => {
    switch (theme) {
      case FLAT_THEME:
        return flatTheme;
      case DARK_THEME:
        return darkTheme;
      case TRANSPARENT_THEME:
        return transparentTheme;
      default:
        return darkTheme;
    }
  }, [theme]);

  const images = useMemo(() => {
    const images = {
      logo: tenantConfig.assets.logoWhite,
      toolUnderline: './brikks-icons/inner/tool_underline_dark.png',
    };
    switch (theme) {
      case FLAT_THEME:
        images.logo = tenantConfig.assets.logoDark;
        images.toolUnderline = './brikks-icons/inner/tool_underline.png';
        processThemeColors(tenantConfig.themes.solid.colors);
        processThemeSizes(tenantConfig.sizes);
        break;
      case TRANSPARENT_THEME:
        images.logo = tenantConfig.assets.logoDark;
        images.toolUnderline = './brikks-icons/inner/tool_underline.png';
        processThemeColors(tenantConfig.themes.transparent.colors);
        processThemeSizes(tenantConfig.sizes);
        break;
      case DARK_THEME:
        processThemeColors(tenantConfig.themes.dark.colors);
        processThemeSizes(tenantConfig.sizes);
        break;
      default:
        return images;
    }
    return images;
  }, [theme, tenantConfig]);

  return {
    theme: themeStyles,
    currentTheme: theme,
    setTheme: (theme) => updateUserSettings({ theme }),
    images,
  };
}
