import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { UsersIcon } from 'assets/icons/brikks/UsersIcon';
import { BrikkModal } from 'components/modals/BrikkModal';
import { UserAdministrationPreview, UserAdministrationModal } from './components';

export const UserAdministration = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Draggable position={position}>
        <Brikk>
          <Brikk.Header
            icon={<UsersIcon />}
            heading="userAdminBrikk"
          />

          <Brikk.Content center>
            <UserAdministrationPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="userAdminInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkModal
        isOpen={isModalOpen}
        heading="reportIssueInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <UserAdministrationModal />
      </BrikkModal>
    </>
  ); 
};
