export const RealEstates = {
  KUNGSLEDEN: {
    aliases: ['kungsleden'],
    realEstateId: 'kungsleden',
    subTenants: ['hq', 'eden'],
  },
  WG1: {
    aliases: ['work.brikks', 'wg1'],
    realEstateId: 'wg1',
    subTenants: ['viaeuropa', 'consulence', 'brikks'],
  },
};

export const WG1 = 'wg1';
export const KUNGSLEDEN = 'kungsleden';
