import React from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Dashboard } from 'components/Dashboard';
import { ONBOARDING_PAGE } from 'global/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useUserSettings } from 'services/user/settings';
import { useRedirect } from 'utils/redirect';
import { AppHeader } from 'components/headers/AppHeader';
import { useUserDetails } from 'services/user/get-details';
import { useTenantId } from 'services/user/tenant/hook';
import styles from './styles.module.scss';

export const Onboarding = ({ children }) => {
  const { onboardingPassed } = useUserSettings();
  const { tenantId } = useTenantId();

  useRedirect(`/${tenantId}`, onboardingPassed);
  // useRedirect('/', false);
  // TODO: Uncomment this value and comment in the one before to see the onboarding screens when you are logged IN
  // Do the same here src/components/guards/OnboardingGuard/index.js

  useUserDetails();

  return (
    <Dashboard>
      <IntlPageTitle intlTitle="introduction" />

      <AppHeader />

      <div className={styles.onboarding}>
        <Route
          path={ONBOARDING_PAGE}
          render={() => (
            <Switch>
              {children}

              <Redirect to={`/${tenantId}/onboarding/welcome}`} />
            </Switch>
          )}
        />
      </div>
    </Dashboard>
  );
};
