import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocale } from 'services/user/locale/hook';
import enUS from 'date-fns/locale/en-US';
import sv from 'date-fns/locale/sv';
import { ENGLISH, SWEDISH } from 'services/user/locale/utils';
import styles from './styles.module.scss';

registerLocale(ENGLISH, enUS);
registerLocale(SWEDISH, sv);

export const DatePicker = ({
  id,
  selectedDate,
  minDate,
  maxDate,
  minTime,
  maxTime,
  isOpen,
  isInline,
  onChange,
  dateFormat,
  timeFormat,
  showTimeSelect = false,
  showTimeInput = false,
  label,
  ...props
}) => {
  const { locale } = useLocale();

  return (
    <ReactDatePicker
      calendarContainer={({ className, children }) => (
        <div
          className={`${className} ${styles.datePicker}`}
        >
          {children}
        </div>
      )}
      className={styles.picker}
      open={isOpen}
      inline={isInline}
      id={id}
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      minTime={minTime}
      maxTime={maxTime}
      onChange={onChange}
      dateFormat={dateFormat}
      timeFormat={timeFormat}
      showTimeSelect={showTimeSelect}
      showTimeInput={showTimeInput}
      locale={locale}
      {...props}
    />
  ); 
};
