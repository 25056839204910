import React from 'react';
import classNames from 'classnames';
import { SwedishFlag } from 'assets/icons/SwedishFlag';
import { EnglishFlag } from 'assets/icons/EnglishFlag';
import { SWEDISH, ENGLISH } from 'services/user/locale/utils';
import { useLocale } from 'services/user/locale/hook';
import { Radio } from 'components/form-elements/Radio';
import { LanguageChangeWrapper } from './components/LanguageChangeWrapper';
import styles from './styles.module.scss';

export const LanguagePicker = ({ column }) => {
  const { locale, setLocale } = useLocale(); 

  return (
    <div className={classNames(
      styles.languagePicker, {
        [styles.column]: column,
      },
    )}
    >
      <div className={styles.option}>
        <LanguageChangeWrapper newLocale={SWEDISH}>
          <SwedishFlag />
        </LanguageChangeWrapper>

        <Radio
          toggle
          name="swedish"
          checked={locale === SWEDISH}
          onChange={() => setLocale(SWEDISH)}
        />
      </div>

      <div className={styles.option}>
        <LanguageChangeWrapper newLocale={ENGLISH}>
          <EnglishFlag />
        </LanguageChangeWrapper>

        <Radio
          toggle
          name="english"
          checked={locale === ENGLISH}
          onChange={() => setLocale(ENGLISH)}
        />
      </div>
    </div>
  );
};
