const mapCategory = (category) => {
  switch (category) {
    case 'office':
      return 'Office';
    case 'openSpace':
      return 'Open Space';
    case 'conferenceRoom':
      return 'Conference Room';
    default:
      return null;
  }
};

export const mapDataForBackend = (report) => ({
  description: report.description,
  attachments: report.attachments.map((item) => ({ data: item.fileContent, filename: item.filename })),
  email: report.email,
  phoneNumber: report.phoneNumber,
  language: report.language,
  category: mapCategory(report.category),
  ...(report.category === 'office' && {
    company: report.company,
    floor: report.floor,
    confirmationEmail: report.confirmationEmail
  }),
});
