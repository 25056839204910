import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import * as SemanticUI from 'semantic-ui-react';

export const Input = ({
  id,
  value,
  name,
  className,
  onChange,
  disabled,
  placeholder,
  autoFocus,
  hasError,
  children,
  inputRef,
}) => (
  <div className={classNames(styles.inputContainer, className)}>
    <SemanticUI.Input
      className={classNames(styles.input, hasError && styles.error)}
      error={hasError}
      ref={inputRef}
      id={id}
      value={value}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
      onChange={onChange}
    />

    {children}
  </div>
);

