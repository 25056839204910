import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { ClockHandIcon } from 'assets/icons/brikks/ClockHandIcon';
import { useTenant } from 'services/user/tenant/hook';
import { BrikkModal } from 'components/modals/BrikkModal';
import { ResourceBookingModal } from './components/ResourceBookingModal';
import { ResourceBookingPreview } from './components/ResourceBookingPreview';

export const ResourceBooking = ({ position }) => {
  const { tenantId } = useTenant();
  const isTenantHQ = tenantId === 'hq';
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={isTenantHQ}>
          <Brikk.Header
            icon={<ClockHandIcon />}
            heading="bookSpaceBrikk"
          />

          <Brikk.Content center>
            <ResourceBookingPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              disabled={isTenantHQ}
              brikkNameIntl="bookSpaceInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      
      <BrikkModal
        isOpen={isModalOpen}
        heading="bookResource"
        onClose={() => setIsModalOpen(false)}
      >
        <ResourceBookingModal />
      </BrikkModal>
    </>
  ); 
};
