import React from 'react';
import styles from './styles.module.scss';
import { useTenantConfig } from 'global/tenant-config';
import { MultiGridItem } from './components/MultiGridItem';

export const MultiGrid = () => {
  const { tenantConfig } = useTenantConfig();
  return (
    <div className={styles.multiGrid}>
      {tenantConfig.multiGrids.map((multiGrid) => (
        <MultiGridItem 
          key={multiGrid} 
          grid={multiGrid} 
        />
      ))}
    </div>
  );
};
