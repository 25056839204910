import React, { useState } from 'react';
import FormErrors from "components/messages/FormErrors";
import Validate from "utils/FormValidation";
import { Auth } from "aws-amplify";
import { Form, Button } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';

export const ForgotPassword = (props) => {
    const [email, setEmail] = useState('');

    const [cognitoError, setCognitoError] = useState(null);
    const [blankfieldError, setBlankfieldError] = useState(false);

    const intl = useIntl();

    const { theme } = useTheme();

    const onEmailChange = event => {
        setEmail(event.target.value);
        document.getElementById(event.target.id).closest('.field').classList.remove("error");
    };

    const forgotPasswordHandler = async event => {
        event.preventDefault();

        // Form validation
        setCognitoError(null);
        setBlankfieldError(false);
        Validate(event, { email, setBlankfieldError });

        // AWS Cognito integration
        try {
            await Auth.forgotPassword(email);
            props.history.push('/forgot-pass-verify');
        } catch (error) {
            let err = null;
            !error.message ? err = { "message": error } : err = error;
            setCognitoError(err);
        }
    };

    return (
        <BasicPageLayout intlTitle="forgotPassword">
            <Form size='large' onSubmit={forgotPasswordHandler}>
                <Text
                    element="h1"
                    size={32}
                    intl="newPassword"
                />

                <div className={styles.subtitle}>
                    <Text
                        size={16}
                        gray
                        intl="forgotPasswordSubtitle"
                    />
                </div>

                <FormErrors formerrors={{ cognitoError, blankfieldError }} />

                <Form.Input
                    className={theme.textColor}
                    fluid
                    id="email"
                    name="email"
                    label={intl.formatMessage({ id: 'email' })}
                    icon='mail'
                    iconPosition='left'
                    type="email"
                    placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={onEmailChange}
                />

                <div className={styles.buttons}>
                    <a href='/login'
                        onClick={(e) => {
                            e.preventDefault();
                            props.history.push('/login')
                        }}
                    >
                        <FormattedMessage id="backToLogin" />
                    </a>

                    <Button
                        type='submit'
                        size='large'
                        name="send-recovery-code"
                    >
                        <FormattedMessage id="sendCode" />
                    </Button>
                </div>
            </Form>
        </BasicPageLayout>
    );
};
