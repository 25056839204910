const mapUserData = (user) => ({
  firstName: user['First name'],
  lastName: user['Last name'],
  email: user.Email,
  role: user.Role,
  organization: user.Organization,
}
);

export const mapDataForBackend = (users) => ({
  users: users.map(user => mapUserData(user)),
});
