import { useQueries } from 'react-query';
import { getReportFileAPI } from './api';

const REPORT_FILE = 'reportFile';

export function useReportFiles(report) {
  const reportFiles = useQueries(
    report?.files?.map(file => ({
      queryKey: [REPORT_FILE, file.id],
      queryFn: () => getReportFileAPI(file.id),
    })) ?? [],
  );

  return {
    reportFiles,
    isLoading: reportFiles.some(r => r.isLoading),
  };
}
