import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import workoutTogetherSrc from 'assets/media/eden/my-workspace/workout-together.jpeg';
import styles from '../styles.module.scss';

export const WorkOutTogether = () => (
  <>
    <SemanticImage
      src={workoutTogetherSrc}
      alt="Work out together"
      className={styles.image}
    />

    <Text size={16}>
      <FormattedMessage
        id="workOutTogetherDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
          email: (...chunks) => (
            <a
              href={`mailto:${chunks}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        }}
      />
    </Text>
  </>
);
