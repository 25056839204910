import React from "react";
import myHomeImgSrc1 from "assets/media/hyllie.png";
import { Image as SemanticImage } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Text } from "components/typography/Text";

export const Restaurant = () => {
  return (
    <>
      <SemanticImage src={myHomeImgSrc1} alt="Restaurant" />
      <Text size={16}>
        <FormattedMessage
          id="restaurantDescription"
          values={{
            bold: (...chunks) => (
              <Text size={16} element="span" bold>
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </>
  );
};
