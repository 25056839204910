import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { FLAT_THEME } from 'global/themes';
import { createContainer } from 'unstated-next';
import { TenantConfig } from 'global/tenant-config/tenantConfig';
import { useUpdateUserSettings } from 'services/user/settings/update-user-settings';
import { REAL_ESTATE_ID } from 'global/environment';
import { defaultBrikksOrder } from './utils';
import { getUserSettingsAPI } from './api';

const USER_SETTINGS = 'user-settings';

export function useUserSettingsState() {
  const { tenantId } = useTenantId();
  const { updateUserSettings } = useUpdateUserSettings();

  const { data, isFetching } = useQuery(
    [USER_SETTINGS, tenantId],
    () => getUserSettingsAPI(),
    { enabled: tenantId !== null,
      onSuccess: (data) => {
        if (!data.background && tenantId) {
          updateUserSettings({ background: TenantConfig[tenantId].initialBackground });
        }
      },
    },
  );

  return {
    theme: data?.theme || FLAT_THEME,
    background: data?.background || TenantConfig[REAL_ESTATE_ID]?.initialBackground,
    onboardingPassed: data?.onboardingPassed || TenantConfig,
    brikksOrder: data?.brikksOrder || defaultBrikksOrder,
    loading: isFetching,
  };
}

const userSettings = createContainer(useUserSettingsState);

export const UserSettingsProvider = userSettings.Provider;
export const useUserSettings = userSettings.useContainer;
