import { useMutation } from 'react-query';
import { emailLoginAPI } from './api';

export function useEmailLogin({ onSuccess }) {

  const { mutate, isLoading, isError, error } = useMutation(
    (userData) => emailLoginAPI(userData),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
    },
  );

  return {
    emailLogin: mutate,
    isLoading,
    isError,
    error,
  };
}
