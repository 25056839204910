import React from 'react';
import { PDFIcon } from 'assets/icons/PDFIcon';
import { ExcelIcon } from 'assets/icons/ExcelIcon';
import { Text } from 'components/typography';
import { UploadExcel } from 'components/form-elements/UploadExcel';
import { DownloadButton } from 'components/form-elements/buttons/DownloadButton';
import { downloadDocument } from 'utils/download';
import { useUsersTemplate } from 'services/assets/get-users-tempate';
import { useAdminInviteUsers } from 'services/admin/invite-users';
import styles from './styles.module.scss';

export const AddUsersActions = () => {
  const { usersTemplate, isLoading } = useUsersTemplate();
  const { adminInviteUsers, loading } = useAdminInviteUsers();

  return (
    <div className={styles.actions}>
      <div className={styles.actionItem}>
        <PDFIcon
          className={styles.icon}
          size={32}
          fillBlue
        />

        <Text intl="downloadManual" />
 
        <DownloadButton disabled />
      </div>

      <div className={styles.actionItem}>
        <ExcelIcon className={styles.icon} />

        <Text intl="downloadExcelSheet" />

        <DownloadButton
          fillBlue
          loading={isLoading}
          onClick={() => downloadDocument(usersTemplate?.name, usersTemplate?.url)}
        />
      </div>

      <div className={styles.actionItem}>
        <ExcelIcon className={styles.icon} />

        <Text intl="uploadExcelSheet" />

        <UploadExcel
          onClick={adminInviteUsers}
          loading={loading}
        />
      </div>
    </div>
  ); 
};
