import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { useTenantId } from 'services/user/tenant/hook';
import { disableUserAPI } from './api';

const USERS = 'users';

export function useDisableUser({ onSuccess }) {
  const queryClient = useQueryClient();
  const { tenantId } = useTenantId();

  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (userId) => disableUserAPI(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS, tenantId]);
        onSuccess();
      },

      onError: (error, user) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'adminDisableUserModalTitleError',
          description: 'adminDisableUserModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(user),
        },
      }),
    },
  );

  return {
    disableUser: mutate,
    isLoading,
    isError,
  };
}
