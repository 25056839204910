import React from "react";
import {Message} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function FormErrors(props) {
    if (
        props.formerrors &&
        (props.formerrors.blankfieldError || props.formerrors.passwordMatchError
          || props.formerrors.invalidPasswordError || props.formerrors.invalidCurrentPasswordError)
    ) {
        return (
            <Message negative>
                <Message.Content> {props.formerrors.passwordMatchError
                    ? <FormattedMessage id="passwordNoMatch" />
                    : ''}
                </Message.Content>
                <Message.Content> {props.formerrors.blankfieldError
                  ? <FormattedMessage id="fieldsRequired" />
                  : ''}
                </Message.Content>
                <Message.Content> {props.formerrors.invalidPasswordError
                  ? <FormattedMessage id="invalidPassword" />
                  : ''}
                </Message.Content>
                <Message.Content> {props.formerrors.invalidCurrentPasswordError
                  ? <FormattedMessage id="invalidCurrentPassword" />
                  : ''}
                </Message.Content>
            </Message>
        );
    } else if (props.apierrors) {
        return (
            <Message negative>
                <Message.Content>{props.apierrors}</Message.Content>
            </Message>
        );
    } else if (props.formerrors && props.formerrors.cognitoError) {
      if (props.formerrors.cognitoError.name === 'NotAuthorizedException') {
        return (
          <Message negative>
            <Message.Content><FormattedMessage id="invalidCurrentPassword" /></Message.Content>
          </Message>
        )
      }
      if (props.formerrors.cognitoError.name === 'UserNotFoundException') {
        return (
          <Message negative>
            <Message.Content><FormattedMessage id="emailNotRegistered" /></Message.Content>
          </Message>
        )
      }
        return (
            <Message negative>
                <Message.Content> {props.formerrors.cognitoError.message} </Message.Content>
            </Message>
        );
    } else {
        return <div/>;
    }
}

export default FormErrors;
