import React from 'react';
import { Button } from 'semantic-ui-react';
import { Grid } from 'components/layout/Grid';
import { useHistory } from 'react-router-dom';
import { Profile } from 'components/user-components/Profile';
import { FormattedMessage } from 'react-intl';
import { UserDetails } from 'components/user-components/UserDetails';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import styles from './styles.module.scss';

export const MyProfileModal = () => {
  const history = useHistory();

  return (
    <Grid
      brikkLayout
      twoColumns
    > 
      <IntlPageTitle intlTitle="myInfo" />

      <Grid.Column>
        <Profile main />

        <Button
          className={styles.changePass}
          content={<FormattedMessage id="changePassword" />}
          onClick={() => history.push('/change-pass')}
        />
      </Grid.Column>

      <Grid.Column justifySelf="center">
        <UserDetails />
      </Grid.Column>
    </Grid>
  );
};
