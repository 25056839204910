import React from 'react';
import { MultiTabMenu } from 'components/MultiTabMenu';
import { ReportIssueMenus } from '../../menus';

export const ReportIssueInfo = ({ activeMenu }) => {
  const defaultMenu = ReportIssueMenus.filter(item => item.menu === activeMenu)[0];

  return (
    <MultiTabMenu
      title="chooseArea"
      subTitle="chooseAreaSubTitle"
      menus={ReportIssueMenus}
      defaultMenu={defaultMenu}
    />
  ); 
};
