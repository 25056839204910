/* eslint-disable max-len */
import kglWelcome from 'assets/media/kgl/welcome.png';
import kglLogoWhite from 'assets/media/kgl/logo-white.svg';
import kglLogoDark from 'assets/media/kgl/logo-dark.svg';
import kglMyWorkspaceImg from 'assets/media/kgl/my-workspace.png';
import kglMyWorkspaceExpended from 'assets/media/kgl/my-workspace/my-workspace.png';
import kglFavicon from 'assets/media/kgl/favicon.ico';
import wg1Welcome from 'assets/media/wg1/welcome.png';
import wg1Favicon from 'assets/media/wg1/favicon.ico';
import edenLogoDark from 'assets/media/eden/logo-dark.svg';
import consulenceLogo from 'assets/media/consulence/logo.svg';
import viaeuropaLogo from 'assets/media/viaeuropa/logo.svg';
import brikksLogo from 'assets/media/brikks/logo.svg';
import edenMyWorkspaceImg from 'assets/media/eden/my-worspace.jpg';
import edenMyWorkspaceExpended from 'assets/media/eden/my-workspace/my-worspace.jpg';
import hqEnglish from 'translations/hq/english.json';
import hqSwedish from 'translations/hq/swedish.json';
import edenEnglish from 'translations/eden/english.json';
import edenSwedish from 'translations/eden/swedish.json';
import viaeuropaEnglish from 'translations/viaeuropa/english.json';
import viaeuropaSwedish from 'translations/viaeuropa/swedish.json';
import consulenceEnglish from 'translations/consulence/english.json';
import consulenceSwedish from 'translations/consulence/swedish.json';
import brikksEnglish from 'translations/brikks/english.json';
import brikksSwedish from 'translations/brikks/swedish.json';
import hqThemes from 'global/tenant-themes/hq-themes.json';
import edenThemes from 'global/tenant-themes/eden-themes.json';
import consulenceThemes from 'global/tenant-themes/consulence-themes.json';
import viaeuropaThemes from 'global/tenant-themes/viaeuropa-themes.json';
import brikksThemes from 'global/tenant-themes/brikks-themes.json';
import { HQOnboarding } from 'pages/onboarding/HQOnboarding';
import { EDENOnboarding } from 'pages/onboarding/EDENOnboarding';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { Tenants } from 'global/tenant-config/tenants';
import { GREEN_BG, EDEN_BLUE_BG, EDEN_GREEN_BG, EDEN_YELLOW_BG, EDEN_ORANGE_BG, WG1_BG, CONSULENCE_BG, VIAEUROPA_BG, BRIKKS_BG } from 'services/user/settings/get-user-settings/utils';
import { HQWorkspaceInfo } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/components/HQWorkspaceInfo';
import { EdenWorkspaceInfo } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/components/EdenWorkspaceInfo';

export const TenantConfig = {
  [Tenants.KUNGSLEDEN.tenantId]: {
    assets: {
      favicon: kglFavicon,
      welcome: kglWelcome,
      logoWhite: kglLogoWhite,
      logoDark: kglLogoDark,
    },
    intl: {
      english: hqEnglish,
      swedish: hqSwedish,
    },
    brikks: [],
    initialBackground: 'kglGreen',
    backgrounds: {
      kglGreen: {
        background: GREEN_BG,
      },
    },
    themes: hqThemes,
    components: {
      onboarding: HQOnboarding,
    },
    multiGrids: [MultiGrid.Office],
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
  },
  [Tenants.HQ.tenantId]: {
    assets: {
      logoWhite: kglLogoWhite,
      favicon: kglFavicon,
      welcome: kglWelcome,
      myWorkspaceImg: kglMyWorkspaceImg,
      myWorkspaceExpended: kglMyWorkspaceExpended,
    },
    intl: {
      english: hqEnglish,
      swedish: hqSwedish,
    },
    components: {
      onboarding: HQOnboarding,
    },
    officeInfo: {
      components: {
        workspaceInfo: HQWorkspaceInfo,
      },
    },
    themes: hqThemes,
    initialBackground: 'hqGreen',
    backgrounds: {
      hqGreen: {
        background: GREEN_BG,
      },
    },
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
    multiGrids: [MultiGrid.Office],
    brikks: ['A01', 'A02', 'A03', 'A04', 'A05', 'A06', 'A07', 'A08', 'A09', 'A10', 'A11', 'A12', 'A13', 'A14', 'A15', 'A20'],
    city: 'Stockholm',
    rpName: 'test-client',
    gaTracking: '',
  },
  [Tenants.EDEN.tenantId]: {
    assets: {
      logoDark: edenLogoDark,
      favicon: kglFavicon,
      welcome: kglWelcome,
      myWorkspaceImg: edenMyWorkspaceImg,
      myWorkspaceExpended: edenMyWorkspaceExpended,
    },
    intl: {
      english: edenEnglish,
      swedish: edenSwedish,
    },
    components: {
      onboarding: EDENOnboarding,
    },
    officeInfo: {
      components: {
        workspaceInfo: EdenWorkspaceInfo,
      },
    },
    initialBackground: 'edenBlue',
    backgrounds: {
      edenBlue: {
        background: EDEN_BLUE_BG,
      },
      edenGreen: {
        background: EDEN_GREEN_BG,
      },
      edenYellow: {
        background: EDEN_YELLOW_BG,
      },
      edenOrange: {
        background: EDEN_ORANGE_BG,
      },
    },
    themes: edenThemes,
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
    multiGrids: [MultiGrid.Office],
    brikks: ['A01', 'A02', 'A05', 'A06', 'A07', 'A09', 'A16', 'A17', 'A18', 'A20'],
    city: 'Malmö',
    rpName: 'test-client',
    gaTracking: '',
  },
  [Tenants.WG1.tenantId]: {
    assets: {
      favicon: wg1Favicon,
      welcome: wg1Welcome,
    },
    initialBackground: 'wg1Beige',
    backgrounds: {
      wg1Beige: {
        background: WG1_BG,
      },
    },
    intl: {
      english: viaeuropaEnglish,
      swedish: viaeuropaSwedish,
    },
    components: {
      onboarding: HQOnboarding,
    },
    brikks: [],
    themes: viaeuropaThemes,
    multiGrids: [],
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
  },
  [Tenants.CONSULENCE.tenantId]: {
    assets: {
      logoDark: consulenceLogo,
      favicon: wg1Favicon,
      welcome: wg1Welcome,
    },
    intl: {
      english: consulenceEnglish,
      swedish: consulenceSwedish,
    },
    components: {
      onboarding: EDENOnboarding,
    },
    initialBackground: 'consulenceGrey',
    backgrounds: {
      consulenceGrey: {
        background: CONSULENCE_BG,
      },
    },
    themes: consulenceThemes,
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
    multiGrids: [MultiGrid.Office],
    brikks: ['A02', 'A09', 'A18', 'A20', 'A21'],
    city: 'Lund',
    rpName: 'test-client',
    gaTracking: '',
  },
  [Tenants.VIAEUROPA.tenantId]: {
    assets: {
      logoDark: viaeuropaLogo,
      favicon: wg1Favicon,
      welcome: wg1Welcome,
    },
    intl: {
      english: viaeuropaEnglish,
      swedish: viaeuropaSwedish,
    },
    components: {
      onboarding: EDENOnboarding,
    },
    initialBackground: 'viaeuropaBrown',
    backgrounds: {
      viaeuropaBrown: {
        background: VIAEUROPA_BG,
      },
    },
    themes: viaeuropaThemes,
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
    multiGrids: [MultiGrid.Office],
    brikks: ['A02', 'A09', 'A18', 'A20', 'A21'],
    city: 'Lund',
    rpName: 'test-client',
    gaTracking: '',
  },
  [Tenants.BRIKKS.tenantId]: {
    assets: {
      logoDark: brikksLogo,
      favicon: wg1Favicon,
      welcome: wg1Welcome,
    },
    intl: {
      english: brikksEnglish,
      swedish: brikksSwedish,
    },
    components: {
      onboarding: EDENOnboarding,
    },
    initialBackground: 'brikksBeige',
    backgrounds: {
      brikksBeige: {
        background: BRIKKS_BG,
      },
    },
    themes: brikksThemes,
    sizes: {
      brikk: '300px',
      'brikks-spacing': '12px',
    },
    multiGrids: [MultiGrid.Office],
    brikks: ['A02', 'A09', 'A18', 'A20', 'A21'],
    city: 'Lund',
    rpName: 'test-client',
    gaTracking: '',
  },
};
