import React, { useEffect } from 'react';
import { useBankIDLogin } from 'services/auth/bank-id-login/hook';
import { useAuth } from 'services/auth';
import { useRedirect } from 'utils/redirect';
import { getUserFromToken } from 'utils/auth-token';
import { BankID } from 'components/bank-id/BankID';

export const BankIDLogin = () => {
  const { setIsAuthenticated, setUser } = useAuth();

  const {
    isInitSuccessful,
    loading,
    error,
    isSuccessful,
    onLoginWithBankID,
  } = useBankIDLogin();

  const { onRedirect } = useRedirect('/');

  useEffect(() => {
    async function loginUser() {
      const userAttributes = await getUserFromToken();
      setIsAuthenticated(true);
      setUser({ attributes: userAttributes });
      onRedirect();
    }

    if (isSuccessful) {
      loginUser();
    }
  }, [isSuccessful, setIsAuthenticated, setUser, onRedirect]);

  return (
    <BankID
      name="bank-id-login"
      onInit={onLoginWithBankID}
      initLoading={loading}
      initSuccess={isInitSuccessful}
      error={error}
    />
  )
};
