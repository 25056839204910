import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { ToolsIcon } from 'assets/icons/brikks/ToolsIcon';
import { useTenantId } from 'services/user/tenant/hook';
import { BrikkModal } from 'components/modals/BrikkModal';
import { StatusPreview } from './components/StatusPreview';
import { StatusModal } from './components/StatusModal';

export const Status = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { tenantId } = useTenantId();
  const isTenantHQ = tenantId === 'hq';

  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={isTenantHQ}>
          <Brikk.Header
            icon={<ToolsIcon />}
            heading="statusBrikk"
          />

          <Brikk.Content center>
            <StatusPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              disabled={isTenantHQ}
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="status"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="reportIssueInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <StatusModal />
      </BrikkModal>
    </>
  );
};
