import React, { useContext } from 'react';
import { Text } from 'components/typography/Text';
import { Image } from 'semantic-ui-react';
import userSrc from 'assets/media/user.png';
import { ADMIN } from 'utils/user/roles';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import { ShouldRender } from 'components/ShouldRender';
// eslint-disable-next-line max-len
import { UserAdministrationModalContext } from 'components/brikks/Office/UserAdministration/components/UserAdministrationModal';
import styles from './styles.module.scss';

export const UserItem = ({ user }) => {

  const { selectedUser, setSelectedUser } = useContext(
    UserAdministrationModalContext,
  );

  return (
    <div
      className={styles.userCard}
      onClick={() => setSelectedUser(user)}
    >
      <div className={styles.details}>
        <Image
          className={styles.img}
          id="user-avatar"
          alt="user-avatar"
          src={user.imageUrl || userSrc}
          size="tiny"
          circular
        />

        <Text
          size={14}
          uppercase
        >
          {user.firstName} {user.lastName} {user.userRole === ADMIN && `(${user.userRole})`}
        </Text>
      </div>

      <ShouldRender condition={selectedUser?.userId === user?.userId}>
        <CheckMarkIcon
          active
        />
      </ShouldRender>
    </div>
  ); 
};
