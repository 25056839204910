import React from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { useTenantConfig } from 'global/tenant-config';
import { ImageContentCard } from 'components/cards/ImageContentCard';
import { AppHeader } from '../../headers/AppHeader';
import styles from './styles.module.scss';

export const BasicPageLayout = ({ children, intlTitle }) => {
  const { tenantConfig } = useTenantConfig();
  return (
    <div className={styles.basicPageLayout}>
      <IntlPageTitle intlTitle={intlTitle} />

      <AppHeader />

      <div className={styles.content}>
        <ImageContentCard imageSrc={tenantConfig.assets.welcome}>
          {children}
        </ImageContentCard>
      </div>
    </div>
  );
};
