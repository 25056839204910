import { format, formatDistanceStrict, isBefore, isFuture } from 'date-fns';

export const daysFormat = 'yyyy-MM-dd';
export const dotFormat = 'dd.MM.yyyy';
export const timeFormat = 'HH:mm';

export const dateMonthNameYear = 'dd LLLL yyyy';
export const dateMonthNameYearTime = 'dd LLLL yyyy HH:mm';

export const dateAndMonthName = 'dd LLLL';

export const hourAndMinute = 'kk:mm';

export const formatDate = (date, dateFormat = daysFormat) => format(date, dateFormat);

export const distanceInWordsStrict = (firstDate, secondDate) => formatDistanceStrict(firstDate, secondDate);

export const isDateBefore = (date, dateToCompare) => isBefore(date, dateToCompare);

export const isDateInFuture = (date) => isFuture(date);
 
export const monthNumberToName = (monthNumber) => {
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  return months[parseInt(monthNumber) - 1];
};

export const monthNameFromStringDate = (stringDate) => monthNumberToName(new Date(stringDate).getMonth() + 1);

const serviceCallCenterHoursByDays = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  2: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  6: null,
};

const serviceOfficeHoursByDays = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  2: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 17,
      m: 0,
    },
  },
  6: null,
};
 
export const checkServiceCallCenterOpenStatus = (date) => {
  if (date.getDay() === 0 || date.getDay() === 6) {
    return false;
  }

  const todayCallCenterOpenTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceCallCenterHoursByDays[date.getDay()].from.h,
    serviceCallCenterHoursByDays[date.getDay()].from.m,
  );

  const todayCallCenterCloseTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceCallCenterHoursByDays[date.getDay()].to.h,
    serviceCallCenterHoursByDays[date.getDay()].to.m,
  );

  if (date > todayCallCenterOpenTime && date < todayCallCenterCloseTime) {
    return true;
  }
};

export const checkServiceOfficeOpenStatus = (date) => {
  if (date.getDay() === 0 || date.getDay() === 6) {
    return false;
  }

  const todayOfficeOpenTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceOfficeHoursByDays[date.getDay()].from.h,
    serviceOfficeHoursByDays[date.getDay()].from.m,
  );

  const todayOfficeCloseTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceOfficeHoursByDays[date.getDay()].to.h,
    serviceOfficeHoursByDays[date.getDay()].to.m,
  );

  if (date > todayOfficeOpenTime && date < todayOfficeCloseTime) {
    return true;
  }
};
