import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { useTenantId } from 'services/user/tenant/hook';
import { useIntl } from 'react-intl';
import { adminInviteUsersAPI } from './api';

const USERS = 'users';

export function useAdminInviteUsers() {
  const queryClient = useQueryClient();
  const { tenantId } = useTenantId();
  const intl = useIntl();

  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (users) => adminInviteUsersAPI(users),
    {
      onSuccess: (data) => {
        const usersInvited = data.users.filter(user => user.succeeded);
        const usersFailed = data.users.filter(user => !user.succeeded);

        openModal({
          modalType: 'PopupModal',
          modalProps: {
            titleIntl: `${intl.formatMessage({ id: 'successfullyUsers' })} ${usersInvited.length}`,
            description: 'adminInviteUsersModalDescriptionSuccess',
            descriptionDetails:
              usersFailed.length > 0 ? `${usersFailed.map((user, index) => `${user.email}${index === usersFailed.length - 1 ? '' : ','}\n`).join('')}` : '/',
            showActions: false,
          },
        });
        queryClient.invalidateQueries([USERS, tenantId]);
      },

      onError: (error, users) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'adminInviteUsersModalTitleError',
          description: 'adminInviteUsersModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(users),
        },
      }),
    },
  );

  return {
    adminInviteUsers: mutate,
    loading: isLoading,
    isError,
  };
}
