import React from 'react';

export const ExcelIcon = ({ size = 32, className }) => (
  <svg
    className={className}
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32.234 36.59"
  >
    <g
      transform="translate(-1673 -855.205)"
    >
      <path
        d="M31.948,8H13.653A.654.654,0,0,0,13,8.653V43.936a.654.654,0,0,0,.653.653h25.7a.654.654,0,0,0,.653-.653V36.967H20.623V22.157H40.007V16.494H32.6a.653.653,0,0,1-.65-.591l0-.063ZM45.234,23.464H21.93v12.2h23.3ZM39.6,26.412a3.648,3.648,0,0,1,.925.115,6.391,6.391,0,0,1,.921.323l-.426,1.025-.238-.095a5.165,5.165,0,0,0-.585-.19,2.643,2.643,0,0,0-.64-.081.849.849,0,0,0-.574.174.576.576,0,0,0-.2.455.564.564,0,0,0,.081.3.9.9,0,0,0,.257.251,8.023,8.023,0,0,0,.836.436,3.321,3.321,0,0,1,1.2.836,1.63,1.63,0,0,1,.323,1.027,1.608,1.608,0,0,1-.606,1.327,2.634,2.634,0,0,1-1.687.485,3.96,3.96,0,0,1-1.761-.374V31.205a7.254,7.254,0,0,0,1.065.4,3.167,3.167,0,0,0,.8.115,1.133,1.133,0,0,0,.666-.166.567.567,0,0,0,.232-.493.545.545,0,0,0-.1-.325,1.132,1.132,0,0,0-.3-.274l-.078-.048q-.177-.106-.538-.281l-.191-.092a3.814,3.814,0,0,1-.855-.515,1.935,1.935,0,0,1-.455-.574,1.64,1.64,0,0,1-.17-.766,1.607,1.607,0,0,1,.559-1.3A2.323,2.323,0,0,1,39.6,26.412ZM27.872,26.5l1.34,2.237L30.527,26.5h1.421L30,29.585l2.1,3.135H30.591l-1.446-2.352L27.7,32.72H26.286l2.063-3.207L26.418,26.5Zm6.325,0v5.13H36.72V32.72H32.879V26.5Zm5.618-11.617-6.56-6.56v6.864h6.732A.653.653,0,0,0,39.816,14.884Z"
        transform="translate(1660 847.205)"
        fill="#0272ce"/>
    </g>
  </svg>
);
