import { Auth } from 'aws-amplify';
import { getRealEstateIdBasedOnURL } from 'utils/real-estate-config';

export function confirmEmailAPI(email) {
  return Auth.signIn({
    username: email, 
    password: '123',
    validationData: { realEstateId: getRealEstateIdBasedOnURL() },
  });
}
