import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'components/form-elements/buttons/Button';
import { useModal } from 'store/modals';
import { Text } from 'components/typography';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const PopupModal = ({ title, titleIntl, description, descriptionIntl, descriptionDetails, 
  showActions, onRetry }) => {
  const { closeModal } = useModal();

  const onTryAgain = () => {
    onRetry();
    closeModal();
  };

  return (
    <Modal
      size="small"
      centered
      dimmer="inverted"
      open
      className={styles.popupModal}
    >
      <Modal.Header>
        <Text
          center
          size={20}
          intl={title}
        >
          {titleIntl}
        </Text>
      </Modal.Header>

      <Modal.Content>
        <Text
          center
          size={18}
          intl={description}
        />

        <Text
          center
          size={18}
        >
          {descriptionDetails}
        </Text>
      </Modal.Content>

      <Modal.Actions className={showActions ? styles.modalActions : styles.modalAction}>
        <ShouldRender condition={showActions}>

          <Button
            intl="cancel"
            onClick={closeModal}
          />

          <Button
            intl="tryAgain"
            onClick={onTryAgain}
          />
        </ShouldRender>

        <ShouldRender condition={!showActions}>
          <Button
            intl="close"
            onClick={closeModal}
          />
        </ShouldRender>
      </Modal.Actions>
    </Modal>
  );
};
