import React from "react";
import { Item } from "./Item";
import { Grid } from "components/layout/Grid";

export const Manuals = () => {
  return (
    <Grid
      twoEqualColumns
      style={{ 
        gridGap: 15, 
        paddingLeft: 5, 
        paddingRight: 5, 
        paddingBottom: 5, 
        paddingTop: 0 
      }}
    >
      <Item
        intl="generalInfoManual"
        name="Välkommen till vårt nya huvudkontor, Hangövägen 20.pdf"
      />
      <Item
        intl="entryfyMobileManual"
        name="Entryfy mobilapplikation app.pdf"
      />
      <Item
        intl="entryfyGuideManual"
        name="Öppningssätt.pdf"
      />
    </Grid>
  );
};
