import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { getOrganizatinsAPI } from './api';

const ORGANIZATIONS = 'organizations';

export function useOrganizations() {
  const { tenantId } = useTenantId();

  const { data, isLoading, isError } = useQuery(
    [ORGANIZATIONS, tenantId],
    () => getOrganizatinsAPI(),
    { enabled: tenantId !== null },
  );

  return {
    organizations: data || [],
    isLoading,
    isError,
  };
}
