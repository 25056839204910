/*
USAGE FOR submitREF:

This functionality allows to press the Enter button to submit a form after you have clicked the EYE button.
It refocuses on the Submit button after a click on the Eye button.

1. Declare: const submitRef = useRef(null);
2. Add: submitRef={submitRef} as a prop in the <PasswordInput /> component
3. import { Ref } from 'semantic-ui-react';
4. Wrap the submit button with <Ref innerRef={submitRef}><button></Ref>

An example can be found in src/pages/auth/ChangePassword/index.js
 */

import React, { useState } from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { EyeIconClosed } from 'assets/icons/EyeIconClosed';
import { EyeIcon } from 'assets/icons/EyeIcon';
import PropTypes from 'prop-types';
 
/**
 * @component Password input field with an eye button to disclose the password to the user
 * @param {number|string} [iconHeight = 24] - Optional
 * @param {string} [eyeButtonOffsetRight = '20px'] - Optional
 * @param {string} [icon] - Icon for the input form from Semantic-UI, not the eye icon.
 * @param submitRef - Required to refocus on the submit button after clicking the reveal(eye) button.
 * Wrap the Submit Button component with the <Ref innerRef={submitRef}> tag from Semantic-UI.
 * @param type - This exists just to catch if "type" is send by mistake.
 * @param props
 * @returns {JSX.Element}
 */
export const PasswordInput = ({ iconHeight = 24, eyePositionRight: eyeButtonOffsetRight = '20px', icon, type, submitRef, ...props }) => {
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const handleToggleShowPassword = event => {
    event.preventDefault();
    setPasswordFieldType(prevState => (prevState === 'password' ? 'text' : 'password'));
    if (submitRef) submitRef.current.focus();
  };

  const button = (
    <Button
      onClick={handleToggleShowPassword}
      type="button"
      style={{
        backgroundColor: 'transparent',
        padding: '0',
        margin: '0',
        position: 'absolute',
        top: `calc(50% - (${iconHeight}px / 2))`,
        right: `${eyeButtonOffsetRight}`,
      }}
    >
      {passwordFieldType === 'password' ? <EyeIconClosed /> : <EyeIcon />}
    </Button>
  );

  return (
    <Form.Input
      type={passwordFieldType}
      {...props}
    >
      <input />
      {icon ? <Icon name={icon} /> : null}
      {button}
    </Form.Input>
  );
};

PasswordInput.propTypes = {
  submitRef: PropTypes.object.isRequired,
};
