// eslint-disable-next-line max-len
import {
  SymbioticBuilding,
  VegetationAndRecycling,
  Tenants,
  ServiceOffer,
  CheckIn,
  MailAndParcels,
  ExerciseOnEveryFloor,
  WorkOutTogether,
  ExerciseOutdoors,
  GoByBike,
  Well,
  Restaurant,
  Catering,
  Meetings,
  Accommodation,
  TechnologyAndEquipment,
  Organize,
  Events,
  Kungsleden,
  SquaresAndParks,
  Transportation,
  Hotels,
  ShoppingRestaurantsGyms,
  TheBuilding,
  Stocken,
  TheArea,
} from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/components/EdenWorkspaceInfo/components/EdenGeneralInfoTab';

export const GeneralInfoOptions = [
  {
    menu: 'aboutEden',
    submenus: [
      { title: 'theBuilding', content: TheBuilding },
      { title: 'vegetationAndRecycling', content: VegetationAndRecycling },
      { title: 'symbioticBuilding', content: SymbioticBuilding },
      { title: 'well', content: Well },
      { title: 'tenants', content: Tenants },
      { title: 'kungsleden', content: Kungsleden },
    ],
  },
  {
    menu: 'service',
    submenus: [
      { title: 'stocken', content: Stocken },
      { title: 'serviceOffer', content: ServiceOffer },
      { title: 'checkIn', content: CheckIn },
      { title: 'mailAndParcels', content: MailAndParcels },
    ],
  },
  {
    menu: 'exerciseAndHealth',
    submenus: [
      { title: 'exerciseOnEveryFloor', content: ExerciseOnEveryFloor },
      { title: 'workOutTogether', content: WorkOutTogether },
      { title: 'exerciseOutdoors', content: ExerciseOutdoors },
      { title: 'goByBike', content: GoByBike },
    ],
  },
  {
    menu: 'food',
    submenus: [
      { title: 'restaurant', content: Restaurant },
      { title: 'catering', content: Catering },
    ],
  },
  {
    menu: 'meetingsAndAccommodation',
    submenus: [
      { title: 'meetings', content: Meetings },
      { title: 'accommodation', content: Accommodation },
      { title: 'technologyAndEquipment', content: TechnologyAndEquipment },
    ],
  },
  {
    menu: 'event',
    submenus: [
      { title: 'events', content: Events },
      { title: 'organize', content: Organize },
    ],
  },
  {
    menu: 'hyllie',
    submenus: [
      { title: 'theArea', content: TheArea },
      { title: 'squaresAndParks', content: SquaresAndParks },
      { title: 'transportation', content: Transportation },
      { title: 'shoppingRestaurantsGyms', content: ShoppingRestaurantsGyms },
      { title: 'hotels', content: Hotels },
    ],
  },
];
