import React from 'react';
import styles from './styles.module.scss';
import { useUserAvatar } from 'services/user/avatar/hook';
import { Text } from 'components/typography/Text';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { StatusDot } from "components/StatusDot";
import userSrc from 'assets/media/user.png';
import { useUserDetails } from "services/user/get-details";

export const MyProfilePreview = () => {
  const { userDetails } = useUserDetails();

  const { avatar } = useUserAvatar();

  return (
    <>
      <CircularImage
        id="tiny-avatar"
        src={avatar || userSrc}
        alt="User avatar profile"
        onError={() => document.getElementById('tiny-avatar').src = userSrc}
      />

      <div className={styles.nameLine}>
        <Text bold>{userDetails.givenName}</Text> <StatusDot active/>
      </div>
    </>
  );
};
