import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'store/reducer';
import { rootSaga } from 'store/effect';
import { AuthProvider } from 'services/auth';
import { UserSettingsProvider } from 'services/user/settings';
import { UserAvatarProvider } from 'services/user/avatar/hook';
import { LocaleProvider } from 'services/user/locale/hook';
import { TenantProvider } from 'services/user/tenant/hook';
import { OnboardingGuard } from 'components/guards/OnboardingGuard';
import { ModalProvider } from 'store/modals';
import { SideMenuProvider } from './side-menu';
import { ReorderBrikksProvider } from './reorder-brikks';

export const StoreProvider = ({ children }) => (
  <AuthProvider>
    <TenantProvider>
      <UserSettingsProvider>
        <OnboardingGuard>
          <UserAvatarProvider>
            <LocaleProvider>
              <ModalProvider>
                <ReorderBrikksProvider>
                  <SideMenuProvider>{children}</SideMenuProvider>
                </ReorderBrikksProvider>
              </ModalProvider>
            </LocaleProvider>
          </UserAvatarProvider>
        </OnboardingGuard>
      </UserSettingsProvider>
    </TenantProvider>
  </AuthProvider>
);

const sagaMiddleware = createSagaMiddleware();

const reduxDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f;

function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(sagaMiddleware), reduxDevTools)
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(rootReducer));
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
