import React from "react";

export const HeartCalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.87"
      viewBox="0 0 20 20.87"
    >
      <g transform="translate(-464 -181.826)">
        <g transform="translate(464 181.826)">
          <path
            d="M18.761,2.609H16.37a.217.217,0,0,1-.217-.217V.87a.87.87,0,1,0-1.739,0V5a.652.652,0,1,1-1.3,0V3.043a.435.435,0,0,0-.435-.435H7.239a.217.217,0,0,1-.217-.217V.87a.87.87,0,1,0-1.739,0V5a.652.652,0,0,1-1.3,0V3.043a.435.435,0,0,0-.435-.435h-1.3A1.739,1.739,0,0,0,.5,4.348V19.13A1.739,1.739,0,0,0,2.239,20.87H18.761A1.739,1.739,0,0,0,20.5,19.13V4.348A1.739,1.739,0,0,0,18.761,2.609Zm0,16.087a.435.435,0,0,1-.435.435H2.674a.435.435,0,0,1-.435-.435V8.261a.435.435,0,0,1,.435-.435H18.326a.435.435,0,0,1,.435.435Z"
            transform="translate(-0.5 0)"
          />
          <path
            d="M11.954,12.126l-.588.592-.588-.588a2.129,2.129,0,1,0-3.011,3.011l3.285,3.428a.435.435,0,0,0,.628,0l3.285-3.428a2.129,2.129,0,1,0-3.011-3.011Z"
            transform="translate(-1.367 -1.501)"
          />
        </g>
      </g>
    </svg>
  );
};
