import {authorize, getRequest} from "utils/http";
import {AWS_API_URL} from "global/environment";


export async function getApartmentDocumentIDAPI() {
  return getRequest(`${AWS_API_URL}/apartment-document-id`, await authorize());
}

export async function getFloorPlanAPI(documentId) {
  return getRequest(`${AWS_API_URL}/apartment-drawing-content/${documentId}`, await authorize());
}

export async function getApartmentDrawingAPI() {
  return getRequest(`${AWS_API_URL}/apartment-drawing`, await authorize());
}
