import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Loader = ({ className }) => (
  <div className={classNames(styles.loader, className)}>
    <div className={styles.ball} />
    <div className={styles.ball} />
    <div className={styles.ball} />
  </div>
);

export default Loader;
