import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { FormattedMessage } from 'react-intl';
import orderService from 'assets/media/order-service-preview.png';
import { Text } from 'components/typography';

export const OrderServicePreview = () => (
  <div>
    <CircularImage
      src={orderService}
      alt="Order service preview"
    />
    <Text><FormattedMessage id="orderServicePreviewDescription" /></Text>
  </div>
);
