import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const UploadImage = ({ className, uploadedImages = [],
  name = 'file', id, bytes, disabled, maxSize = 3, multiple, type = {}, onChange, onError }) => {

  const onSelectFile = useCallback((files) => {
    if (!files.length) {
      return;
    }

    let attachedFiles;
    for (let i = 0; i < files.length; i++) {
      if (i > maxSize - 1) {
        break;
      }

      const file = files[i];

      if (type.regex && !type.regex.test(file.type)) {
        onError(type.error);
        return;
      }

      if (uploadedImages.length > 0) {
        const totalSize = uploadedImages.reduce(
          (total, image) => total + image.size + file.size, 0,
        );

        if (totalSize >= 5242880) {
          onError('totalSizeError');
          return;
        }
      }

      if (bytes) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const attachedFile = {
            fileContent: e.target.result,
            filename: file.name,
            size: file.size,
          };

          onError(null);
          onChange(attachedFile);
        };
      } else {
        attachedFiles = files[i];
        onError(null);
        onChange(attachedFiles);
      }
    }
  }, [onChange, onError, type, bytes, maxSize, uploadedImages]);

  return (
    <input
      id={id}
      name={name}
      className={classNames(className, disabled && styles.disabled)}
      disabled={disabled}
      multiple={multiple}
      type="file"
      accept={type.formats}
      onChange={e => onSelectFile(e.target.files)}
    />
  );
};
