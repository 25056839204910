import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from 'components/user-components/Profile/components/Avatar/styles.module.scss';
import { Image, Placeholder } from 'semantic-ui-react';
import { Heading } from 'components/typography/Heading';
import { useUserAvatar } from 'services/user/avatar/hook';
import { ShouldRender } from 'components/ShouldRender';
import { UploadImage } from 'components/form-elements/UploadImage';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import userSrc from 'assets/media/user.png';
import { TYPE_IMAGE } from 'utils/files';

export const Avatar = ({ onError }) => {
  const { avatar, loading, error, uploadUserAvatar } = useUserAvatar();

  const { theme } = useTheme();

  useEffect(() => {
    if (error) {
      onError(error);
    }
  }, [onError, error]);

  return (
    <div className={classNames(styles.avatar, theme.iconBackgroundSecondaryColor)}>
      <div className={styles.image}>
        {loading ? <Placeholder className={styles.placeholder} /> : (
          <Image
            id="avatar"
            alt="User avatar profile"
            className={styles.avatarImage}
            src={avatar || userSrc}
            circular
            onError={() => document.getElementById('avatar').src = userSrc}
          />
        )}
      </div>

      <ShouldRender condition={!loading}>
        <label htmlFor="uploadImage">
          <div className={styles.changeAvatar}>
            <div className={styles.text}>
              <Heading
                dark
                xxl
              >
                <FormattedMessage id="changeYourProfile" />
              </Heading>
            </div>

            <UploadImage
              id="uploadImage"
              name="user-avatar"
              className={styles.input}
              type={TYPE_IMAGE}
              onChange={uploadUserAvatar}
              onError={onError}
            />
          </div>
        </label>
      </ShouldRender>
    </div>
  );
};
