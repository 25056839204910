import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { WELCOME_SCREEN } from 'global/routes';

export function useRedirect(path, shouldRedirect = false) {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  useEffect(() => {
    if (shouldRedirect) {
      history.push(path, { prev: location.pathname !== WELCOME_SCREEN ? currentPath : location.state?.prev });
    }
    // eslint-disable-next-line 
  }, [history, path, shouldRedirect]);

  return {
    onRedirect: (redirectPath = path) => history.push(redirectPath, { prev: location.pathname !== WELCOME_SCREEN ? currentPath : location.state?.prev }),
  };
}
