import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ProcessingLoader } from 'components/loaders/ProcessingLoader';
import { FocusableElement } from '../../../components/FocusableElement';

export const DownloadIcon = ({ onClick, loading, error, fillBlue, disabled }) =>
  loading ? (
    <ProcessingLoader />
  ) : (
    <FocusableElement onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        className={classNames(styles.icon, error && styles.error, fillBlue && styles.fillBlue, disabled && styles.disabled)}
      >
        <g
          id="Group_6362"
          data-name="Group 6362"
          transform="translate(-1418.5 -394)"
        >
          <path
            id="Path_8250"
            data-name="Path 8250"
            d="M0,0H31V31H0Z"
            transform="translate(1449.5 425) rotate(180)"
          />

          <g
            id="Group_6361"
            data-name="Group 6361"
            transform="translate(477.231 304.52)"
          >
            
          <path
              id="Path_6820"
              data-name="Path 6820"
              d="M16.761,16.938a.9.9,0,0,0-.895.895V19.2a1.16,1.16,0,0,1-1.159,1.159H3.069A1.16,1.16,0,0,1,1.91,19.2V17.833a.895.895,0,1,0-1.789,0V19.2a2.952,2.952,0,0,0,2.948,2.952H14.707A2.952,2.952,0,0,0,17.659,19.2V17.833a.895.895,0,0,0-.9-.895Z"
              transform="translate(947.879 92.563)"
              fill="#fff"
          />

          <path
              id="Path_6821"
              data-name="Path 6821"
              d="M10.476,0A1.431,1.431,0,0,0,9.045,1.431V8.122a.185.185,0,0,1-.185.185H6.9a.716.716,0,0,0-.54,1.185l3.578,4.115a.715.715,0,0,0,1.08,0l3.578-4.115a.716.716,0,0,0-.54-1.185H12.092a.185.185,0,0,1-.185-.185V1.431A1.431,1.431,0,0,0,10.476,0Z"
              transform="translate(946.291 97)"
              fill="#fff"
          />
          </g>
        </g>
      </svg>
    </FocusableElement>
  );
