import React, { useState } from 'react';
import { Heading } from 'components/typography/Heading';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { AddFeed } from 'components/brikks/Office/RSSFeed/components/AddFeed';
import { InnerModal } from 'components/modals/InnerModal';
import styles from './styles.module.scss';

export const AddRSSFeedModal = () => {
  const [isAddFeedModalOpened, setIsAddFeedModalOpened] = useState(false);

  return (
    <div className={styles.addFeed}>
      <div className={styles.container}>
        <Heading
          xxl
          intl="noFeedsDescription"
        />

        <Button onClick={() => setIsAddFeedModalOpened(true)}>
          <FormattedMessage id="addFeed" />
        </Button>
      </div>

      <InnerModal
        isOpen={isAddFeedModalOpened}
        onClose={() => setIsAddFeedModalOpened(false)}
      >
        <AddFeed onAdd={() => setIsAddFeedModalOpened(false)} />
      </InnerModal>
    </div>
  );
};
