import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import userAdminSrc from 'assets/media/user-admin.png';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import styles from './styles.module.scss';
import { Users } from './components/Users';
import { ManageUsers } from './components/ManageUsers';

export const UserAdministrationModalContext = createContext(null);

export const UserAdministrationModal = () => {
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <UserAdministrationModalContext.Provider
      value={{ selectedUser, setSelectedUser }}
    >
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="userAdminBrikk" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="userAdminBrikk"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={userAdminSrc}
                alt="User admin"
              />

              <ModalIntro.Text intl="userAdminDescription" />
            </ModalIntro>

            <ProceedButton
              className={styles.button}
            >
              <ProceedButton.Button
                size={14}
                intl="goToDocuments"
              />
              <ProceedButton.SignIn />
            </ProceedButton>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column noOverflow>
          <Users />
        </Grid.Column>

        <Grid.Column noOverflow>
          <ManageUsers />
        </Grid.Column>

      </Grid>
    </UserAdministrationModalContext.Provider>
  ); 
};
