import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FocusableElement } from 'components/FocusableElement';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography/Text';

export const DropdownButton = ({ children, active, onClick, intl, disabled }) => (
  <FocusableElement
    className={classNames(
      styles.dropdownButton, {
        [styles.active]: active,
      },
      disabled && styles.disabled
    )}
    onClick={onClick}
  >
    <ShouldRender condition={!!intl}>
      <Text
        size={16}
        intl={intl}
        fontWeight={500}
      />
    </ShouldRender>
    {children}
  </FocusableElement>
);
