import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';

export const ServiceOffer = () => (
  <>
    <Text size={16}>
      <FormattedMessage
        id="serviceOfferDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
          email: (...chunks) => (
            <a
              href={`mailto:${chunks}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        }}
      />
    </Text>
  </>
);
