import * as Yup from 'yup';
import { PASSWORD_PATTERN } from 'utils/patterns';

export const registerValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('requiredNewPassword')
    .matches(
      PASSWORD_PATTERN,
      ('passPatternError'),
    ),
  confirmNewPassword: Yup.string()
    .required('requiredConfirmNewPassword')
    .matches(
      PASSWORD_PATTERN,
      ('passPatternError'),
    )
    .oneOf([Yup.ref('newPassword')], ('passMustMatch')),
});
