import React from 'react';
import styles from './styles.module.scss';
import { UserProfile } from 'components/headers/UserProfile';
import { FocusableElement } from 'components/FocusableElement';
import { ShouldRender } from "components/ShouldRender";
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ArrangeIcon } from 'assets/icons/ArrangeIcon';
import { Settings } from '../Settings';
import { useAuth } from 'services/auth';
import { useReorderBrikks } from 'store/reorder-brikks';

export const Menu = () => {
  const {
    shouldReorderBrikks,
    setShouldReorderBrikks,
  } = useReorderBrikks();
  
  const { isAuthenticated } = useAuth();

  return (
    <menu className={styles.menu}>
      <UserProfile />

      <FocusableElement
        className={classNames(styles.menuItem, styles.arrangeBrikks)}
        onClick={() => setShouldReorderBrikks(!shouldReorderBrikks)}
      >
        <span className={classNames(styles.menuText)}>
          {shouldReorderBrikks
            ? <FormattedMessage id="lockBrikks" /> : <FormattedMessage id="arrangeBrikks" />}
        </span>

        <ArrangeIcon isActive={shouldReorderBrikks} />
      </FocusableElement>


      <div className={styles.menuItem}>
        <ShouldRender condition={isAuthenticated}>
          <Settings />
        </ShouldRender>
      </div>
    </menu>
  );
};
