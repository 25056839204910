import React, { useContext, useMemo } from 'react';
import styles from './styles.module.scss';
import { PDFIcon } from 'assets/icons/PDFIcon';
import { Text } from 'components/typography/Text';
import { DocumentPreviewContext } from '../../index';

export const DocumentTitle = ({ type }) => {
  const { name } = useContext(DocumentPreviewContext);

  const icon = useMemo(() => {
    switch (type) {
      case 'pdf':
        return <PDFIcon />;
      default:
        return null;
    }
  }, [type])

  return (
    <div className={styles.documentTitle}>
      {icon}

      <Text
        intl={name}
        className={styles.title}
        size={12}
        bold
      >
        {name}
      </Text>
    </div>
  )
}
