import React from 'react';
import { flatten, sortBy } from 'lodash';
import { Feed } from 'components/feed/Feed';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const RSSFeedPreview = ({ feed, onSelectArticle }) => {
  const articles = React.useMemo(() => {
    if (feed?.length) {
      const articles = flatten(feed.map(feed => feed.articles));
      const sortedArticles = sortBy(articles, article => article.datePublished);
      return sortedArticles.reverse();
    } return [];
  }, [feed]);

  return (
    <div className={styles.feedPreview}>
      {articles.length ? (
        <Feed
          articles={articles}
          onSelectArticle={onSelectArticle}
        />
      ) : (
        <div className={styles.noFeeds}>
          <Text
            xl
            center
            intl="noFeeds"
          />
        </div>
      )}
    </div>
  );
};
