import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import React from 'react';
import styles from './styles.module.scss';

export const ContactUsAction = ({ onClick, intl }) => (
  <ProceedButton
    onClick={onClick}
    className={styles.button}
  >
    <ProceedButton.Button
      size={14}
      intl={intl}
    />
    <ProceedButton.SignIn />
  </ProceedButton>
);
