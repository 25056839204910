import React from "react";

export const SmartHouseLightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.722"
      height="19.124"
      viewBox="0 0 18.722 19.124"
    >
      <g transform="translate(-0.25)">
        <path
          d="M7.223,4.6a.2.2,0,0,1-.08-.159V3.586a.8.8,0,0,0-.319-.637.2.2,0,0,1-.08-.159v-1a.2.2,0,0,1,.2-.2h7.57a.2.2,0,0,1,.2.2v1a.025.025,0,0,1-.026.025H14a.653.653,0,0,0-.623.34.655.655,0,0,0,.112.7l1.4,1.814a.767.767,0,0,0,1.246,0l1.4-1.815a.654.654,0,0,0,.111-.7.653.653,0,0,0-.622-.34h-.688a.025.025,0,0,1-.029-.025V.8a.8.8,0,0,0-.8-.8H5.942a.8.8,0,0,0-.8.8V2.789a.2.2,0,0,1-.08.159.8.8,0,0,0-.319.637v.849a.2.2,0,0,1-.08.159,2.191,2.191,0,1,0,2.556,0Z"
          transform="translate(-0.712)"
        />
        <path
          d="M6.348,11.75a.6.6,0,0,0-.6.6v.8a.6.6,0,0,0,1.2,0v-.8A.6.6,0,0,0,6.348,11.75Z"
          transform="translate(-1.117 -2.387)"
        />
        <path
          d="M9.428,10.925a.6.6,0,0,0,0,.845l.4.4a.6.6,0,1,0,.845-.845l-.4-.4a.6.6,0,0,0-.845,0Z"
          transform="translate(-1.829 -2.184)"
        />
        <path
          d="M12.245,7.848a.6.6,0,0,0-.6-.6h-.8a.6.6,0,0,0,0,1.2h.8a.6.6,0,0,0,.6-.6Z"
          transform="translate(-2.032 -1.473)"
        />
        <path
          d="M2.327,10.925l-.4.4a.6.6,0,1,0,.845.845l.4-.4a.6.6,0,1,0-.845-.845Z"
          transform="translate(-0.305 -2.184)"
        />
        <path
          d="M2.245,7.848a.6.6,0,0,0-.6-.6h-.8a.6.6,0,0,0,0,1.2h.8a.6.6,0,0,0,.6-.6Z"
          transform="translate(0 -1.473)"
        />
        <path
          d="M13.037,20.324a.2.2,0,0,1-.045-.36,2.271,2.271,0,1,0-2.368,0,.2.2,0,0,1-.045.36,4.114,4.114,0,0,0-1.986,1.355.4.4,0,0,0,.307.646h5.817a.4.4,0,0,0,.31-.649,4.116,4.116,0,0,0-1.99-1.352Z"
          transform="translate(-1.678 -3.201)"
        />
        <path
          d="M16.035,8.251a.8.8,0,0,0-1.127,1.128,3.984,3.984,0,0,0,5.634,0,.8.8,0,0,0-1.127-1.128A2.449,2.449,0,0,1,16.035,8.251Z"
          transform="translate(-2.931 -1.629)"
        />
        <path
          d="M21.382,11.494a.8.8,0,0,0-1.127,0,3.989,3.989,0,0,1-5.634,0,.8.8,0,1,0-1.127,1.127,5.583,5.583,0,0,0,7.889,0A.8.8,0,0,0,21.382,11.494Z"
          transform="translate(-2.644 -2.288)"
        />
      </g>
    </svg>
  );
};
