import React from 'react';
import { Input } from 'components/form-elements/inputs/Input';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';
 
export const LabeledInput = ({ labelIntl, id, hasError, inputRef, onChange, disabled, ...props }) => (
  <Input
    className={styles.labeledInput}
    id={id}
    hasError={hasError}
    onChange={onChange}
    inputRef={inputRef}
    disabled={disabled}
    {...props}
  >
    <label
      className={styles.label}
      htmlFor={id}
    >
      <Text
        element="span"
        intl={labelIntl}
      />
    </label>
  </Input>
);
