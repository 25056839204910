import { MultiGrid } from './multiGrid';
import { MultiGridActions } from './actions';

const initialState = {
  selectedGrid: MultiGrid.Office,
}

export function multiGridReducer(state = initialState, action) {
  switch (action.type) {
    case MultiGridActions.SET_MULTIGRID:
      return {
        ...state,
        selectedGrid: action.payload,
      };
    default:
      return state;
  }
}
