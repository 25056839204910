import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { getUsersAPI } from 'services/admin/get-users/api';

const USERS = 'users';

export function useUsers() {
  const { tenantId } = useTenantId();

  const { data, isLoading, isFetching, isError } = useQuery(
    [USERS, tenantId],
    () => getUsersAPI(),
    { enabled: tenantId !== null },
  );

  return {
    users: data?.users || [],
    isLoading,
    isFetching,
    isError,
  };
}
