import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';

export const TextArea = ({
  rows,
  onChange,
  inputRef,
  placeholder,
  hasError,
}) => {
  const intl = useIntl();
  return (
    <Form.TextArea
      error={hasError}
      rows={rows}
      placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
      onChange={onChange}
      inputRef={inputRef}
    />
  );
};
