import { useEffect, useState } from 'react';
import {
  removeAuthToken,
} from 'utils/auth-token';
import { Auth } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import { TENANT_ID } from 'global/environment';
import { createContainer } from 'unstated-next';
import { getUserTenants } from 'utils/auth-token/getUserTenants';

function useAuthState() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [userTenants, setUserTenants] = useState(null);

  useEffect(() => {
    async function authorizeUser() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (cognitoUser) {
          const userTenants = await getUserTenants();
          setUser(cognitoUser);
          setUserTenants(userTenants);
          setIsAuthenticated(true);
        }
      } catch (error) {
        removeAuthToken();
      }
    }
    authorizeUser().then(() => setAuthLoading(false));
  }, []);

  return {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    userTenants,
    setUserTenants,
    authLoading,
  };
}

const auth = createContainer(useAuthState);

export const AuthProvider = auth.Provider;
export const useAuth = auth.useContainer;
