import React from "react";

export const DocumentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.167"
      viewBox="0 0 20 19.167"
    >
      <g transform="translate(-1537 -247)">
        <rect
          width="4.792"
          height="6.042"
          rx="1.25"
          transform="translate(1540.543 250.333)"
        />
        <path
          d="M20,8.417a2.5,2.5,0,0,0-2.5-2.5H15.833V2.167A1.667,1.667,0,0,0,14.167.5H1.667A1.667,1.667,0,0,0,0,2.167V16.75a2.917,2.917,0,0,0,2.917,2.917H17.083A2.917,2.917,0,0,0,20,16.75ZM2.917,18a1.25,1.25,0,0,1-1.25-1.25V2.583a.417.417,0,0,1,.417-.417H13.75a.417.417,0,0,1,.417.417V16.75a2.912,2.912,0,0,0,.092.728.417.417,0,0,1-.4.521Zm15.417-1.25a1.25,1.25,0,1,1-2.5,0V8a.417.417,0,0,1,.417-.417H17.5a.833.833,0,0,1,.833.833Z"
          transform="translate(1537 246.5)"
        />
        <path
          d="M12.626,6.047h1.667a.625.625,0,0,1,.625.625v.417a.625.625,0,0,1-.625.625H12.626A.624.624,0,0,1,12,7.091V6.675a.626.626,0,0,1,.626-.628Z"
          transform="translate(1535 245.575)"
        />
        <path
          d="M12.626,10.047h1.667a.625.625,0,0,1,.625.625v.417a.625.625,0,0,1-.625.625H12.626A.625.625,0,0,1,12,11.091v-.417a.626.626,0,0,1,.626-.628Z"
          transform="translate(1535 244.909)"
        />
        <path
          d="M4.626,14.047h8.333a.625.625,0,0,1,.625.625v.417a.625.625,0,0,1-.625.625H4.626A.625.625,0,0,1,4,15.091v-.417a.626.626,0,0,1,.626-.627Z"
          transform="translate(1536.333 244.242)"
        />
        <path
          d="M4.625,18.047h6.25a.625.625,0,0,1,.625.625v.417a.625.625,0,0,1-.625.625H4.625A.624.624,0,0,1,4,19.091v-.417a.625.625,0,0,1,.625-.627Z"
          transform="translate(1536.333 243.575)"
        />
      </g>
    </svg>
  );
};
