import React from 'react';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';

export const Checkbox = ({ checked, onChange, value, label, disabled }) => (
  <SemanticCheckbox
    value={value}
    label={label}
    checked={checked}
    onChange={onChange} 
    disabled={disabled}
  />
);
