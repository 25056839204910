import React, { createContext, useCallback, useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { ToolsIcon } from 'assets/icons/brikks/ToolsIcon';
import { BrikkModal } from 'components/modals/BrikkModal';
import { useTenant } from 'services/user/tenant/hook';
import { ReportIssueModal } from './components/ReportIssueModal';
import { ReportIssuePreview } from './components/ReportIssuePreview';

export const ReportIssueContext = createContext();

export const ReportIssue = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { tenantId } = useTenant();
  const isTenantHQ = tenantId === 'hq';

  const onSelectItem = useCallback((item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  }, [setSelectedItem, setIsModalOpen]);

  return (
    <ReportIssueContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <Draggable position={position}>
        <Brikk disabled={isTenantHQ}>
          <Brikk.Header
            icon={<ToolsIcon />}
            heading="reportIssueBrikk"
          />

          <Brikk.Content center>
            <ReportIssuePreview onSelectItem={onSelectItem} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              disabled={isTenantHQ}
              brikkNameIntl="reportIssueInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="reportIssueInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <ReportIssueModal selectedItem={selectedItem} />
      </BrikkModal>
    </ReportIssueContext.Provider>
  ); 
};
