import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import statusSrc from 'assets/media/status.png';
import { Tickets, Messages } from './components';

export const TicketContext = createContext();
export const StatusModal = () => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  
  return (
    <TicketContext.Provider
      value={{ selectedTicket, setSelectedTicket }}
    >
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="status" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="statusBrikk"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={statusSrc}
                alt="Status"
              />

              <ModalIntro.Text intl="statusDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Tickets />
        </Grid.Column>

        <Grid.Column>
          <Messages />
        </Grid.Column>

      </Grid>
    </TicketContext.Provider>
  ); 
};
