import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { ToolsBigIcon } from 'assets/icons/brikks/ToolsBIgIcon';
import { useReports } from 'services/reports/get-reports';

export const StatusPreview = () => {
  const { reports } = useReports();
  const activeReports = reports?.filter(report => report.statusName !== 'Kvitterad');

  return (
    <IconNumText
      icon={<ToolsBigIcon />}
      text={<FormattedMessage id="serviceTickets" />}
      number={activeReports?.length || '0'}
    />
  );
};
