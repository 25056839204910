import { REAL_ESTATE_ID } from 'global/environment';
import { RealEstates } from 'global/real-estate-config/realEstates';
import { useTenantId } from 'services/user/tenant/hook';
import { Tenants } from './tenants';
import { TenantConfig } from './tenantConfig';

export function getTenantIDBasedOnURL(userTenants) {
  const domainURL = window.location.pathname;

  const tenantKeys = userTenants || Object.keys(Tenants);

  for (let i = 0; i < tenantKeys.length; i++) {
    const { aliases, tenantId } = Tenants[tenantKeys[i].toUpperCase()];

    if (aliases.find((alias) => domainURL.includes(alias))) {
      return tenantId;
    }
  }

  return null;
}

export function getRealEstateIDBasedOnURL() {
  const domainURL = window.location.hostname;

  const realEstateKeys = Object.keys(RealEstates);

  for (let i = 0; i < realEstateKeys.length; i++) {
    const { aliases, realEstateId } = RealEstates[realEstateKeys[i]];

    if (aliases.find((alias) => domainURL.includes(alias))) {
      return realEstateId;
    }
  }
  return process.env.REACT_APP_REAL_ESTATE_ID;
}

export const tenantConfig = TenantConfig.hq;

export const useTenantConfig = () => {
  const { tenantId } = useTenantId();

  return {
    tenantConfig: tenantId ? TenantConfig[tenantId] : TenantConfig[REAL_ESTATE_ID],
  };
};

export const realEstateConfig = TenantConfig[getRealEstateIDBasedOnURL()];

