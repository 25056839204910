import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import onboardingWelcomeImgSrc from 'assets/media/eden/welcome.png';
import { Text } from 'components/typography/Text';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { OnboardingImage } from 'pages/onboarding/OnboardingImage';
import { FormattedMessage } from 'react-intl';
import { useTenantId } from 'services/user/tenant/hook';
import styles from './styles.module.scss';

export const Welcome = () => {
  const { tenantId } = useTenantId();
  const { onRedirect } = useRedirect(`/${tenantId}/onboarding/your-info`);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <OnboardingImage
            className={styles.mainImage}
            imageSrc={onboardingWelcomeImgSrc}
          />
        </Grid.Column>

        <Grid.Column>
          <Text
            element="h1"
            size={42}
            className={styles.title}
          >
            <FormattedMessage
              id="onboardingWelcomeHeading"
              values={{
                span: (...chunks) => (
                  <Text
                    element="span"
                    size={42}
                    primary
                  >
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Text>

          <Text
            gray
            xl
            intl="onboardingWelcomeDescription"
          />
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
};
