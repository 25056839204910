import { dotFormat, formatDate, hourAndMinute } from 'utils/timeAndDate';

export const mapDataForBackend = (order) => ({
  deliveryDate: formatDate(order.deliveryDate, dotFormat),
  deliveryTime: formatDate(order.deliveryDate, hourAndMinute),
  description: order.description,
  language: order.locale,
  deliveryPlace: order.deliveryPlace,
  email: order.email,
  phoneNumber: order.workNumber,
});
