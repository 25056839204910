import React from 'react';
import { setSplashScreenBoolean } from 'utils/local-storage/welcome-screen';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { LanguagePicker } from 'components/user-components/LanguagePicker';
import { Navigator } from 'components/links/Navigator';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { useLocation } from 'react-router-dom';
import { LOGIN_PAGE } from 'global/routes';
import { REAL_ESTATE_ID } from 'global/environment';
import styles from './styles.module.scss';

export const WelcomeScreen = () => {
  const location = useLocation();

  return (
    <BasicPageLayout intlTitle={REAL_ESTATE_ID}>
      <Text
        element="h1"
        size={42}
        className={styles.title}
      >
        <FormattedMessage
          id="welcomeTitle"
          values={{
            span: (...chunks) => (
              <Text
                element="span"
                size={42}
                primary
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>

      <Text
        gray
        xl
        intl="welcomeDescription"
      />

      <div className={styles.footer}>
        <LanguagePicker />

        <div onClick={() => setSplashScreenBoolean(true)}>
          <Navigator
            dark
            to={location.state?.prev || LOGIN_PAGE}
          />
        </div>
      </div>
    </BasicPageLayout>
  );
};
