import React from 'react';
import { Text } from 'components/typography/Text';
import { StatusDot } from 'components/StatusDot';
import styles from './styles.module.scss';

export const StatusLine = ({ prefixIntl, opened }) => (
  <div className={styles.statusLine}>
    <Text intl={prefixIntl} />
    {opened ? (
      <>
        <Text 
          bold 
          intl="statusOpen" 
        /> 
        <StatusDot active />
      </>
    ) : (
      <>
        <Text 
          bold 
          intl="statusClosed"
        /> 
        <StatusDot />
      </>
    )}
  </div>
);
