import React, { useContext, useEffect, useMemo } from 'react';
import { Form } from 'components/layout/Form';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements/buttons/Button';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { LabeledSelect } from 'components/form-elements/selects';
import { useOrganizations } from 'services/tenant/organizations';
import { useForm, Controller } from 'react-hook-form';
import { ROLES } from 'utils/user/roles';
import { useAdminUpdateUser } from 'services/admin/update-user';
import { userPermissions, GARAGE } from 'utils/user';
import { Checkbox } from 'components/form-elements/Checkbox';
import { ShouldRender } from 'components/ShouldRender';
import { useIntl } from 'react-intl';
import { UserAdministrationModalContext } from '../../../../index';
import styles from './styles.module.scss';
import { UserDetailsCard } from './components/UserDetailsCard';
import { UserUpdateAccess } from './components/UserUpdateAccess';

export const EditUser = () => {
  const { selectedUser } = useContext(
    UserAdministrationModalContext,
  );

  const { organizations } = useOrganizations();
  const intl = useIntl();

  const organizationOptions = useMemo(() => (
    organizations.map(item => ({ value: item, label: item }))
  ), [organizations]);

  const { control, handleSubmit, reset, watch } = useForm({
    mode: 'onSubmit',
  });

  const currentUser = watch();

  const { isLoading, adminUpdateUser } = useAdminUpdateUser();

  useEffect(() => {
    reset({
      ...selectedUser,
      userRole: {
        value: selectedUser?.userRole,
        label: selectedUser?.userRole.charAt(0).toUpperCase() + selectedUser?.userRole.slice(1),
      },
      organization: selectedUser?.organization ? {
        value: selectedUser.organization,
        label: selectedUser.organization,
      } : null,
      vehicleNumber: selectedUser?.vehicleNumber || '',
      userPermissions: selectedUser?.userPermissions || [],
    });
  }, [selectedUser, reset]);

  const onSubmit = (user) => {
    adminUpdateUser(user);
  };
  
  const setUserPermission = (checked, onChange, value) => {
    if (checked.checked) {
      onChange([
        ...value,
        checked.value,
      ]);
    } else {
      onChange(
        value?.filter(
          (value) => value !== checked.value,
        ),
      );
    }
  };

  return (
    <>
      { selectedUser
        ? (
          <Form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <UserUpdateAccess selectedUser={selectedUser} />
            
            <UserDetailsCard selectedUser={selectedUser} />

            <Grid
              oneColumn
              noPadding
              style={{ 
                marginTop: '20px', 
                justifyItems: 'flex-start',
              }}
            >
              <Grid
                noPadding
                oneColumn
                style={{ 
                  gap: '10px', 
                  justifyItems: 'flex-start', 
                }}
              >
                <Controller
                  name="userRole"
                  control={control}
                  render={({ field }) => (
                    <LabeledSelect
                      value={field.value}
                      onChange={field.onChange}
                      label="changeRole"
                      options={ROLES}
                    />
                  )}
                />

                <Controller
                  name="organization"
                  control={control}
                  render={({ field }) => (
                    <LabeledSelect
                      label="changeOrganisation"
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      options={organizationOptions}
                    />
                  )}
                />

                <Text
                  size={14}
                  intl="changePermissions"
                />

                <Controller
                  name="userPermissions"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {
                        userPermissions.map(option => (
                          <div
                            style={{ 
                              display: 'flex',
                              width: '100%',
                            }}
                            key={option.value}
                          >
                            <Checkbox
                              key={option.value}
                              value={option.value}
                              label={intl.formatMessage({ id: (option.label) })}
                              disabled={option.disable}
                              checked={value?.some(
                                (existingValue) => existingValue === option.value,
                              )}
                              onChange={(event, checked) => setUserPermission(checked, onChange, value)}
                            />

                            <ShouldRender condition={currentUser.userPermissions?.includes(GARAGE) && option.value === GARAGE}>
                              <div className={styles.input}>
                                <Controller
                                  name="vehicleNumber"
                                  control={control}
                                  defaultValue={selectedUser?.vehicleNumber || ''}
                                  render={({ field: { ref, onChange, value } }) => (
                                    <LabeledInput
                                      labelIntl="vehicleNumberplate"
                                      value={value}
                                      inputRef={ref}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </div>
                            </ShouldRender>
                          </div>
                        ))
                      }
                    </>
                  )}
                />
              </Grid>
            </Grid>

            <div className={styles.button}>
              <Button
                loading={isLoading}
                name="save"
                secondary
                intl="saveChanges"
              />
            </div>
          </Form>
        ) : (
          <Text
            secondary
            center
            intl="noUserSelected"
            size={16}
          />
        )}
    </>
  ); 
};
