import React from 'react';
import { useRedirect } from 'utils/redirect';
import { PageLoader } from 'components/loaders/PageLoader';
import { useAuth } from 'services/auth';
import { useUserSettings } from 'services/user/settings';
import { useTenantId } from 'services/user/tenant/hook';

export const OnboardingGuard = ({ children }) => {
  const { loading, onboardingPassed } = useUserSettings();
  const { isAuthenticated } = useAuth();
  const { tenantId } = useTenantId();

  const shouldOnboard = !onboardingPassed && !loading && isAuthenticated && !!tenantId;

  useRedirect(`/${tenantId}/onboarding/welcome`, shouldOnboard);

  // useRedirect(ONBOARDING_WELCOME, true);
  // TODO: Uncomment this value and comment in the one before to see the onboarding screens when you are logged IN
  // Do the same here src/pages/onboarding/Onboarding/index.js

  return (loading) ? <PageLoader /> : <>{children}</>;
};
