import axios from 'axios';
import { getIdToken } from 'utils/auth-token';

function handleHttpError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      // TODO: Should improve this logic here 

      // removeAuthToken();
      // window.location.href = LOGIN_PAGE;
      console.log(error.response.errorMessage);
    } else if (error.response.data) {
      return {
        ...error.response.data,
        statusCode: error.response.status,
        message: error.response.errorMessage || 'genericErrorMessage',
      };
    }
  }
  return {
    timeout: true,
    message: 'genericErrorMessage',
  };
}

function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiCall();
      resolve(data);
    } catch (e) {
      reject(handleHttpError(e));
    }
  });
}

export async function authorize(options = {}) {
  const jwtToken = await getIdToken();

  const authHeader = {
    Authorization: jwtToken,
  };

  return { ...options, headers: { ...options.headers, ...authHeader } };
}

export function getRequest(path, options = {}) {
  return makeHttpRequest(() => axios.get(path, options));
}

export function postRequest(path, data = {}, options = {}) {
  return makeHttpRequest(() => axios.post(path, data, options));
}

export function putRequest(path, data = {}, options = {}) {
  return makeHttpRequest(() => axios.put(path, data, options));
}

export function deleteRequest(path, options = {}) {
  return makeHttpRequest(() => axios.delete(path, options));
}
