import React from "react";

export const ReadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.833"
      height="20"
      viewBox="0 0 15.833 20"
    >
      <g transform="translate(-2.5)">
        <path
          d="M9.506,8.032a.417.417,0,0,0-.019.773,8.851,8.851,0,0,0,1.908.778A8.851,8.851,0,0,0,13.3,8.805a.417.417,0,0,0,.256-.392.412.412,0,0,0-.269-.379,1.766,1.766,0,0,1-.06-1.25A4.483,4.483,0,0,0,14.4,3.339,3.083,3.083,0,0,0,11.395,0,3.083,3.083,0,0,0,8.388,3.339,4.465,4.465,0,0,0,9.546,6.767,1.759,1.759,0,0,1,9.506,8.032Z"
          transform="translate(-0.978)"
        />
        <path
          d="M17.809,10.482a1.25,1.25,0,0,0-1.142-.16l-6.251,2.206-6.25-2.206A1.25,1.25,0,0,0,2.5,11.5v6.583a1.25,1.25,0,0,0,.833,1.179l6.8,2.4a.95.95,0,0,0,.278.044.967.967,0,0,0,.278-.047l6.806-2.4a1.255,1.255,0,0,0,.833-1.179V11.5a1.255,1.255,0,0,0-.524-1.015Zm-13.643,1.9a.207.207,0,0,1,.278-.2l5,1.764a.208.208,0,0,1,.139.2V19.4a.208.208,0,0,1-.278.2l-5-1.763a.208.208,0,0,1-.139-.2Zm12.5,5.255a.208.208,0,0,1-.139.2l-5,1.763a.208.208,0,0,1-.278-.2v-5.25a.208.208,0,0,1,.139-.2l5-1.764a.207.207,0,0,1,.277.2Z"
          transform="translate(0 -1.708)"
        />
      </g>
    </svg>
  );
};
