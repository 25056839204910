import React from 'react';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import styles from './styles.module.scss';

export const ReportIssuePreview = ({ onSelectItem }) => (
  <div className={styles.content}>
    <ProceedButton
      className={styles.proceedButton}
      onClick={() => onSelectItem('office')}
    >
      <ProceedButton.Button
        intl="office"
        size={16}
        className={styles.button}
      />
      <ProceedButton.CircledArrow dark />
    </ProceedButton>

    <ProceedButton
      className={styles.proceedButton}
      onClick={() => onSelectItem('openSpace')}
    >
      <ProceedButton.Button
        intl="openSpace"
        size={16}
        className={styles.button}
      />
      <ProceedButton.CircledArrow dark />
    </ProceedButton>

    <ProceedButton
      className={styles.proceedButton}
      onClick={() => onSelectItem('conferenceRoom')}
    >
      <ProceedButton.Button
        intl="conference"
        size={16}
        className={styles.button}
      />
      <ProceedButton.CircledArrow dark />
    </ProceedButton>
  </div>
);
