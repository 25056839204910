export const GARAGE = 'garage';
export const NETWORK = 'network';
export const ACCESS = 'access';

export const userPermissions = [ 
  {
    value: ACCESS,
    label: 'permissionToAccess',
    disable: true,
  },
  {
    value: NETWORK,
    label: 'permissionToNetwork',
    disable: true,
  },
  {
    value: GARAGE,
    label: 'permissionToGarage',
    disable: false,
  },
];
