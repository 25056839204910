import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { LinkModal } from 'components/modals/LinkModal';
import { TextButton } from 'components/form-elements/buttons/TextButton';
import { ResourceBookingModal } from 'components/brikks/Office/ResourceBooking/components/ResourceBookingModal';

export const Hotels = () => (
  <>
    <Text size={16}>
      <FormattedMessage
        id="hotelsDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
          linkmodal: (...chunks) => (
            <LinkModal
              buttonTriggerModal={TextButton}
              buttonText={chunks}
              brikkModal={ResourceBookingModal}
              modalHeading="resourceBooking"
            />
          ),
        }}
      />
    </Text>
  </>
);
