import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import LogIn from 'pages/auth/LogIn';
import { ForgotPassword } from 'pages/auth/ForgotPassword/';
import { CreatePassword } from 'pages/auth/CreatePassword/';
import { ForgotPasswordVerify } from 'pages/auth/ForgotPasswordVerify/';
import { HomePage } from 'pages/HomePage';
import { WelcomeScreen } from 'pages/WelcomeScreen';
import { ActivateAccount } from 'pages/auth/ActivateAccount';
import { ChangePassword } from 'pages/auth/ChangePassword/';
import { Register } from "pages/Register";
import { PrivateRoute } from 'components/routes/PrivateRoute';
import { useTenantConfig } from 'global/tenant-config';
import ChangePasswordConfirmation from 'pages/auth/ChangePasswordConfirmation/ChangePasswordConfirmation';
import NotFoundPage from 'pages/404';
import {
  HOME_PAGE,
  MAIN_PAGE,
  ONBOARDING_PAGE,
  WELCOME_SCREEN,
  CHANGE_PASS_PAGE,
  REGISTER_PAGE,
  LOGIN_PAGE,
  ACTIVATE_ACCOUNT_PAGE,
  CREATE_PASS_PAGE,
  FORGOT_PASS_PAGE,
  FORGOT_PASS_VERIFY_PAGE,
  CHANGE_PASS_CONFIRMATION_PAGE,
} from 'global/routes';

export const Routes = () => {
  const { tenantConfig } = useTenantConfig();
  const Onboarding = tenantConfig.components.onboarding;

  return (
    <Switch>
      <Route
        exact
        path={LOGIN_PAGE}
        component={(props) => <LogIn {...props} />}
      />

      <Route
        exact
        path={ACTIVATE_ACCOUNT_PAGE}
        component={(props) => <ActivateAccount {...props} />}
      />

      <Route
        exact
        path={CREATE_PASS_PAGE}
        component={(props) => <CreatePassword {...props} />}
      />
      <Route
        exact
        path={FORGOT_PASS_PAGE}
        component={(props) => <ForgotPassword {...props} />}
      />

      <Route
        exact
        path={FORGOT_PASS_VERIFY_PAGE}
        component={(props) => <ForgotPasswordVerify {...props} />}
      />

      <Route
        exact
        path={CHANGE_PASS_CONFIRMATION_PAGE}
        component={(props) => <ChangePasswordConfirmation {...props} />}
      />

      <Route
        exact
        path={WELCOME_SCREEN}
        component={WelcomeScreen}
      />

      <PrivateRoute
        exact
        path={CHANGE_PASS_PAGE}
        component={(props) => <ChangePassword {...props} />}
      />

      <Route
        exact
        path={REGISTER_PAGE}
        component={(props) => <Register {...props} />}
      />

      <PrivateRoute
        exact
        path={HOME_PAGE}
        component={HomePage}
      />

      <PrivateRoute
        exact
        path={MAIN_PAGE}
        component={MainPage}
      />

      <PrivateRoute
        path={ONBOARDING_PAGE}
        component={Onboarding}
      />
      <Route component={(props) => <NotFoundPage {...props} />} />
    </Switch>
  );
};
