import React, { useContext } from 'react';
import { MultiTabMenu } from 'components/MultiTabMenu';
import { UserAdministrationModalContext } from '../../index';
import { AddUsers } from './components/AddUsers';
import { EditUser } from './components/EditUser';

export const ManageUsers = () => {

  const { selectedUser } = useContext(
    UserAdministrationModalContext,
  );

  return (
    <MultiTabMenu
      title="userAdminInfo"
      defaultTab={selectedUser ? 'editUser' : 'addUsers'}
      tabs={{
        addUsers: {
          title: 'addUsers',
          component: AddUsers,
          menus: [],
        },
        editUser: {
          title: 'editUser',
          component: EditUser,
          menus: [],
        },
      }}
    />
  );
};
