import React from 'react';
import { LabeledElement } from 'components/form-elements/LabeledElement';
import { Select } from 'components/form-elements/selects/Select';

export const LabeledSelect = ({
  id,
  label,
  isDisabled,
  placeholder,
  hasError,
  isClearable,
  ...props
}) => (
  <LabeledElement
    id={id}
    label={label}
    isDisabled={isDisabled}
  >
    <Select
      id={id}
      isDisabled={isDisabled}
      placeholder={placeholder}
      isClearable={isClearable}
      {...props}
    />
  </LabeledElement>
);
