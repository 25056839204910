import React from 'react';
import styles from './styles.module.scss';
import { Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ArrangeIcon } from 'assets/icons/ArrangeIcon';
import classNames from 'classnames';
import { useReorderBrikks } from 'store/reorder-brikks';
import { useTheme } from 'global/themes';
import { Settings } from './components/Settings';
import { UserProfile } from 'components/headers/UserProfile';
import { FocusableElement } from 'components/FocusableElement';

export const AuthenticatedMenu = () => {
  const { shouldReorderBrikks, setShouldReorderBrikks } = useReorderBrikks();

  const { theme } = useTheme();

  return (
    <>
      <Menu.Menu position='right' className={styles.menu}>
        <UserProfile />
      

        <FocusableElement onClick={() => setShouldReorderBrikks(!shouldReorderBrikks)}>
          <Menu.Item
            as='a'
            className={classNames(styles.arrangeBrikks, styles.link)}
          >
           <span className={classNames(styles.menuText, theme.textColor)}>
             {shouldReorderBrikks
               ? <FormattedMessage id="lockBrikks" /> : <FormattedMessage id="arrangeBrikks" />}
           </span>
            <ArrangeIcon isActive={shouldReorderBrikks} />
          </Menu.Item>
        </FocusableElement>

        <Menu.Item
          as='a'
          className={styles.link}
        >
          <Settings />
        </Menu.Item>
      </Menu.Menu>
    </>
  )
};
