import React, { useState } from 'react';
import { Button, Input, Message } from 'semantic-ui-react';
import { useAddRSSFeed } from 'services/rss-feeds/add-rss-feed/hook';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';

export const AddFeed = ({ onAdd }) => {
  const [feedUrl, setFeedUrl] = useState();

  const { isLoading, isError, error, addRSSFeed } = useAddRSSFeed({
    onSuccess: () => {
      onAdd();
    },
  });
  const intl = useIntl();

  return (
    <div className={styles.addFeed}>
      <Input
        label={intl.formatMessage({ id: 'url' })}
        name="rss-feed-url"
        value={feedUrl}
        onChange={(e) => setFeedUrl(e.target.value)}
      />

      <ShouldRender condition={!!isError}>
        <Message negative>
          <Message.Content>
            <FormattedMessage id={error?.message} />
          </Message.Content>
        </Message>
      </ShouldRender>

      <Button
        name="add-rss-feed"
        loading={isLoading}
        disabled={isLoading || !feedUrl}
        onClick={() => addRSSFeed(feedUrl)}
      >
        <FormattedMessage id="add" />
      </Button>
    </div>
  );
};
