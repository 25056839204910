import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { DropdownContext } from '../../index';
import { Icon } from 'semantic-ui-react';
import { DropdownButton } from 'components/form-elements/buttons/DropdownButton';

export const Item = ({ children, intl, disabled, active, onClick }) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  const handleOnClick = () => {
    onClick();
    setIsOpen(!isOpen)
  }

  return (
    <DropdownButton
      intl={intl}
      active={active}
      disabled={disabled}
      // onClick={!disabled ? () => setIsOpen(!isOpen) : undefined}
      onClick={!disabled ? () => handleOnClick() : undefined}
    >
      {children}

      <Icon
        className={styles.icon}
        name={isOpen ? 'angle down' : 'angle right'}
      />
    </DropdownButton>
  )
}
