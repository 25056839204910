import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import { Text } from 'components/typography';
import { dateMonthNameYearTime, timeFormat } from 'utils/timeAndDate';
import { DatePicker } from 'components/form-elements/pickers/DatePicker';
import { Button } from 'components/form-elements/buttons/Button';
import { Form } from 'components/layout/Form';
import { useForm, Controller } from 'react-hook-form';
import { TextArea } from 'components/form-elements/textareas/TextArea';
import { useUserDetails } from 'services/user/get-details';
import { useLocale } from 'services/user/locale/hook';
import { useSendOrder } from 'services/order-service/send-order';
import { useIntl } from 'react-intl';
import { endOfDay, isPast } from 'date-fns';
import { calculateMinTime } from 'utils/date-and-time';
import styles from './styles.module.scss';

export const OrderForm = ({ setIsModalOpen }) => {
  const { userDetails } = useUserDetails();
  const { locale } = useLocale();
  const initialDate = new Date();
  const intl = useIntl();

  const [minTime, setMinTime] = useState(calculateMinTime(new Date()));

  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });

  const { sendOrder, isLoading } = useSendOrder({
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const onSubmit = (order) => {
    order.email = userDetails.email;
    order.workNumber = userDetails.workNumber;
    order.locale = locale;
    sendOrder(order);
  };

  return (
    <>
      <Brikk.Heading heading="orderForm" />

      <Text
        intl="orderFormDescription"
        size={16}
      />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              onChange={field.onChange}
              inputRef={field.ref}
              rows={5}
            />
          )}
        />

        <Controller
          name="deliveryDate"
          control={control}
          defaultValue={initialDate}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              selectedDate={value}
              showTimeSelect
              timeIntervals={15}
              minDate={initialDate}
              minTime={minTime}
              maxTime={endOfDay(initialDate)}
              dateFormat={dateMonthNameYearTime}
              timeFormat={timeFormat}
              timeCaption={intl.formatMessage({ id: 'time' })}
              onChange={(selectedDate) => {
                onChange(isPast(selectedDate) ? initialDate : selectedDate);
                setMinTime(calculateMinTime(selectedDate));
              }}
            />
          )}
        />

        <div className={styles.send}>
          <Button
            loading={isLoading}
            intl="sendOrder"
            disabled={!formState.isValid}
          />
        </div>
      </Form>
    </>
  );
};
