import React from 'react';
import { MultiTabMenu } from 'components/MultiTabMenu';
import { TicketsList } from './components/TicketsList';

export const Tickets = () => (
  <MultiTabMenu
    title="tickets"
    defaultTab="open"
    tabs={{
      open: {
        title: 'open',
        component: TicketsList,
        menus: [] },
      closed: { 
        title: 'closed',
        component: TicketsList,
        menus: [], 
      },
    }}
  />
);
