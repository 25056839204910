import React from 'react';
import styles from './styles.module.scss';
import { Modal as SemanticModal, Placeholder } from 'semantic-ui-react';
import { DiscardIcon } from 'assets/icons/DiscardIcon';
import { ModalTracking } from 'components/tracking/ModalTracking';
import { Modal } from 'components/modals/Modal';

export const BrikkModal = ({
  isOpen,
  isLoading,
  heading,
  children,
  onClose,
}) => (
  <Modal
    className={styles.modal}
    isOpen={isOpen}
    dimmer='blurring'
    onClose={onClose}
  >
    <div className={styles.header}>
      <div className={styles.discard}>
        <DiscardIcon onClick={onClose} />
      </div>
    </div>

    <SemanticModal.Content className={styles.content}>
      {isLoading ? (
        <Placeholder fluid>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : children}
    </SemanticModal.Content>

    <ModalTracking name={heading} />
  </Modal>
);
