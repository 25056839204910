import React from 'react';
import styles from './styles.module.scss';
import { Image } from './components/Image';
import { Text } from './components/Text';

export const ModalIntro = ({ children }) => (
  <div className={styles.modalIntro}>
    {children}
  </div>
);

ModalIntro.Image = Image;
ModalIntro.Text = Text;
