import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';

export const CheckIn = () => {
  return (
    <>
      <Text size={16}>
        <FormattedMessage
          id="checkInDescription"
          values={{
            bold: (...chunks) => (
              <Text size={16} element="span" bold>
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
    </>
  );
};
