import { useMutation } from 'react-query';
import { useModal } from 'store/modals';
import { sendOrderAPI } from './api';

export function useSendOrder({ onSuccess }) {
  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (order) => sendOrderAPI(order),
    {
      onSuccess: (data, order) => {
        onSuccess();
        openModal({
          modalType: 'PopupModal',
          modalProps: {
            title: 'orderModalTitleSuccess',
            description: 'orderModalDescriptionSuccess',
            descriptionDetails: order.description,
            showActions: false,
          },
        });
      },
      onError: (error, order) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'orderModalTitleError',
          description: 'orderModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(order),
        },
      }),
    },
  );

  return {
    sendOrder: mutate,
    isLoading,
    isError,
  };
}
