import React from 'react';
import { Wg1Icon } from 'assets/icons/Wg1Icon';
import { ConditionalLink } from 'components/links/ConditionalLink';
import { ShouldRender } from 'components/ShouldRender';
import { REAL_ESTATE_ID, TENANT_ID } from 'global/environment';
import { TenantConfig } from 'global/tenant-config/tenantConfig';
import { WG1 } from 'global/real-estate-config/realEstates';
import { useAuth } from 'services/auth';
import { useTenant } from 'services/user/tenant/hook';
import styles from './styles.module.scss';

export const Logo = () => {
  const { userTenants } = useAuth();
  const { tenantId } = useTenant();

  const realEstateLogo = TenantConfig[REAL_ESTATE_ID]?.assets?.logoDark;
  const tenantLogo = TenantConfig[TENANT_ID]?.assets?.logoDark;
  
  return (
    <ConditionalLink
      to="/"
      condition={userTenants?.length > 1}
    >
      <div className={styles.logoWrapper}> 
        <ShouldRender condition={REAL_ESTATE_ID === WG1}>
          <Wg1Icon className={styles.logoRealEstate} />
        </ShouldRender>

        <ShouldRender condition={!!realEstateLogo}>
          <img
            alt="Logo"
            src={realEstateLogo}
            className={styles.logoRealEstate}
          />
        </ShouldRender>

        <ShouldRender condition={tenantId && tenantLogo}>
          <span className={styles.verticalLine} />
            
          <img
            alt="tenantLogo"
            src={tenantLogo}
            className={styles.logo}
          />
        </ShouldRender>
      </div>
    </ConditionalLink>
  );
};
