import React from 'react';
import classNames from 'classnames';
import { Text } from 'components/typography/Text';
import { AddFeedButton } from '../AddFeedButton';
import { FocusableElement } from 'components/FocusableElement';
import { FeedArticle } from '../FeedArticle';
import styles from './styles.module.scss';

export const FeedList = ({ selectedFeed, feed, selectAllArticles, setSelectedFeed }) => (
  <div className={styles.feedList}>
    <div className={styles.section}>
      <Text
        intl="allArticles"
        bold
        uppercase
        large
        dark
      />

      <div className={styles.sectionList}>
        <FocusableElement
          element="span"
          onClick={selectAllArticles}
        >
          <Text
            intl="latest"
            dark
            large
            clickable
            selected={!selectedFeed.title}
          />
        </FocusableElement>
      </div>
    </div>

    <div className={styles.section}>
      <Text
        intl="subscriptions"
        bold
        uppercase
        large
        dark
      />

      <div className={classNames(styles.sectionList, styles.scrollable)}>
        {feed.map(feedArticle => (
          <FeedArticle
            key={`${feedArticle.id}-${feedArticle.title}`}
            selectedFeed={selectedFeed}
            feedArticle={feedArticle}
            setSelectedFeed={setSelectedFeed}
            selectAllArticles={selectAllArticles}
          />
        ))}
      </div>
    </div>

    <div className={styles.section}>
      <AddFeedButton />
    </div> 
  </div>
);
