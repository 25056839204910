import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';

export const ActionButton = ({ children, intl, className, onClick }) => (
  <div className={styles.actionButton}>
    {children}

    <Text
      intl={intl}
      size={10}
      underline
      marginLeft
      className={className}
      onClick={() => onClick && onClick()}
    />
  </div>
);

