import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DropdownContext } from '../../index';

export const Content = ({ children }) => {
  const { isOpen } = useContext(DropdownContext);

  return (
    <div
      className={classNames(
      styles.contentContainer,
      isOpen && styles.open,
    )}
    >
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}
