import React from 'react';
import { Text } from 'components/typography/Text';
import { DARK_THEME, FLAT_THEME, TRANSPARENT_THEME } from 'global/themes';
import { Checkbox } from './components/Checkbox';
import styles from './styles.module.scss';

export const ThemeSettings = () => {
  return (
    <div className={styles.themeSettings}>
      <div className={styles.headingWrapper}>
        <Text
          element="h3"
          thin
          medium
          uppercase
          white
          intl="appearance"
          className={styles.heading}
        />
      </div>

      <div className={styles.settings}>
        <Checkbox
          intl="solid"
          themeName={FLAT_THEME}
          disabled
        />
        <Checkbox
          intl="transparent"
          themeName={TRANSPARENT_THEME}
          disabled
        />
        <Checkbox
          intl="dark"
          themeName={DARK_THEME}
          disabled
        />
      </div>
    </div>
  );
};
