import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useSideMenu } from 'store/side-menu';
import { DiscardCrossIcon } from 'assets/icons/DiscardCrossIcon';
import { ThemeSettings } from './components/ThemeSettings';
import { BackgroundSettings } from './components/BackgroundSettings';
import { LanguageSettings } from './components/LanguageSettings';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import FocusTrap from 'focus-trap-react';

export const SideMenu = () => {
  const { isSideMenuOpen, setIsSideMenuOpen } = useSideMenu();

  const { ref, onClickOutside } = useClickOutside(() => setIsSideMenuOpen(false));

  return (
    <FocusTrap active={isSideMenuOpen}>
      <div
        className={classNames(styles.sideMenuContainer, isSideMenuOpen && styles.open)}
        onClick={onClickOutside}
      >
        <div
          ref={ref}
          className={classNames(styles.sideMenu, isSideMenuOpen && styles.open)}
        >
          <div className={styles.header}>

            <DiscardCrossIcon
              white
              xl
              ignoreTheme
              onClick={() => setIsSideMenuOpen(false)}
            />
          </div>

          <div className={styles.settings}>
            <LanguageSettings />

            <ThemeSettings />

            <BackgroundSettings />
          </div>
        </div>
      </div>
    </FocusTrap>
  )
};
