import React from 'react';

export const DeleteIcon = ({ size = 25, className }) => (
  <svg
    style={{ width: size }}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.977 25.977"
  >
    <path
      id="circle-with-plus"
      d="M9.621,0a9.621,9.621,0,1,0,9.621,9.621A9.622,9.622,0,0,0,9.621,0Zm5.727,10.767H10.767v4.582H8.476V10.767H3.894V8.476H8.476V3.894h2.291V8.476h4.582Z"
      transform="translate(0 13.607) rotate(-45)"
      fill="#fd2b2b"
    />
  </svg>
);
