import React from "react";
import { createContainer } from "unstated-next";

function useMenu() {
  const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);

  return {
    isSideMenuOpen,
    setIsSideMenuOpen,
  };
}

const sideMenu = createContainer(useMenu);

export const SideMenuProvider = sideMenu.Provider;
export const useSideMenu = sideMenu.useContainer;
