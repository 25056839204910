import React, { useState, createContext, useEffect } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { Text } from 'components/typography';
import { Tabs } from './Tabs';
import { Menus } from './Menus';
import { MenuContent } from './MenuContent';

export const MultiTabContext = createContext();

export const MultiTabMenu = ({ tabs, defaultTab, title, menus, defaultMenu, subTitle }) => {

  const [activeMenu, setActiveMenu] = useState(defaultMenu || null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const Content = (tabs && tabs[activeTab].component) && tabs[activeTab].component;

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <MultiTabContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        activeSubMenu,
        setActiveSubMenu,
        activeTab,
        setActiveTab,
      }}
    >
      <Grid.Column>
        <Brikk.Heading heading={title || tabs[activeTab].title}>
          {
            tabs && <Tabs tabs={tabs} />
          }

          {
            subTitle && (
            <Text
              large
              bold
              intl={subTitle}
            />
            )
          }

          <Menus
            menus={tabs ? tabs[activeTab].menus : menus}
          />
        </Brikk.Heading>

        {
          (tabs && tabs[activeTab]?.component)
          && <Content selectedTab={tabs[activeTab]?.title} />
        }
      </Grid.Column>

      <Grid.Column>
        <MenuContent />
      </Grid.Column>
    </MultiTabContext.Provider>
  );
};
