import React, { useState } from 'react';
import classNames from 'classnames';
import { Text } from 'components/typography';
import { Image } from 'components/media/Image';
import { useUserSettings, useUpdateUserSettings } from 'services/user/settings';
import { FocusableElement } from 'components/FocusableElement';
import { useTenantConfig } from 'global/tenant-config';
import Loader from 'components/loaders/Loader';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const BackgroundSettings = () => {
  const { background } = useUserSettings();
  const { updateUserSettings, isLoading } = useUpdateUserSettings();
  const { tenantConfig: { backgrounds } } = useTenantConfig();
  const [selectedBackground, setSelectedBackground] = useState(background);

  return (
    <div className={styles.backgroundSettings}>
      <div className={styles.headingWrapper}>
        <Text
          element="h3"
          thin
          medium
          uppercase
          white
          intl="backgroundScreen"
          className={styles.heading}
        />
      </div>

      <div className={styles.settings}>
        {Object.keys(backgrounds).map((bgKey) => (
          <FocusableElement
            key={bgKey}
            className={classNames(
              styles.bgImage,
              background === bgKey && styles.active,
            )}
            onClick={() => {
              updateUserSettings({ background: bgKey });
              setSelectedBackground(bgKey);
            }}
          >
            <Image 
              src={backgrounds[bgKey].background} 
              alt={backgrounds[bgKey].background} 
            />
            <ShouldRender condition={isLoading && selectedBackground === bgKey}>
              <Loader className={styles.loader} />
            </ShouldRender>
          </FocusableElement>
        ))}
      </div>
    </div>
  );
};
