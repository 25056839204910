import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { RealEstateAction } from 'assets/icons/brikks/RealEstateAction';
import { OrderServicePreview } from 'components/brikks/Office/OrderService/components/OrderServicePreview';
import { useTenant } from 'services/user/tenant/hook';
import { BrikkModal } from 'components/modals/BrikkModal';
import { OrderServiceModal } from './components/OrderServiceModal';

export const OrderService = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { tenantId } = useTenant();
  const isTenantHQ = tenantId === 'hq';

  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={isTenantHQ}>
          <Brikk.Header
            icon={<RealEstateAction />}
            heading="orderServiceBrikk"
          />

          <Brikk.Content center>
            <OrderServicePreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              disabled={isTenantHQ}
              brikkNameIntl="orderServiceInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="orderServiceInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <OrderServiceModal
          setIsModalOpen={setIsModalOpen}
        />
      </BrikkModal>
    </>
  );
};
