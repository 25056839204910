import React from 'react';
import { Text } from 'components/typography';
import styles from './styles.module.scss';

export const LabeledElement = ({ label, children }) => (
  <div className={styles.container}>
    <Text
      size={14}
      intl={label}
    />

    {children}
  </div>
);
