import React from 'react';
import classNames from 'classnames';
import { Card, Label, Popup } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { Header, Content, Button } from './components';
import { ShouldRender } from 'components/ShouldRender';
import { Heading } from './components/Heading';
import { ImageText } from './components/ImageText';

export const BrikkContext = React.createContext({ isLoading: false, error: '' });

export const Brikk = ({
  children,
  isDragging,
  disabled,
  isDraggingEnabled,
  isLoading,
  error,
  demo,
}) => {


  return (
    <BrikkContext.Provider value={{ isLoading, error, disabled }}>
      <div
        className={classNames(
          styles.brikk,
          isDragging && styles.dragged,
          isDraggingEnabled && styles.heartbeat,
        )}
      >
        <Card
          raised
          className={styles.content}
        >
          {children}
        </Card>

        <ShouldRender condition={demo}>
          <span className={styles.demo}>
            <Popup
              content='To be done.'
              mouseEnterDelay={200}
              mouseLeaveDelay={400}
              on='hover'
              trigger={<Label as='a' ribbon>Demo</Label>}
            />
          </span>
        </ShouldRender>
      </div>
    </BrikkContext.Provider>
  );
};

Brikk.Header = Header;
Brikk.Content = Content;
Brikk.Button = Button;
Brikk.Heading = Heading;
Brikk.ImageText = ImageText;
