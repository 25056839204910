import React from "react";

export const ToolsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.657"
      viewBox="0 0 20 20.657"
    >
      <path
        d="M20.379,6.813a.433.433,0,0,0-.359-.675H18.552a.433.433,0,0,1-.3-.118l-1.378-1.3a.433.433,0,0,1,0-.631l1.378-1.3a.433.433,0,0,1,.3-.118h1.467A.433.433,0,0,0,20.379,2a4.376,4.376,0,0,0-.539-.656,4.33,4.33,0,0,0-7.3,3.965.433.433,0,0,1-.117.4L4.448,13.686a.433.433,0,0,1-.331.126,3.464,3.464,0,1,0,3.258,3.261.433.433,0,0,1,.126-.33l7.984-7.983a.433.433,0,0,1,.4-.118A4.306,4.306,0,0,0,19.84,7.465a4.369,4.369,0,0,0,.539-.651ZM4.836,18.185a1.3,1.3,0,1,1,0-1.837,1.3,1.3,0,0,1,0,1.837Z"
        transform="translate(-0.452 -0.077)"
      />
    </svg>
  );
};
