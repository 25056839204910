import React from 'react';
import { BgImage } from 'components/media/BgImage';
import { MainOverlay } from './components/MainOverlay';
import { SmallOverlay } from './components/SmallOverlay';

export const ImageWithOverlay = ({ imageSrc,  className, children }) => (
  <BgImage
    className={className}
    bgImage={imageSrc}
  >
    {children}
  </BgImage>
);

ImageWithOverlay.MainOverlay = MainOverlay;
ImageWithOverlay.SmallOverlay = SmallOverlay;
