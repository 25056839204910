import React from 'react';

export const ExtraLinkIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.365'
    height='18.362'
    viewBox='0 0 18.365 18.362'
  >
    <path
      id='Path_7684'
      data-name='Path 7684'
      d='M1043.549,363.664c-.029,0-.058.007-.087.011h-4.555a.781.781,0,0,0-.2,1.551.707.707,0,0,0,.175,0h2.805l-7.99,7.99a.782.782,0,0,0,.93,1.257.747.747,0,0,0,.145-.141l.022-.022,7.986-7.99V369.1a.781.781,0,0,0,1.553.021v-4.577a.781.781,0,0,0-.66-.874A1,1,0,0,0,1043.549,363.664Zm-15.509,3.112a1.563,1.563,0,0,0-1.553,1.553v11.644a1.563,1.563,0,0,0,1.553,1.552h11.643a1.562,1.562,0,0,0,1.553-1.552v-9.764l-1.553,1.553v8.212H1028.04V368.329h8.211l1.553-1.553Z'
      transform='translate(-1026.237 -363.414)'
      fill='#070707'
      stroke='#000'
      strokeMiterlimit='10'
      strokeWidth='0.5'
    />
  </svg>
);
