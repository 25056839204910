import { useMutation, useQueryClient } from 'react-query';
import { deleteRSSFeedAPI } from './api';

const RSS_FEEDS = 'rssFeeds';

export const useDeleteRSSFeed = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    (feedId) => deleteRSSFeedAPI(feedId),
    {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(RSS_FEEDS);
      },
    },
  );

  return {
    deleteRSSFeed: mutate,
    isLoading,
    isError,
    error,
  };
};
