import { useMutation } from 'react-query';
import { useModal } from 'store/modals';
import { REAL_ESTATE_ID } from 'global/environment';
import { WG1 } from 'global/real-estate-config/realEstates';
import { submitContactInfoAPI } from './api';
import { mapDataForBackend } from './mapData';

export function useSubmitContactInfo({ onSuccess }) {
  const { openModal } = useModal();

  const mapData = (contactInfo) => {
    switch (REAL_ESTATE_ID) {
      case WG1:
        return mapDataForBackend(contactInfo);
      default:
        return contactInfo;
    }
  };

  const { mutate, isLoading, isError } = useMutation(
    (contactInfo) => submitContactInfoAPI(mapData(contactInfo)),
    {
      onSuccess: () => {
        onSuccess();
        openModal({
          modalType: 'PopupModal',
          modalProps: {
            title: 'contactUsModalTitle',
            description: 'contactUsModalDescriptionSuccess',
            showActions: false,
          },
        });
      },
      onError: (error, order) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'contactUsModalTitle',
          description: 'contactUsModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(order),
        },
      }),
    },
  );

  return {
    submitContactInfo: mutate,
    isLoading,
    isError,
  };
}
