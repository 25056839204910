import React from 'react';
import { MultiTabMenu } from 'components/MultiTabMenu';
import { UsersList } from './components/UsersList';

export const Users = () => (
  <MultiTabMenu
    title="currentUsers"
    defaultTab="active"
    tabs={{
      active: {
        title: 'active',
        component: UsersList,
        menus: [],
      },
      deactivated: {
        title: 'deactivated',
        component: UsersList,
        menus: [],
      },
    }}
  />
);
