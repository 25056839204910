import { AWS_API_URL } from 'global/environment';
import { authorize, getRequest } from 'utils/http';
import { defaultBrikksOrder } from './utils';

export async function getUserSettingsAPI() {
  try {
    const url = `${AWS_API_URL}/user/settings`;
    let backendSettings = await getRequest(url, await authorize());

    if (backendSettings) {
      if (backendSettings.brikksOrder) {
        const parsedBrikksOrder = JSON.parse(backendSettings.brikksOrder);
        if (!Array.isArray(parsedBrikksOrder)) {
          //skip if using old (pre multigrid) brikks order structure
          backendSettings.brikksOrder = parsedBrikksOrder;
        } else {
          backendSettings.brikksOrder = defaultBrikksOrder;
        }
      }
    } else {
      backendSettings = {};
    }

    return Promise.resolve(backendSettings);
  } catch (error) {
    return Promise.reject(error);
  }
}
