import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { logout, removeAuthToken } from 'utils/auth-token';
import { AppTracking } from 'global/appTracking';
import { useLoginWithEmail } from 'services/auth/login-email/hook';
import { useAuth } from 'services/auth';
import styles from './styles.module.scss';

export const DropdownMenu = () => {
  const { setIsAuthenticated, setUser } = useAuth();

  const history = useHistory();

  const login = useLoginWithEmail();

  const handleLogOut = async event => {
    event.preventDefault();
    try {
      logout(() => {
        login.onResetEmailLogin();
        setIsAuthenticated(false);
        setUser(null);
        removeAuthToken();
        AppTracking.authEvent({ action: 'Logout' });
        window.location = '/login';
      });
    } catch (error) {
    }
  };

  return (
    <div className={styles.menu}>
      <div
        className={styles.menuItem}
        tabIndex={0}
        onClick={() => history.push('/change-pass')}
      >
        <Icon name='lock'/>

        <span className='change-password'>
            <FormattedMessage id="changePassword"/>
        </span>
      </div>

      <div
        className={styles.menuItem}
        tabIndex={0}
        onClick={handleLogOut}
      >
        <Icon name='sign-out'/>

        <span className='logout-settings'>
            <FormattedMessage id="logout"/>
        </span>
      </div>
    </div>
  )
};
