import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import styles from './styles.module.scss';

export const SaveChanges = ({ oldUserDetails, newUserDetails, isLoading }) => {

  const hasChanges = useMemo(() => (
    !isEqual(oldUserDetails, newUserDetails)
  ), [oldUserDetails, newUserDetails]);
 
  return (
    <Button
      className={styles.saveChangesButton}
      type="submit"
      name="submit-user-details"
      disabled={!hasChanges || isLoading}
      loading={isLoading}
      size="large"
    >
      <FormattedMessage id="saveChanges" />
    </Button>
  );
};
