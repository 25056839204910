import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Card = ({ children, transparent, radius, className }) => (
  <div className={classNames(
    styles.card,
    className,
    styles[radius], {
      [styles.transparent]: transparent,
    }
  )}
  >
    {children}
  </div>
);
