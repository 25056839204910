/* eslint-disable */
import React from 'react';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { MyProfile } from 'components/brikks/Office/MyProfile';
import { MyWorkspace } from 'components/brikks/Office/MyWorkspace';
import { Documents } from 'components/brikks/Office/Documents';
import { RefreshmentsFika } from 'components/brikks/Office/RefreshmentsFika';
import { ResourceBooking } from 'components/brikks/Office/ResourceBooking';
import { OrderService } from 'components/brikks/Office/OrderService';
import { Status } from 'components/brikks/Office/Status';
import { Recreation } from 'components/brikks/Office/Recreation';
import { Contact } from 'components/brikks/Office/Contact';
import { Scenarios } from 'components/brikks/Office/Scenarios';
import { Mobility } from 'components/brikks/Office/Mobility';
import { AccessControl } from 'components/brikks/Office/AccessControl';
import { Security } from 'components/brikks/Office/Security';
import { EventConfigurator } from 'components/brikks/Office/EventConfigurator';
import { Logistics } from 'components/brikks/Office/Logistics';
import { ReportIssue } from 'components/brikks/Office/ReportIssue';
import { AccessApp } from 'components/brikks/Office/AccessApp';
import { Network } from 'components/brikks/Office/Network';
import { RSSFeed } from 'components/brikks/Office/RSSFeed';
import { UserAdministration } from "components/brikks/Office/UserAdministration";
import { Weather } from 'components/brikks/Office/Weather';
import { ADMIN, USER } from "utils/user/roles";

export const BRIKKS = [
  { id: "A01", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <MyWorkspace position={position} key="A01"/> },
  { id: "A02", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <MyProfile position={position} key="A02" /> },
  { id: "A03", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Documents position={position} key="A03" /> },
  { id: "A04", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <RefreshmentsFika position={position} key="A04" /> },
  { id: "A05", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <ResourceBooking position={position} key="A05" /> },
  { id: "A06", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <OrderService position={position} key="A06" /> },
  { id: "A07", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Status position={position} key="A07" /> },
  { id: "A08", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Recreation position={position} key="A08" /> },
  { id: "A09", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Contact position={position} key="A09" /> },
  { id: "A10", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Scenarios position={position} key="A10" /> },
  { id: "A11", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Mobility position={position} key="A11" /> },
  { id: "A12", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <AccessControl position={position} key="A12" /> },
  { id: "A13", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Security position={position} key="A13" /> },
  { id: "A14", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <EventConfigurator position={position} key="A14" /> },
  { id: "A15", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Logistics position={position} key="A15" /> },
  { id: "A16", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <ReportIssue position={position} key="A16" /> },
  { id: "A17", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <AccessApp position={position} key="A17" /> },
  { id: "A18", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <RSSFeed position={position} key="A18" /> },
  { id: "A19", category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Network position={position} key="A19" /> },
  { id: "A20", category: MultiGrid.Office, roles: [ADMIN], component: position => <UserAdministration position={position} key="A20" /> },
  { id: 'A21', category: MultiGrid.Office, roles: [ADMIN, USER], component: position => <Weather position={position} key="A21"/> },
]
