import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { sendReportAPI } from './api';

const REPORTS = 'reports';

export function useSendReport({ onSuccess }) {
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    (report) => sendReportAPI(report),
    {
      onSuccess: (data, report) => {
        onSuccess();
        queryClient.invalidateQueries(REPORTS);
        openModal({
          modalType: 'PopupModal',
          modalProps: {
            title: 'reportModalTitleSuccess',
            description: 'reportModalDescriptionSuccess',
            descriptionDetails: report.description,
            showActions: false,
          },
        });
      },
      onError: (error, report) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'reportModalTitleError',
          description: 'reportModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(report),
        },
      }),
    },
  );

  return {
    sendReport: mutate,
    isLoading,
    isError,
  };
}
