import React from 'react';
import { DragAndDrop } from 'components/drag-and-drop';
import { useReorderBrikks } from 'store/reorder-brikks';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { AppHeader } from 'components/headers/AppHeader';
import { PageLoader } from 'components/loaders/PageLoader';
import { useTenantConfig } from 'global/tenant-config';
import { REAL_ESTATE_ID } from 'global/environment';
import { BRIKKS } from 'global/brikks';
import { useUserSettings, useUpdateUserSettings } from 'services/user/settings';
import { useMultiGrid } from 'services/multi-grid/hook';
import styles from 'pages/MainPage/styles.module.scss';
import { useTenantId } from 'services/user/tenant/hook';

const MainPage = () => {
  const { brikksOrder, loading } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings();
  const { shouldReorderBrikks } = useReorderBrikks();
  const { tenantConfig } = useTenantConfig();
  const { selectedGrid } = useMultiGrid();
  const { tenantRole } = useTenantId();

  const tenantBrikks = React.useMemo(() => BRIKKS.filter((brikk) => tenantConfig.brikks.includes(brikk.id) && brikk.roles.includes(tenantRole)), [tenantConfig, tenantRole]);

  const selectedGridBrikks = tenantBrikks.filter(
    (brikk) => brikk.category === selectedGrid,
  );

  const selectedGridBrikksOrder = brikksOrder[selectedGrid];

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className="mainPage">
      <IntlPageTitle intlTitle={REAL_ESTATE_ID} />

      <AppHeader />

      <div className={styles.brikksWrapper}>
        <DragAndDrop
          enabled={shouldReorderBrikks}
          draggables={selectedGridBrikks}
          order={selectedGridBrikksOrder}
          onChange={(newBrikksOrder) => updateUserSettings({
            brikksOrder: {
              ...brikksOrder,
              [selectedGrid]: newBrikksOrder,
            },
          })}
        />
      </div>
    </div>
  );
};

export default MainPage;
