import React from 'react';
import classNames from 'classnames';
import { Placeholder } from 'semantic-ui-react';
import { useTheme } from 'global/themes';
import { useUserDetails } from 'services/user/get-details';
import { Brikk } from '../../Brikk';
import { UserForm } from './components/UserForm';
import styles from './styles.module.scss';

export const UserDetails = () => { 
  const { loading } = useUserDetails();
  const { theme } = useTheme();

  return (
    <div className={styles.userDetails}>
      <Brikk.Heading heading="myInfo">
        {loading ? (
          <Placeholder>
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
          </Placeholder>
        ) : <UserForm />}
      </Brikk.Heading>
    </div>
  );
};
