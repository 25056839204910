import React from 'react';
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { Form } from 'components/form-elements/Form';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { ShouldRender } from 'components/ShouldRender';
import { useUserDetails } from 'services/user/get-details';
import { useUpdateUserDetails } from 'services/user/update-details';
import { validatePhone } from 'utils/validation';
import { PHONE_FORMAT } from 'utils/phoneNumber';
import { SaveChanges } from './components/SaveChanges';
import styles from './styles.module.scss';

export const UserForm = () => {
  const { userDetails } = useUserDetails();
  const { updateUserDetails, isLoading } = useUpdateUserDetails();

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: userDetails,
  });
  const newUserDetails = watch();

  const onSubmit = (userDetails) => {
    updateUserDetails(userDetails);
  };

  return (
    <Form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.equalColumns}>
        <Controller
          name="givenName"
          control={control}
          render={({ field }) => (
            <LabeledInput
              labelIntl="givenName"
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
            />
          )}
        />

        <Controller
          name="familyName"
          control={control}
          render={({ field }) => (
            <LabeledInput
              labelIntl="familyName"
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
            />
          )}
        />
      </div>

      <LabeledInput
        disabled
        labelIntl="email"
        id="email"
        type="email"
        value={userDetails.email}
      />

      <Controller
        name="workNumber"
        control={control}
        rules={{ validate: value => validatePhone(value), pattern: PHONE_FORMAT }}
        render={({ field }) => (
          <LabeledInput
            labelIntl="phone"
            hasError={!!errors.workNumber}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            inputRef={field.ref}
          />
        )}
      />

      <ShouldRender condition={!!errors.workNumber}>
        <Message
          negative
        >
          <FormattedMessage id="workNumberError" />
        </Message>
      </ShouldRender>

      <LabeledInput
        labelIntl="organization"
        id="organization"
        value={userDetails.organization}
        disabled
      />

      <SaveChanges
        oldUserDetails={{ ...userDetails }}
        newUserDetails={{ ...newUserDetails }}
        isLoading={isLoading}
      />
    </Form>
  );
};
