import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Menu} from "semantic-ui-react";
import { useTheme } from 'global/themes';
import { AuthenticatedMenu } from './componenets/AuthenticatedMenu';
import { useAuth } from  'services/auth';
import { ShouldRender } from 'components/ShouldRender';
import { Logo } from 'components/headers/AppHeader/components/Logo';

const PageHeader = ({ transparent, fixed, noLogin, children = <AuthenticatedMenu /> }) => {
  const { isAuthenticated } = useAuth();

  const { theme } = useTheme();

    return (
        <header className={classNames(styles.header, fixed && styles.fixed)}>
            <Menu
              borderless
              className={classNames(styles.pageHeader, transparent && styles.transparent, theme.backgroundColor)}
            >
              <Logo />

                {!isAuthenticated ? (
                  <ShouldRender condition={!noLogin}>
                    <Menu.Menu position='right' className='user-menu'>
                      <Menu.Item as='a' onClick={ () => window.location.href = '/login' } className='login'>
                        Login
                      </Menu.Item>
                    </Menu.Menu>
                  </ShouldRender>
                ) : children
                }

            </Menu>
        </header>
    )
};

export default PageHeader;
