import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

const lat = '55.7046';
const lon = '13.1910';

export async function getWeatherAPI() {
  const url = `${AWS_API_URL}/weather?lat=${lat}&lon=${lon}`;
  const data = await getRequest(url, await authorize());
  
  return mapDataToClient(data);
}
