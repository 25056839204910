import React, { useState } from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { useRSSFeeds } from 'services/rss-feeds/get-rss-feeds';
import Loader from 'components/loaders/Loader';
import { FeedArticles, SelectedArticle } from './components';
import styles from './styles.module.scss';

export const RSSFeedModal = ({ feed, preSelectedArticle }) => {
  const [selectedArticle, setSelectedArticle] = useState(preSelectedArticle);
  const { isLoading, isFetching } = useRSSFeeds();

  if (isLoading || isFetching) return <Loader />;

  return (
    <div className={styles.modal}>
      <IntlPageTitle intlTitle="myNews" />

      <div className={styles.feedArticles}>
        <FeedArticles
          feed={feed}
          onSelectArticle={setSelectedArticle}
        />
      </div>

      <div className={styles.selectedArticle}>
        <SelectedArticle
          selectedArticle={selectedArticle}
        />
      </div>
    </div>
  );
};
