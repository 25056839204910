import React from 'react';
import { Image } from 'semantic-ui-react';
import userSrc from 'assets/media/user.png';
import { Text } from 'components/typography';
import { Grid } from 'components/layout/Grid';
import styles from './styles.module.scss';

export const UserDetailsCard = ({ selectedUser }) => (
  <div className={styles.card}>
    <Image
      className={styles.img}
      id="user-avatar"
      alt="user-avatar"
      src={selectedUser.imageUrl || userSrc}
      size="tiny"
      circular
    />

    <Grid
      noPadding
      oneColumn
      style={{ 
        gap: '5px', 
        width: 'auto', 
        justifyItems: 'flex-start', 
      }}
    >
      <Text
        size={14}
      >
        {selectedUser.firstName} {selectedUser.lastName}
      </Text>

      <Text size={14}> {selectedUser.email} </Text>

      <Text size={14}> {selectedUser.phoneNumber} </Text>
    </Grid>

  </div>
);
