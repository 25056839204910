export const Tenants = {
  KUNGSLEDEN: {
    aliases: ['kungsleden'],
    tenantId: 'kungsleden',
  },
  HQ: {
    aliases: ['hq'],
    tenantId: 'hq',
  },
  EDEN: {
    aliases: ['eden'],
    tenantId: 'eden',
  },
  WG1: {
    aliases: ['wg1'],
    tenantId: 'wg1',
  },
  CONSULENCE: {
    aliases: ['consulence'],
    tenantId: 'consulence',
  },
  VIAEUROPA: {
    aliases: ['viaeuropa'],
    tenantId: 'viaeuropa',
  },
  BRIKKS: {
    aliases: ['brikks'],
    tenantId: 'brikks',
  },
};
