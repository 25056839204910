import React, { useRef } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import networkSrc from 'assets/media/network.png';
import { Button } from 'components/form-elements/buttons/Button';
import RemoveDevice from 'assets/media/eden/remove-device-scr.png';
import CoverageMap from 'assets/media/eden/coverage-map-scr.png';
import { Image as SemanticImage, Ref } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomToggle } from 'components/form-elements/toggles/CustomToggle';
import { Text } from 'components/typography';
import { NetworkIcon } from 'assets/icons/NetworkIcon';
import { PasswordInput } from 'components/form-elements/PasswordInput';
import styles from './styles.module.scss';

export const NetworkModal = () => {
  const intl = useIntl();
  const submitRef = useRef(null);

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="network" />

      <Grid.Column>
        <Brikk.Heading
          main
          heading="networkBrikk"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={networkSrc}
              alt="Network"
            />
            <ModalIntro.Text intl="networkDescription1" />
            <ModalIntro.Text intl="networkDescription2" />
            <ModalIntro.Text intl="networkDescription3" />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="statusAndControl">
          <ModalIntro>
            <div className={styles.card}>
              <div className={styles.title}>
                <NetworkIcon />
                <Text
                  small
                  bold
                  uppercase
                >
                  <FormattedMessage id="yourNetworkAccount" />
                </Text>
              </div>

              <div className={styles.info}>
                <div>
                  <FormattedMessage id="networkUsername" />:{' '}
                  <span className={styles.infoItem}>
                    jasmin.persson@gnail.com
                  </span>
                </div>
                <div>
                  <FormattedMessage id="networkPassword" />:{' '}
                  <span className={styles.infoItem}>*********</span>
                </div>
                <div className={styles.password}>
                  <FormattedMessage id="showPassword" />
                  <CustomToggle checked />
                </div>
              </div>
              <div className={styles.networkPassword}>
                <FormattedMessage id="changeNetworkPassword" />
                <div className={styles.passwordFields}>
                  <PasswordInput 
                    className={styles.newPassword}
                    fluid
                    id="newpassword"
                    name="new-password"
                    placeholder={intl.formatMessage({
                      id: 'newNetworkPassword',
                    })}
                    submitRef={submitRef}
                  />
                  <PasswordInput
                    fluid
                    id="repeatpassword"
                    name="repeat-password"
                    placeholder={intl.formatMessage({
                      id: 'repeatNetworkPassword',
                    })}
                    submitRef={submitRef}
                  />
                </div>

                <div className={styles.setNetworkPassword}>
                  <Ref innerRef={submitRef}>
                    <Button
                      intl="setNetworkPassword"
                      onClick={() => console.log('changing password placeholder')}
                    />
                  </Ref>
                </div>
              </div>
            </div>
            <SemanticImage
              src={RemoveDevice}
              alt="Remove device"
            />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="coverageMap">
          <ModalIntro>
            <SemanticImage
              src={CoverageMap}
              alt="Remove device"
            />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>
    </Grid>
  );
};
