import React, { useContext } from 'react';
import { Grid } from 'components/layout/Grid';
import { useModal } from 'store/modals';
import { useDisableUser } from 'services/admin/disable-user';
import { UserAdministrationModalContext } from 
  'components/brikks/Office/UserAdministration/components/UserAdministrationModal';
import { useEnableUser } from 'services/admin/enable-user';
import { ActionButton } from 'components/media/DocumentPreview/components/ActionButton';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';

export const UserUpdateAccess = ({ selectedUser }) => {
  const isActive = selectedUser.userStatus === 'active';
  const { openModal } = useModal();
  const { setSelectedUser } = useContext(UserAdministrationModalContext);
  
  const intl = useIntl();

  const { disableUser } = useDisableUser({
    onSuccess: () => { 
      setSelectedUser(null);
    }, 
  });

  const { enableUser } = useEnableUser({
    onSuccess: () => {
      setSelectedUser(null);
    },
  });

  const handleMethod = (type) => {
    switch (type) {
      case 'disable':
        return disableUser(selectedUser.userId);
      case 'enable':
        return enableUser(selectedUser.userId);
      default:
        return null;
    }
  };

  const handleAction = (type) => {
    let message;
    switch (type) {
      case 'disable':
        message = intl.formatMessage(
          {
            id: 'deactivateUserPopup',
          },
          {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
          },
        );
        break;
      case 'enable':
        message = intl.formatMessage(
          {
            id: 'activateUserPopup',
          },
          {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
          },
        );
        break;
      default:
        message = '';
    }

    openModal({
      modalType: 'ConfirmationModal',
      modalProps: {
        title: message,
        onConfirm: () => handleMethod(type),
      },
    });

  };

  return (
    <Grid
      noPadding
      oneColumn
      style={{
        justifyItems: 'flex-end',
      }}
    >
      <ActionButton
        intl={isActive ? 'deactivateUser' : 'activateUser'}
        onClick={() => handleAction(isActive ? 'disable' : 'enable')}
        className={classNames(styles.action, isActive ? styles.disable : styles.enable)}
      >
        {
          isActive ? <DeleteIcon size={30} /> : <CheckMarkIcon green />
        }
      </ActionButton>
    </Grid>
  );
}; 
