import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { getWeatherAPI } from './api';

const WEATHER = 'weather';

export function useWeather() {
  const { tenantId } = useTenantId();

  const { data, isLoading, isFetching, isError } = useQuery(
    [WEATHER, tenantId],
    () => getWeatherAPI(),
  );

  return {
    weather: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
