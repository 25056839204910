import React from 'react';

export const NetworkIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width}
    height={props.height}
    viewBox='0 0 16.771 15.6'
  >
    <g id='wifi' transform='translate(-0.85 -1.629)'>
      <path
        id='Path_7979'
        data-name='Path 7979'
        d='M3.955,9.1a.94.94,0,1,0,1.33,1.329,6.59,6.59,0,0,1,9.306,0,.939.939,0,0,0,.665.275h0a.933.933,0,0,0,.936-.918V9.766a.934.934,0,0,0-.275-.664,8.471,8.471,0,0,0-11.962,0Z'
        transform='translate(-0.702 -1.24)'
      />
      <path
        id='Path_7980'
        data-name='Path 7980'
        d='M17.346,4.982a11.483,11.483,0,0,0-16.221,0,.94.94,0,1,0,1.33,1.33,9.6,9.6,0,0,1,13.561,0,.94.94,0,1,0,1.33-1.33Z'
      />
      <path
        id='Path_7981'
        data-name='Path 7981'
        d='M12.43,11.933a5.454,5.454,0,0,0-5.647,1.287.94.94,0,0,0,.664,1.6h0a.938.938,0,0,0,.665-.276,3.571,3.571,0,0,1,4.909-.117c.047.041.1.075.143.117h0a.938.938,0,0,0,.663.274h0a.941.941,0,0,0,.664-1.606,5.406,5.406,0,0,0-2.06-1.284Z'
        transform='translate(-1.403 -2.479)'
      />
      <circle
        id='Ellipse_377'
        data-name='Ellipse 377'
        cx='1.88'
        cy='1.88'
        r='1.88'
        transform='translate(7.355 13.468)'
      />
    </g>
  </svg>
);
