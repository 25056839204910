import React from 'react';
import { Input } from 'components/form-elements/inputs/Input';
import styles from './styles.module.scss';

export const SearchInput = ({ keyword, setKeyword, placeholder }) => (
  <Input
    className={styles.input}
    placeholder={placeholder}
    value={keyword}
    onChange={(e) => {
      setKeyword(e.target.value);
    }}
  />
);
