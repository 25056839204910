import React from 'react';
import { Text } from 'components/typography/Text';
import { Image } from 'components/media/Image';
import entryfyImgSrc from 'assets/media/eden/access-app/entryfy.png';
import iosDownloadBadge from 'assets/media/ios-download-badge.svg';
import adroidDownloadBadge from 'assets/media/google-play-badge.png';
import styles from './styles.module.scss';
import { ExternalLink } from 'components/links/ExternalLink';

export const Download = () => (
  <>
    <Text intl='accessAppDownloadDescription' size={18} />

    <Image src={entryfyImgSrc} />

    <div className={styles.downloadBadges}>
      <ExternalLink href='https://apps.apple.com/se/app/entryfy/id1497879952'>
        <Image src={iosDownloadBadge} className={styles.icon} />
      </ExternalLink>
      <ExternalLink href='https://play.google.com/store/apps/details?id=com.entryfy&hl=en_US&gl=US'>
        <Image src={adroidDownloadBadge} className={styles.iconAndroid} />
      </ExternalLink>
    </div>
  </>
);
