import React, { useContext } from 'react';
import { useReports } from 'services/reports/get-reports';
import { PageLoader } from 'components/loaders/PageLoader';
import { Text } from 'components/typography';
import { TicketContext } from 'components/brikks/Office/Status/components/StatusModal/index';
import { TicketItem } from '../TicketItem';

export const TicketsList = ({ selectedTab }) => {

  const { reports, isLoading } = useReports();
  const { setSelectedTicket } = useContext(TicketContext);

  const activeReports = selectedTab === 'closed' ? reports?.filter(report => report.statusName === 'Kvitterad')
    : reports?.filter(report => report.statusName !== 'Kvitterad');

  if (isLoading) return <PageLoader />;

  return (
    <div>
      {
        activeReports && activeReports.length > 0
          ? activeReports.map((ticket, index) => (
            <TicketItem
              key={index}
              ticket={ticket}
              onClick={() => setSelectedTicket(ticket)}
            />
          ))
          : (
            <Text
              secondary
              center
              intl="noItems"
              size={16}
            />
          )
      }
    </div>
  );
};
