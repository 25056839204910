import React from 'react';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { Text } from 'components/typography';
import styles from './styles.module.scss';

export const DownloadButton = ({ loading = false, onClick, error = false, fillBlue, disabled }) => (
  <div
    className={styles.buttonGroup}
    onClick={() => onClick()}
  >
    <DownloadIcon
      loading={loading}
      error={error}
      fillBlue={fillBlue}
      disabled={disabled}
    />
      
    <Text intl="download" />
  </div> 
);
