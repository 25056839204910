import React from "react";
import {Message} from "semantic-ui-react";
import {FormattedMessage} from 'react-intl';
import styles from './ChangePasswordConfirmation.module.scss';
import {IntlPageTitle} from 'components/intl/IntlPageTitle';
import PageHeader from "components/headers/PageHeader";

const ChangePasswordConfirmation = (props) => (
  <div className={styles.changePasswordConfirmation}>
    <IntlPageTitle intlTitle="passwordRecoverySuccess"/>

    <PageHeader transparent noLogin/>

    <div className={styles.confirmationMessage}>
      <Message positive>
        <Message.Header>
          <p>
            <FormattedMessage id="passwordRecoverySuccess"/>
          </p>
          <p>
            <a href='/login'
               onClick={(e) => {
                 e.preventDefault();
                 props.history.push('/login')
               }}
            >
              <FormattedMessage id="backToLogin"/>
            </a>
          </p>
        </Message.Header>
      </Message>
    </div>
  </div>
);

export default ChangePasswordConfirmation;
