import { takeLatest, put, call } from 'redux-saga/effects';
import {
  UserAvatarActions,
  getUserAvatarSuccess,
  uploadUserAvatarSuccess,
  uploadUserAvatarFail, getUserAvatarFail
} from "./actions";
import { getUserAvatarAPI, uploadUserAvatarAPI } from "./api";
import { AppTracking } from 'global/appTracking';

function* getUserAvatar() {
  try {
    const avatar = yield call(getUserAvatarAPI);
    yield put(getUserAvatarSuccess(avatar.url));
  } catch (e) {
    yield put(getUserAvatarFail(e.message));
  }
}

function* uploadUserAvatar({ payload }) {
  AppTracking.settingsEvent({ action: 'Change Avatar' });
  try {
    yield call(uploadUserAvatarAPI, payload);
    const avatar = yield call(getUserAvatarAPI);
    yield put(uploadUserAvatarSuccess(avatar.url));
  } catch (e) {
    yield put(uploadUserAvatarFail(e.message));
  }
}

export const userAvatarEffects = [
  takeLatest(UserAvatarActions.GET_AVATAR, getUserAvatar),
  takeLatest(UserAvatarActions.UPLOAD_AVATAR, uploadUserAvatar),
];
