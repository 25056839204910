import React from 'react';

export const HeadphonesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <path
        id='headphones-customer-support'
        d='M20,12.167a3.82,3.82,0,0,0-2.5-3.608V7.911A7.581,7.581,0,0,0,10,.251a7.581,7.581,0,0,0-7.5,7.66v.648a3.858,3.858,0,0,0,0,7.225,1.228,1.228,0,0,0,1.14-.163,1.285,1.285,0,0,0,.526-1.046V7.911A5.9,5.9,0,0,1,10,1.953,5.9,5.9,0,0,1,15.83,7.911v6.664a1.284,1.284,0,0,0,.417.936V16c0,1.255-1.076,1.7-2.083,1.7H12.682a1.652,1.652,0,0,0-1.874-.793,1.712,1.712,0,0,0,0,3.289,1.652,1.652,0,0,0,1.874-.793h1.481A3.434,3.434,0,0,0,17.913,16v-.407A3.839,3.839,0,0,0,20,12.167Z'
        transform='translate(0.004 -0.251)'
      />
    </svg>
  );
};
