import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ProcessingLoader } from '../../../loaders/ProcessingLoader';

export const Button = ({ children, name, disabled, intl, onClick, loading, className }) => (
  <button
    className={classNames(styles.button, className)}
    name={name}
    disabled={disabled || loading}
    onClick={onClick}
  >
    {loading ? <ProcessingLoader /> : intl ? <FormattedMessage id={intl} /> : { children }}
  </button>
);
