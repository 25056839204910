import React from "react";

export const RealEstateAction = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.657"
      viewBox="0 0 20 20.657"
    >
      <path
        id="real-estate-action-house-wrench"
        d="M19.781,9.841,10.61.251A.866.866,0,0,0,9.4.251L.234,9.841a.834.834,0,0,0,.6,1.411H2.085a.417.417,0,0,1,.417.417v7.088a1.251,1.251,0,0,0,1.251,1.251H16.26a1.251,1.251,0,0,0,1.251-1.251V11.668a.417.417,0,0,1,.417-.417h1.251a.834.834,0,0,0,.6-1.411Zm-8.315,3.105a.417.417,0,0,0-.208.361v3.781a.417.417,0,0,1-.417.417H9.173a.417.417,0,0,1-.417-.417V13.307a.417.417,0,0,0-.208-.361A2.918,2.918,0,0,1,10.87,7.629a.417.417,0,0,1,.167.695l-.722.716a1.042,1.042,0,1,0,1.468,1.481l.429-.425a.417.417,0,0,1,.71.322A2.918,2.918,0,0,1,11.466,12.946Z"
        transform="translate(-0.002 -0.007)"
      />
    </svg>
  );
};
