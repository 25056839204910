import { useMutation, useQueryClient } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { updateUserSettingsAPI } from './api';
import { mapDataToClient } from './mapData';

const USER_SETTINGS = 'user-settings';

export function useUpdateUserSettings() {
  const queryClient = useQueryClient();
  const { tenantId } = useTenantId();

  const { mutate, isLoading, isError } = useMutation(
    (userSettings) => updateUserSettingsAPI(userSettings),
    {
      onSuccess: (data, userSettings) => {
        queryClient.setQueryData([USER_SETTINGS, tenantId], (oldData) => mapDataToClient(oldData, userSettings));
      },
    },
  );

  return {
    updateUserSettings: mutate,
    isLoading,
    isError,
  };
}
