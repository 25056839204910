import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import bookSpaceSrc from 'assets/media/book-space.jpg';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from 'components/links/ExternalLink';
import { ExtraLinkIcon } from 'assets/icons/ExtraLinkIcon';
import { Text } from 'components/typography';
import { Manuals } from './components/Manuals';
import { Download } from './components/Download';
import styles from './styles.module.scss';

export const ResourceBookingModal = () => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="bookResource" />

    <Grid.Column>
      <Brikk.Heading
        main
        heading="bookResource"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={bookSpaceSrc}
            alt="Book a space"
          />
          <ModalIntro.Text intl="resourceBookingDescription" />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="list">
        <ModalIntro>
          <Manuals />

          <ModalIntro.Text intl="bookSpaceManualDescription" />
          <div className={styles.externalLink}>
            <ModalIntro.Text intl="bookSpaceManualExternalLink" />
            <ExternalLink href="https://www.quickoffice.se/kontorshotell/malmo-hyllie-eden/motesrum/">
              <div className={styles.externalLinkContent}>
                <ExtraLinkIcon />
                <Text bold>
                  <FormattedMessage id="externalLink" />
                </Text>
              </div>
            </ExternalLink>
          </div>
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="download">
        <ModalIntro>
          <ModalIntro.Text intl="downloadDescription" />
          <Download />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>
  </Grid>
);
