import React, { useState, useRef } from 'react';
import { Auth } from "aws-amplify";
import { Form, Button, Message, Ref } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';
import { AppTracking } from 'global/appTracking';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { PasswordInput } from "components/form-elements/PasswordInput";

export const ForgotPasswordVerify = (props) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [fieldError, setFieldError] = useState('');
    const [cognitoError, setCognitoError] = useState(null);

    const intl = useIntl();

    const { theme } = useTheme();

    const submitRef = useRef(null);

    const onVerificationCodeChange = event => {
        setVerificationCode(event.target.value);
        setFieldError('');
    };

    const onEmailChange = event => {
        setEmail(event.target.value);
        setFieldError('');
    };

    const onNewPasswordChange = event => {
        setNewPassword(event.target.value);
        setFieldError('');
    };

    const passwordVerificationHandler = async event => {
        event.preventDefault();

        if (!verificationCode.trim().length) {
            return setFieldError('fieldsRequired');
        } else if (!email.trim().length) {
            return setFieldError('fieldsRequired');
        } else if (!new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/gm).test(newPassword)) {
            return setFieldError('invalidPassword');
        }

        setCognitoError(null);

        // AWS Cognito integration
        try {
            setLoading(true);
            await Auth.forgotPasswordSubmit(
                email,
                verificationCode,
                newPassword
            );
            setLoading(false);
            AppTracking.authEvent({ action: 'Account Activated' });
            props.history.push("/change-pass-confirmation");
        } catch (error) {
            setLoading(false);
            switch (error.code) {
                case 'CodeMismatchException':
                case 'ExpiredCodeException':
                    return setCognitoError('invalidCode');
                case 'NotAuthorizedException':
                    return setCognitoError('emailNotFound');
                case 'LimitExceededException':
                    return setCognitoError('limitExceeded');
                case 'UserNotFoundException':
                    return setCognitoError('emailNotRegistered');
                default:
                    return setCognitoError('genericErrorMessage');
            }
        }
    };

    return (
        <BasicPageLayout intlTitle="confirmPassword">
            <Form size='large' onSubmit={passwordVerificationHandler}>
                <Text
                    element="h1"
                    size={32}
                    intl="verifyPasswordTitle"
                />

                <div className={styles.subtitle}>
                    <Text
                        size={16}
                        gray
                        intl="verifyPasswordSubtitle"
                    />
                </div>

                <ShouldRender condition={!!fieldError || !!cognitoError}>
                    <Message negative>
                        <Message.Content>
                            <FormattedMessage id={fieldError || cognitoError} />
                        </Message.Content>
                    </Message>
                </ShouldRender>

                <Form.Input
                    className={theme.textColor}
                    fluid
                    id="verificationcode"
                    name="verification-code"
                    label={intl.formatMessage({ id: 'recoveryCode' })}
                    icon='hashtag'
                    iconPosition='left'
                    type="text"
                    placeholder={intl.formatMessage({ id: 'recoveryCodePlaceholder' })}
                    aria-describedby="verificationCodeHelp"
                    value={verificationCode}
                    onChange={onVerificationCodeChange}
                />

                <Form.Input
                    className={theme.textColor}
                    fluid
                    id="email"
                    name="email"
                    label={intl.formatMessage({ id: 'email' })}
                    icon='mail'
                    iconPosition='left'
                    type="email"
                    placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={onEmailChange}
                />

                <PasswordInput
                  className={theme.textColor}
                  fluid
                  id="newpassword"
                  name="new-password"
                  label={intl.formatMessage({ id: 'newPassword' })}
                  icon='lock'
                  iconPosition='left'
                  placeholder={intl.formatMessage({ id: 'newPasswordPlaceholder' })}
                  value={newPassword}
                  onChange={onNewPasswordChange}
                  submitRef={submitRef}
                />

                <div className='field'>
                    <Ref innerRef={submitRef}>
                        <Button
                            loading={loading}
                            floated='right'
                            type='submit'
                            size='large'
                            name="change-password"
                        >
                            <FormattedMessage id="send" />
                        </Button>
                    </Ref>
                </div>
            </Form>
        </BasicPageLayout>
    );
};
