import React from 'react';
import image from 'assets/media/book-space.jpg';
import { FormattedMessage } from 'react-intl';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { Text } from 'components/typography';

export const ResourceBookingPreview = () => (
  <div>
    <CircularImage
      src={image}
      alt="Resource Booking"
    />
    <Text><FormattedMessage id="resourceBookingPreviewDescription" /></Text>
  </div>
);
