import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import contactImage from 'assets/media/eden/contact-us-sm.png';
import { OfficeAndCallCenterStatus } from 'components/brikks/sub-components/OfficeAndCallCenterStatus';

export const ContactPreview = () => (
  <>
    <CircularImage 
      src={contactImage} 
      alt="My workspace image" 
    />
    <OfficeAndCallCenterStatus 
      office 
    />
  </>
);
