import React from 'react';

export const Form = ({ className, children, onSubmit }) => (
  <form
    className={className}
    onSubmit={(e) => {
    e.preventDefault();
    onSubmit();
  }}
  >
    {children}
  </form>
);
