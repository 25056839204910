import React from 'react';
import styles from './styles.module.scss';

export const CogIcon = () => (
  <svg
    className={styles.content}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25">
    <path d="M24.445,10.325,21.577,9.62a9.466,9.466,0,0,0-.662-1.575L22.251,5.82a.732.732,0,0,0-.11-.895L20.07,2.853a.73.73,0,0,0-.895-.11L16.946,4.079a9.436,9.436,0,0,0-1.566-.656L14.675.555A.732.732,0,0,0,13.965,0h-2.93a.732.732,0,0,0-.71.555s-.489,2-.7,2.867a9.4,9.4,0,0,0-1.662.708L5.647,2.743a.734.734,0,0,0-.895.11L2.681,4.925a.732.732,0,0,0-.11.895L4,8.206a9.438,9.438,0,0,0-.577,1.411l-2.868.707a.732.732,0,0,0-.555.71v2.93a.732.732,0,0,0,.555.71l2.868.705a9.447,9.447,0,0,0,.674,1.6L2.818,19.113a.732.732,0,0,0,.11.895L5,22.079a.735.735,0,0,0,.895.11l2.136-1.28a9.436,9.436,0,0,0,1.589.669l.7,2.867a.731.731,0,0,0,.71.555h2.93a.732.732,0,0,0,.71-.555l.7-2.867A9.3,9.3,0,0,0,17,20.888l2.17,1.3a.732.732,0,0,0,.895-.11l2.071-2.071a.732.732,0,0,0,.11-.895L20.934,16.92a9.38,9.38,0,0,0,.643-1.539l2.868-.705a.732.732,0,0,0,.555-.71v-2.93A.732.732,0,0,0,24.445,10.325ZM12.5,17.627A5.127,5.127,0,1,1,17.627,12.5,5.133,5.133,0,0,1,12.5,17.627Z"/>
  </svg>

);
