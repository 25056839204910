import React from 'react';
import classNames from 'classnames'
import styles from './styles.module.scss';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';

export const OnboardingImage = ({ children, className, imageSrc }) => (
  <ImageWithOverlay
    imageSrc={imageSrc}
    className={classNames(styles.onboardingImage, className)}
  >
    {children}
  </ImageWithOverlay>
);
