import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import orderServiceSrc from 'assets/media/order-service.png';
import { Menus } from './components/Menus';
import { OrderForm } from './components/OrderForm';

export const OrderServiceModal = ({ setIsModalOpen }) => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="orderService" />

    <Grid.Column>
      <Brikk.Heading
        main
        heading="orderService"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={orderServiceSrc}
            alt="Order service"
          />

          <ModalIntro.Text intl="orderServiceDescription" />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Menus />
    </Grid.Column>

    <Grid.Column>
      <OrderForm
        setIsModalOpen={setIsModalOpen}
      />
    </Grid.Column>

  </Grid>
);
