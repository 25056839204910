import React from 'react';
import { Radio as SemanticRadio } from 'semantic-ui-react';
import { FocusableElement } from '../../FocusableElement';

export const Radio = ({ toggle, checked, onChange, name, value, label, className, disabled, inputRef }) => (
  <FocusableElement>
    <SemanticRadio
      className={className}
      name={name}
      value={value}
      label={label}
      toggle={toggle}
      checked={checked}
      onChange={onChange}
      ref={inputRef}
      disabled={disabled}
    />
  </FocusableElement>
);
