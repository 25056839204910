import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { getUserDetailsAPI } from './api';

const USER_DETAILS = 'userDetails';

export function useUserDetails() {
  const { tenantId } = useTenantId();

  const { data, isLoading, isError } = useQuery(    
    [USER_DETAILS, tenantId],
    () => getUserDetailsAPI(),
    { enabled: tenantId !== null },
  );

  return {
    userDetails: data || [],
    isLoading,
    isError,
  };
}
