import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const ForecastItem = ({ label, labelIntl, icon, temp, tempMin }) => (
  <div className={styles.ForecastItem}>
    <div className={styles.label}>
      { labelIntl
        ? <FormattedMessage id={labelIntl} />
        : label}
    </div>

    <div className={styles.icon}>
      {icon}
    </div>

    <div className={styles.temp}>
      {`${temp}°`}
    </div>

    <ShouldRender condition={!!tempMin}>
      <div>
        {tempMin}°
      </div>
    </ShouldRender>
  </div>
);
