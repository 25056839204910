import { RealEstates } from 'global/real-estate-config/realEstates';
import { REAL_ESTATE_ID } from 'global/environment';

export function getRealEstateIdBasedOnURL() {
  const domainURL = window.location.hostname;

  const realEstateKeys = Object.keys(RealEstates);

  for (let i = 0; i < realEstateKeys.length; i++) {
    const { aliases, realEstateId } = RealEstates[realEstateKeys[i]];

    if (aliases.find((alias) => domainURL.includes(alias))) {
      return realEstateId;
    }
  }
  return REAL_ESTATE_ID;
}
