import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { HeadphonesIcon } from 'assets/icons/brikks/HeadphonesIcon';
import { useTenantId } from 'services/user/tenant/hook';
import { ContactPreview } from './components/ContactPreview';
import { ContactUsModal } from './components/ContactUsModal';

export const Contact = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tenantId } = useTenantId();
  
  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={tenantId === 'hq'}>
          <Brikk.Header 
            icon={<HeadphonesIcon />} 
            heading="contactBrikk" 
          />

          <Brikk.Content center>
            <ContactPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              disabled={tenantId === 'hq'}
              brikkNameIntl="contact"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="worspaceApp"
        onClose={() => setIsModalOpen(false)}
      >
        <ContactUsModal setIsModalOpen={setIsModalOpen} />
      </BrikkModal>
    </>
  );
};
