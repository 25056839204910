import React, { useState } from 'react';
import FormErrors from "components/messages/FormErrors";
import Validate from "../../../utils/FormValidation";
import { Auth } from "aws-amplify";
import { Form, Button, Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { AppTracking } from '../../../global/appTracking';
import { Text } from 'components/typography/Text';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';

export const ActivateAccount = (props) => {
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);
  const [blankfieldError, setBlankfieldError] = useState(false);

  const intl = useIntl();

  const onEmailChange = event => {
    setEmail(event.target.value);
    document.getElementById(event.target.id).closest('.field').classList.remove("error");
  };

  const forgotPasswordHandler = async event => {
    event.preventDefault();

    // Form validation
    setCognitoError(null);
    setBlankfieldError(false);
    Validate(event, { email, setBlankfieldError });

    // AWS Cognito integration
    try {
      setLoading(true);
      await Auth.forgotPassword(email);
      setLoading(false);
      AppTracking.authEvent({ action: 'Activation Email' });
      props.history.push('/forgot-pass-verify');
    } catch (error) {
      setLoading(false);
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      setCognitoError(err);
    }
  };

  return (
    <BasicPageLayout intlTitle="activateAccount">
      <Form size='large' onSubmit={forgotPasswordHandler}>
        <Header as='h1'>
          <FormattedMessage id="activateAccount" />
        </Header>

        <Text
          gray
          intl="activateAccountDescription"
          className={styles.subtitle}
        />

        <FormErrors formerrors={{ cognitoError, blankfieldError }} />

        <Form.Input
          fluid
          id="email"
          name="email"
          label={intl.formatMessage({ id: 'email' })}
          icon='mail'
          iconPosition='left'
          type="email"
          placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
          aria-describedby="emailHelp"
          value={email}
          onChange={onEmailChange}
        />

        <div className={styles.buttons}>
          <a href='/login'
            onClick={(e) => {
              e.preventDefault();
              props.history.push('/login')
            }}
          >
            <FormattedMessage id="backToLogin" />
          </a>

          <Button
            name="submit"
            loading={loading}
            type='submit'
            size='large'
          >
            <FormattedMessage id="sendCode" />
          </Button>
        </div>
      </Form>
    </BasicPageLayout>
  );
};
