import React from 'react';
import { Text } from 'components/typography/Text';
import { Radio } from 'components/form-elements/Radio';
import { useTheme } from 'global/themes';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

export const Checkbox = ({ intl, themeName, disabled }) => {
  const { currentTheme: theme, setTheme } = useTheme();

  return (
    <div className={classNames(styles.checkbox, disabled && styles.disabled)}>
      <Text
        className={classNames(styles.text, disabled && styles.disabled)}
        intl={intl}
      />

      <Radio
        toggle
        checked={theme === themeName}
        onChange={() => {
          !disabled && setTheme(themeName);
        }}
        disabled={disabled}
      />
      {disabled && <div className={styles.disabledLayer} />}
    </div>
  );
};
