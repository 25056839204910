import React from 'react';
// eslint-disable-next-line max-len
import { GeneralInfoOptions } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/EdenTabOptions/generalInfoOptions';

import { MultiTabMenu } from 'components/MultiTabMenu';

export const EdenWorkspaceInfo = () => (
  <MultiTabMenu
    defaultTab="generalInfo"
    tabs={{
      generalInfo: { title: 'buildingInfo', menus: GeneralInfoOptions },
    }}
  />
);
