import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import FormErrors from "components/messages/FormErrors";
import Validate from "utils/FormValidation";
import { Auth } from "aws-amplify";
import { Form, Button, Ref } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'services/auth';
import { AppTracking } from 'global/appTracking';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { PasswordInput } from "components/form-elements/PasswordInput";
import classNames from "classnames";

export const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [cognitoError, setCognitoError] = useState(null);
  const [blankfieldError, setBlankfieldError] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [invalidPasswordError, setInvalidPasswordError] = useState(false);
  const [invalidCurrentPasswordError, setInvalidCurrentPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const { setUser, setIsAuthenticated } = useAuth();

  const intl = useIntl();

  const { theme } = useTheme();

  const submitRef = useRef(null);

  const onOldPassInputChange = event => {
    setOldPassword(event.target.value);
    setPasswordError('');
  };

  const onNewPassInputChange = event => {
    setNewPassword(event.target.value);
    setPasswordError('');
  };

  const onConfirmPassInputChange = event => {
    setConfirmPassword(event.target.value);
    setPasswordError('');
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    setCognitoError(null);
    setBlankfieldError(false);
    setPasswordError('');
    setInvalidCurrentPasswordError(false);
    setInvalidPasswordError(false);
    setPasswordMatchError(false);
    const isValidationError = Validate(event, {
      oldPassword,
      newPassword,
      confirmPassword,
      setCognitoError,
      setBlankfieldError,
      setPasswordError,
      setInvalidPasswordError,
      setInvalidCurrentPasswordError,
      setPasswordMatchError
    });

    if (!isValidationError) {
      // AWS Cognito integration
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(
          user,
          oldPassword,
          newPassword
        );
        AppTracking.authEvent({ action: 'Change Password' });
        Auth.signOut().then(() => {
          setIsAuthenticated(false);
          setUser(null);
          props.history.push("/change-pass-confirmation");
        });
      } catch (error) {
        let err = null;
        !error.message ? err = { "message": error } : err = error;
        setCognitoError(err);
      }
    }
  };

  return (
    <BasicPageLayout intlTitle="changePassword">
      <Form size='large' className={styles.form} onSubmit={handleSubmit}>
        <Text
          element="h1"
          size={32}
          intl="changePassword"
        />

        <div className={styles.subtitle}>
          <Text
            size={16}
            gray
            intl="changePasswordSubtitle"
          />
        </div>

        <FormErrors formerrors={{ cognitoError, passwordMatchError, blankfieldError, invalidPasswordError, invalidCurrentPasswordError }} />

        <PasswordInput
          className={classNames(theme.textColor, passwordError === 'oldPassword' && 'error')}
          fluid
          id="old-password"
          name="old-password"
          label={intl.formatMessage({ id: 'currentPassword' })}
          placeholder={intl.formatMessage({ id: 'currentPassword' })}
          value={oldPassword}
          onChange={onOldPassInputChange}
          submitRef={submitRef}
        />

        <PasswordInput
          className={classNames(theme.textColor, passwordError === 'newPassword' && 'error')}
          fluid
          id="new-password"
          name="new-password"
          label={intl.formatMessage({ id: 'newPassword' })}
          placeholder={intl.formatMessage({ id: 'newPasswordPlaceholder' })}
          value={newPassword}
          onChange={onNewPassInputChange}
          submitRef={submitRef}
        />

        <PasswordInput
          className={classNames(theme.textColor, passwordError === 'confirmPassword' && 'error')}
          fluid
          id="confirm-password"
          name="confirm-password"
          label={intl.formatMessage({ id: 'confirmNewPassword' })}
          placeholder={intl.formatMessage({ id: 'confirmPassword' })}
          value={confirmPassword}
          onChange={onConfirmPassInputChange}
          submitRef={submitRef}
        />

        <div className={styles.buttons}>
          <a href='/forgot-pass'
             onClick={(e) => {
               e.preventDefault();
               props.history.push('/forgot-pass')
             }}
          >
            <FormattedMessage id="forgotPassword" />
          </a>

          <Ref innerRef={submitRef}>
            <Button
              name="change-password"
              type='submit'
              size='large'
            >
              <FormattedMessage id="send" />
            </Button>
          </Ref>
        </div>
      </Form>
    </BasicPageLayout>
  );
};
