import React from 'react';

export const ToolsBigIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='48.325'
      height='45.113'
      viewBox='0 0 48.325 45.113'
      {...props}
    >
      <g
        id='Group_6545'
        data-name='Group 6545'
        transform='translate(-431.025 -246.069)'
      >
        <rect
          id='Rectangle_453'
          data-name='Rectangle 453'
          width='48.325'
          height='4.393'
          transform='translate(431.025 286.789)'
          fill='#3a3a3a'
        />
        <path
          id='tools-wench-2'
          d='M34.883,11.717a.748.748,0,0,0-.621-1.166H31.727a.748.748,0,0,1-.513-.2L28.832,8.1a.748.748,0,0,1,0-1.089l2.381-2.245a.748.748,0,0,1,.513-.2h2.535A.748.748,0,0,0,34.883,3.4a7.562,7.562,0,0,0-.931-1.133A7.482,7.482,0,0,0,21.341,9.122a.748.748,0,0,1-.2.687L7.357,23.591a.748.748,0,0,1-.572.217,5.986,5.986,0,1,0,5.63,5.634.748.748,0,0,1,.218-.57l13.8-13.795a.748.748,0,0,1,.685-.2,7.44,7.44,0,0,0,6.837-2.032,7.55,7.55,0,0,0,.931-1.125ZM8.027,31.365a2.245,2.245,0,1,1,0-3.174A2.245,2.245,0,0,1,8.027,31.365Z'
          transform='translate(439.361 245.992)'
          fill='#3a3a3a'
        />
      </g>
    </svg>
  );
};
