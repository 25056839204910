import React, { useContext, useEffect } from 'react';
import { Text } from 'components/typography/Text';
import { Grid } from 'components/layout/Grid';
import { UserAdministrationModalContext } from 'components/brikks/Office/UserAdministration/components/UserAdministrationModal/index';
import { AddUsersActions } from './components/AddUsersActions';

export const AddUsers = () => {
  const { setSelectedUser } = useContext(
    UserAdministrationModalContext,
  );

  useEffect(() => {
    setSelectedUser(null);
  }, [setSelectedUser]);

  return (
    <Grid
      oneColumn
      noPadding
    >
      <Text
        intl="addUsersDescription"
        size={14}
      />

      <Text
        intl="readManual"
        size={14}
        underline
      />

      <AddUsersActions />
    </Grid>
  ); 
};
