import { daysFormat, formatDate } from 'utils/timeAndDate';

export class LivingInfo {
  constructor(livingInfo) {
    this.apartmentno = livingInfo.apartmentno;
    this.productNumber = livingInfo.objectno;
    this.area = livingInfo.area;
    this.floor = livingInfo.floor;
    this.address = getStreetAddress(livingInfo.fulladdress);
    this.rent = livingInfo.productprice;
    this.agreementName = livingInfo.groupname;
    this.startDate = livingInfo.startdate ? formatDate(new Date(livingInfo.startdate), daysFormat) : '-';
  }
}

function getStreetAddress(fullAddress) {
  const splitAddress = fullAddress.split(',');
  return splitAddress ? splitAddress[0] : fullAddress;
}
