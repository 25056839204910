import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Text } from 'components/typography';
import { TextArea } from 'components/form-elements/textareas/TextArea';
import { MultipleImageUpload } from 'components/form-elements/MultipleImageUpload';
import { Button } from 'components/form-elements/buttons/Button';
import { Form } from 'components/layout/Form';
import { Controller, useForm } from 'react-hook-form';
import { MultiTabContext } from 'components/MultiTabMenu';
import { useUserDetails } from 'services/user/get-details';
import { useLocale } from 'services/user/locale/hook';
import { useSendReport } from 'services/reports/send-report';
// import { ReportIssueContext } from 'components/brikks/Office/ReportIssue/index';
import { ShouldRender } from 'components/ShouldRender';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import styles from './styles.module.scss';

export const SendIssue = () => {
  const [attachedFiles, setAttachedFiles] = useState();
  const { activeMenu } = useContext(MultiTabContext);
  const category = activeMenu.menu;

  const { userDetails } = useUserDetails();
  const { locale } = useLocale();
  // const { setIsModalOpen } = useContext(ReportIssueContext);

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onChange',
  });

  const onAttachImage = useCallback((images) => {
    setAttachedFiles(prevState => ({
      ...prevState,
      images,
    }));
  }, [setAttachedFiles]);

  const { sendReport, isLoading } = useSendReport({
    onSuccess: () => {
      // setIsModalOpen(false);
    },
  });

  useEffect(() => {
    reset();
  }, [category, reset]);

  const onSubmit = (report) => {
    report.attachments = attachedFiles.images;
    report.category = category;
    report.email = userDetails.email;
    report.phoneNumber = userDetails.workNumber;
    report.language = locale;
    sendReport(report);
  };

  return (
    <Form
      key={category}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
    >
      <Text
        size={16}
        uppercase
        bold
        intl="describeFurther"
      />

      <Text
        size={14}
        intl="describeFurtherDescription"
      />

      <ShouldRender condition={category === 'office'}>
        <Controller
          name="company"
          rules={{ required: true }}
          control={control}
          defaultValue={userDetails.organization || ''}
          render={({ field }) => (
            <LabeledInput
              labelIntl="company"
              hasError={errors.company}
              onChange={field.onChange}
              inputRef={field.ref}
            />
          )}
        />
      </ShouldRender>

      <Controller
        name="description"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextArea
            defaultValue=""
            placeholder="describeFurtherDescriptionPlaceholder"
            hasError={errors.description}
            onChange={field.onChange}
            inputRef={field.ref}
            rows={10}
          />
        )}
      />

      <ShouldRender condition={category === 'office'}>
        <Controller
          name="floor"
          control={control}
          render={({ field }) => (
            <LabeledInput
              labelIntl="floor"
              onChange={field.onChange}
              inputRef={field.ref}
            />
          )}
        />
      </ShouldRender>

      <MultipleImageUpload
        maxSize={3}
        onUpload={onAttachImage}
      />

      <ShouldRender condition={category === 'office'}>
        <Controller
          name="confirmationEmail"
          control={control}
          rules={{ pattern: /^\S+@\S+$/i }}
          render={({ field }) => (
            <LabeledInput
              hasError={errors.confirmationEmail}
              labelIntl="confirmationEmail"
              onChange={field.onChange}
              inputRef={field.ref}
            />
          )}
        />
      </ShouldRender>

      <div className={styles.action}>
        <Button
          intl="send"
          loading={isLoading}
        />
      </div>
    </Form>
  );
};
