import React, { useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { FocusableElement } from 'components/FocusableElement';
import { useMultiGrid } from 'services/multi-grid/hook';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { BuildingsIcon } from "assets/icons/BuildingsIcon";

export const MenuItem = ({ multiGrid }) => {
  const { selectedGrid, setMultiGrid } = useMultiGrid();

  const Icon = useMemo(() => {
    switch (multiGrid) {
      case MultiGrid.Home:
        return BuildingsIcon;
      default:
        return BuildingsIcon;
    }
  }, [multiGrid])

  return (
    <FocusableElement
      className={classNames(
        styles.menuItem, {
          [styles.active]: selectedGrid === multiGrid,
        }
      )}
      onClick={() => setMultiGrid(multiGrid)}
    >
      <div className={styles.itemContent}>
        <Icon />

        <Text
          white
          size={14}
          intl={multiGrid}
        />
      </div>
    </FocusableElement>
  );
}
