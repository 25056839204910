import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { useTenantId } from 'services/user/tenant/hook';
import { adminUpdateUserAPI } from './api';

const USERS = 'users';

export function useAdminUpdateUser() {
  const queryClient = useQueryClient();
  const { tenantId } = useTenantId();

  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (user) => adminUpdateUserAPI(user),
    {
      onSuccess: () => {
        openModal({
          modalType: 'PopupModal',
          modalProps: {
            title: 'adminUpdateUserModalTitleSuccess',
            description: 'adminUpdateUserModalDescriptionSuccess',
            showActions: false,
          },
        });
        queryClient.invalidateQueries([USERS, tenantId]);
      },

      onError: (error, user) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'adminUpdateUserModalTitleError',
          description: 'adminUpdateUserModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(user),
        },
      }),
    },
  );

  return {
    adminUpdateUser: mutate,
    isLoading,
    isError,
  };
}
