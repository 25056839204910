import React from 'react';
import { useTenantConfig } from 'global/tenant-config';
import { MenuItem } from './components/MenuItem';

export const MobileMenu = ({ onClick }) => {
  const { tenantConfig } = useTenantConfig();

  return (
    <div onClick={onClick}>
      {tenantConfig.multiGrids.map((multiGrid) => (
        <MenuItem 
          key={multiGrid} 
          multiGrid={multiGrid} 
        />
      ))}
    </div>
  );
};
