import React, { useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Card } from 'components/cards/Card';
import { Logo } from './components/Logo';
import { Menu } from './components/Menu';
import { MultiGrid } from './components/MultiGrid';
// import { useRouteMatch } from 'react-router-dom';
// import { MAIN_PAGE, HOME_PAGE } from 'global/routes';
import { ShouldRender } from 'components/ShouldRender';
import { HamburgerMenu } from 'assets/icons/HamburgerMenu';
import { SideMenu } from 'components/headers/SideMenu';
import { useTenantId } from 'services/user/tenant/hook';
import { MobileMenu } from './components/MobileMenu';

export const AppHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { tenantId } = useTenantId();

  //@TODO
  // const { isExact: isHomePage } = useRouteMatch({ path: '/' });
  const isHomePage = window.location.pathname.includes(tenantId) 
  && !window.location.pathname.includes('onboarding');

  return (
    <header className={classNames(
      styles.header, {
        [styles.fixed]: !isHomePage,
      },
    )}>
      <Card transparent={!isHomePage}>
        <div className={classNames(styles.menu, isHomePage && styles.menuBg)}>
          <ShouldRender condition={isHomePage}>
            <HamburgerMenu
              className={styles.hamburgerMenu}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </ShouldRender>
          <Logo />

          <ShouldRender condition={isHomePage}>       
            <Menu />
          </ShouldRender>
        </div>

        <ShouldRender condition={isHomePage}>
          <MultiGrid />
        </ShouldRender>
      </Card>

      <SideMenu
        shouldShow={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <MobileMenu onClick={() => setIsMenuOpen(false)} />
      </SideMenu>
    </header>
  );
}
