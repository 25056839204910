import React from 'react';
import PageHeader from '../components/headers/PageHeader';
import {Header, Modal, Message, Button, Icon, Label} from "semantic-ui-react";
import { IntlPageTitle } from 'components/intl/IntlPageTitle';

const NotFoundPage = (props) => {

    return (
        <div className='notFoundPage'>
            <IntlPageTitle intlTitle="pageNotFound" />

            <PageHeader/>
            <Modal open={true} dimmer='blurring' className='brikk-modal'>
                <Modal.Header>
                    <Header as='h2'>
                        <Label content='404' icon='warning circle' basic color='red' size='massive' style={{margin: '0.5em 2em'}} />
                        Sidan eller filen hittades inte
                    </Header>
                </Modal.Header>

                <Modal.Content>
                    <Message floating size='massive' warning>
                        <Message.Header>
                            Tyvärr kan sidan eller filen du sökte inte hittas.
                        </Message.Header>
                        Anledningen kan vara att sidan eller filen har flyttats eller inte längre finns.
                        <Button as='a' onClick={() => props.history.push('/')} animated basic color='green' size='huge' style={{margin: '1em 2em'}}>
                            <Button.Content visible>
                                <strong>Gå till startsidan</strong>
                            </Button.Content>
                            <Button.Content hidden>
                                <Icon name='arrow left' />
                            </Button.Content>
                        </Button>
                    </Message>
                </Modal.Content>
            </Modal>
        </div>
    )
};

export default NotFoundPage;
