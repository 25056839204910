import { useState } from "react";
import { createContainer } from "unstated-next";

function useReorderBrikksState() {
  const [shouldReorderBrikks, setShouldReorderBrikks] = useState(false);

  return {
    shouldReorderBrikks,
    setShouldReorderBrikks,
  };
}

const reorderBrikks = createContainer(useReorderBrikksState);

export const ReorderBrikksProvider = reorderBrikks.Provider;
export const useReorderBrikks = reorderBrikks.useContainer;
