import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLocale, updateBrowserLocale, updateLocale } from './actions';
import { createContainer } from "unstated-next";
import { useAuth } from 'services/auth';

export function useLocaleState() {
  const { locale, calledOnce } = useSelector(state => state.locale);

  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!calledOnce) {
      dispatch(getLocale())
    }
  }, [calledOnce, dispatch]);

  return {
    locale,
    setLocale: (locale) => isAuthenticated ? dispatch(updateLocale(locale)) : dispatch(updateBrowserLocale(locale)),
  }
}


const locale = createContainer(useLocaleState);

export const LocaleProvider = locale.Provider;
export const useLocale = locale.useContainer;