import React, { useContext } from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { CloudDownloadIcon } from 'assets/icons/CloudDownloadIcon';
import { EyeIcon } from 'assets/icons/EyeIcon';
import { ExternalLink } from 'components/links/ExternalLink';
import { BaseButton } from 'components/form-elements/buttons/BaseButton';
import { CustomToggle } from 'components/form-elements/toggles/CustomToggle';
import { Text } from 'components/typography/Text';
import { downloadDocument } from 'utils/download';
import { DocumentPreviewContext } from '../../index';
import { ActionButton } from '../ActionButton';
import styles from './styles.module.scss';

export const Actions = ({ download, view, showInDocuments }) => {
  const { url, downloadName } = useContext(DocumentPreviewContext);

  return (
    <>
      <div className={styles.actions}>
        <ShouldRender condition={!!download}>
          <BaseButton
            className={styles.button}
            onClick={() => downloadDocument(downloadName, url)}
          >
            <ActionButton
              intl="download"
            >
              <CloudDownloadIcon />
            </ActionButton>
          </BaseButton>
        </ShouldRender>

        <ShouldRender condition={!!view}>
          <ExternalLink href={url}>
            <ActionButton intl="view">
              <EyeIcon />
            </ActionButton>
          </ExternalLink>
        </ShouldRender>
      </div>

      <ShouldRender condition={!!showInDocuments}>
        <div className={styles.footer}>
          <Text
            size={10}
            intl="showInDocuments"
          />

          <CustomToggle
            checked
            className={styles.checkbox}
          />
        </div>
      </ShouldRender>
    </>
  );
};
