import React, { useState } from 'react';
import styles from './styles.module.scss';
import { BankIDLogin } from './components/BankIDLogin';
import { EmailLogin } from './components/EmailLogin';
import { Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export const LoginForm = () => {
  const [shouldLoginWithBankID] = useState(false);

  return (
    <>
      <Header className={styles.header} as='h1'>
        <FormattedMessage
          id="loginTitle"
          values={{
            span: (...chunks) => (
              <span className={styles.strong}>
                {chunks}
              </span>
            )
          }}
        />
      </Header>

      <div className={styles.loginForm}>
        <div className={styles.form}>
          {shouldLoginWithBankID ? <BankIDLogin /> : <EmailLogin/>}
        </div>
      </div>
    </>
  )
};
