import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Message, Ref } from 'semantic-ui-react';
import styles from '../../styles.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoginWithEmail } from 'services/auth/login-email/hook';
import { ShouldRender } from 'components/ShouldRender';
import classNames from 'classnames';
import { useAuth } from 'services/auth';
import { useRedirect } from 'utils/redirect';
import { Link } from 'react-router-dom';
import { PasswordInput } from "components/form-elements/PasswordInput";
import { setApiUrl, setTenant } from 'global/environment';
import { getUserTenants } from 'utils/auth-token/getUserTenants';

export const PasswordLogin = ({ email, emailError, setEmailError }) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const intl = useIntl();

  const { user, loading, error, onEmailLogin } = useLoginWithEmail();

  const { setUser, setIsAuthenticated, setUserTenants } = useAuth();

  const { onRedirect } = useRedirect('/');

  const submitRef = useRef(null);

  useEffect(() => {
    async function authorizeUser() {
      if (user && !loading) {
        const userTenants = await getUserTenants();
        setUser(user);
        setUserTenants(userTenants);
        if (userTenants?.length === 1) {
          setApiUrl(userTenants[0]);
          setTenant(userTenants[0]);
        }
        setIsAuthenticated(true);  
      }
    }
    authorizeUser().then();
  }, [user, setUser, setIsAuthenticated, onRedirect, setUserTenants, loading]);

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
    setPasswordError('');
  }, [setPassword, setPasswordError]);

  const onLogin = useCallback(() => {
    const passwordError = !password.trim().length;
    const emailError = !email.trim().length;

    if (passwordError && emailError) {
      setPasswordError('fieldRequired');
      setEmailError('fieldsRequired');
    } else if (passwordError) {
      setPasswordError('fieldRequired');
    } else if (emailError) {
      setEmailError('fieldRequired');
    } else {
      onEmailLogin(email, password);
    }
  }, [password, email, setPasswordError, setEmailError, onEmailLogin]);

  return (
    <>
      <PasswordInput
        fluid
        className={classNames(styles.field, passwordError && 'error')}
        id="password"
        name="password"
        label={intl.formatMessage({ id: 'password' })}
        placeholder={intl.formatMessage({ id: 'passwordPlaceholder' })}
        value={password}
        onChange={onChangePassword}
        submitRef={submitRef}
      />

      <div className={styles.actions}>
        <Ref innerRef={submitRef}>
          <Button
            className={styles.login}
            loading={loading}
            disabled={loading}
            onClick={onLogin}
          >
            <FormattedMessage id="login" />
          </Button>
        </Ref>

        <Link to="/forgot-pass">
          <FormattedMessage id="forgotPassword" />
        </Link>
      </div>

      <ShouldRender condition={(!!passwordError || !!error) && !emailError}>
        <Message
          negative
          className={styles.field}
        >
          <FormattedMessage id={passwordError || error} />
        </Message>
      </ShouldRender>
    </>
  )
};
