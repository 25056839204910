import React from "react";
import { Draggable } from "components/drag-and-drop";
import { Brikk } from "components/Brikk";
import { SeeMoreButton } from "components/form-elements/buttons/SeeMoreButton";
import { Image } from "components/media/Image";
import { PackageIcon } from "assets/icons/brikks/PackageIcon";
import brikkPreviewSrc from "components/brikks/Office/Logistics/assets/logistics-brikk-preview.png";

export const Logistics = ({ position }) => {
  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={true}>
          <Brikk.Header icon={<PackageIcon />} heading="logisticsBrikk" />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton disabled={true} brikkNameIntl="logistics" />
          </Brikk.Button>
        </Brikk>
      </Draggable>
    </>
  );
};
