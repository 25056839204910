import React from 'react';

export const NetworkIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23.992'
    height='23.998'
    viewBox='0 0 23.992 23.998'
  >
    <g id='network-user' transform='translate(-0.006 0)'>
      <path
        id='Path_8866'
        data-name='Path 8866'
        d='M13.019,16.5a1,1,0,0,0-1-1H8.1a.247.247,0,0,1-.243-.19A14.932,14.932,0,0,1,7.507,12a15.25,15.25,0,0,1,.254-2.8.253.253,0,0,1,.25-.2c10.873,0,13.555.008,14.28-.018a.936.936,0,0,0,.9-1.338A11.942,11.942,0,0,0,11.947,0,12.238,12.238,0,0,0,.008,11.8,11.973,11.973,0,0,0,11.476,23.987h.044a1,1,0,0,0,.271-1.963.945.945,0,0,1-.482-.37,35.8,35.8,0,0,1-2.542-3.79.25.25,0,0,1,.221-.367h3.031a1,1,0,0,0,1-1ZM5.8,15.2a.249.249,0,0,1-.245.3H2.818a.251.251,0,0,1-.236-.167A9.97,9.97,0,0,1,2.413,9.18.251.251,0,0,1,2.653,9H5.46a.252.252,0,0,1,.247.288A17.89,17.89,0,0,0,5.507,12,17.131,17.131,0,0,0,5.8,15.2ZM11.859,2.27a.251.251,0,0,1,.291,0,10.169,10.169,0,0,1,3.36,4.383A.25.25,0,0,1,15.279,7H8.729a.247.247,0,0,1-.207-.111A.25.25,0,0,1,8.5,6.653,10.064,10.064,0,0,1,11.859,2.27ZM20.44,6.614A.25.25,0,0,1,20.229,7H17.946a.251.251,0,0,1-.238-.171,13.131,13.131,0,0,0-1.776-3.521.25.25,0,0,1,.309-.372,9.9,9.9,0,0,1,4.2,3.678ZM7.74,2.968a.25.25,0,0,1,.311.371A13.12,13.12,0,0,0,6.3,6.827.25.25,0,0,1,6.068,7H3.805a.25.25,0,0,1-.21-.385A10.058,10.058,0,0,1,7.74,2.968ZM8.091,20.7a.25.25,0,0,1-.309.372A9.978,9.978,0,0,1,3.922,17.9a.25.25,0,0,1,.2-.4H6.246a.251.251,0,0,1,.234.163A13.142,13.142,0,0,0,8.091,20.7Z'
        fill='#3a3a3a'
      />
      <path
        id='Path_8867'
        data-name='Path 8867'
        d='M18.769,17.7a3.413,3.413,0,1,0-3.412-3.412A3.413,3.413,0,0,0,18.769,17.7Z'
        fill='#3a3a3a'
      />
      <path
        id='Path_8868'
        data-name='Path 8868'
        d='M13.548,23.445a.5.5,0,0,0,.5.553h9.447a.5.5,0,0,0,.5-.553,5.25,5.25,0,0,0-10.442,0Z'
        fill='#3a3a3a'
      />
    </g>
  </svg>
);
