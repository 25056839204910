import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
// import { OfficeOptions } from "./tabOptions/officeOptions";
import { useTenantConfig } from 'global/tenant-config';
import { GeneralInfoOptions } from './EdenTabOptions/generalInfoOptions';

export const OfficeInfoModalContext = createContext();

export const OfficeInfoModal = () => {
  const [activeMenu, setActiveMenu] = useState(GeneralInfoOptions.FOOD);
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const { tenantConfig } = useTenantConfig();

  const WorkspaceInfo = tenantConfig.officeInfo.components.workspaceInfo;
  const myWorkspaceImgSrc = tenantConfig.assets.myWorkspaceExpended;

  return (
    <OfficeInfoModalContext.Provider value={{ activeMenu, setActiveMenu, activeSubMenu, setActiveSubMenu }}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="myWorkplace" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="myWorkplace"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={myWorkspaceImgSrc}
                alt="My workspace"
              />

              <ModalIntro.Text intl="ourOfficeDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>
 
        <WorkspaceInfo />
      </Grid>
    </OfficeInfoModalContext.Provider>
  );
};
