import { useQuery } from 'react-query';
import { getReportsAPI } from './api';

const REPORTS = 'reports';

export function useReports() {
  const { data, isLoading, isError } = useQuery(REPORTS, getReportsAPI);

  return {
    reports: data,
    isLoading,
    isError,
  };
}
