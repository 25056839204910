import React from 'react';
import { useAuth } from 'services/auth';
import { Redirect, Route } from 'react-router-dom';
import { HOME_PAGE, LOGIN_PAGE } from 'global/routes';
import { PageLoader } from 'components/loaders/PageLoader';
import { useTenantId } from 'services/user/tenant/hook';
import { setApiUrl, setTenant } from 'global/environment';

export const PrivateRoute = ({ exact, path, component }) => {
  const { isAuthenticated, authLoading, userTenants } = useAuth();
  const { tenantId } = useTenantId();

  if (authLoading) {
    return <PageLoader />;
  }

  if (tenantId) {
    if (userTenants && !userTenants.includes(tenantId)) {
      setApiUrl(userTenants[0]);
      setTenant(userTenants[0]);
      return <Redirect to={`/${userTenants[0]}`} />;
    }
  }

  if (path === HOME_PAGE && userTenants?.length === 1) {
    setApiUrl(userTenants[0]);
    setTenant(userTenants[0]);
    return <Redirect to={`/${userTenants[0]}`} />;
  }
  
  return isAuthenticated ? (
    <Route 
      exact={exact} 
      path={path} 
      component={component} 
    />
  ) : (
    <Redirect to={LOGIN_PAGE} />
  );
};
