import { AWS_API_URL } from 'global/environment';
import { authorize, getRequest } from 'utils/http';
import { mapDataForClient } from './mapData';

export async function getRSSFeedsAPI() {
  const url = `${AWS_API_URL}/rss-feeds`;
  const data = await getRequest(url, await authorize());
  const rssFeeds = data.map(feed => mapDataForClient(feed));
  return rssFeeds;
}
