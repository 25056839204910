import React from 'react';
import { StoreProvider, store } from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Helmet } from 'react-helmet';
import { TranslationsProvider } from 'components/intl/IntlProvider';
import { PageTracking } from 'components/tracking';
import { ScrollToTop } from 'components/ScrollToTop';
import { realEstateConfig } from 'global/tenant-config';
import { ServiceProvider } from 'services';

export const GlobalProvider = ({ children }) => (
  <Router>
    <ScrollToTop />
    
    <Helmet>
      <link
        rel="icon"
        type="image/png"
        href={realEstateConfig.assets.favicon}
        sizes="16x16"
      />
    </Helmet>

    <ServiceProvider>
      <Provider store={store}>
        <StoreProvider>
          <TranslationsProvider>
            {children}
          </TranslationsProvider>
        </StoreProvider>
      </Provider>

      <ReactQueryDevtools />
    </ServiceProvider>
    <PageTracking />
  </Router>
);
