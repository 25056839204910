import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import userAdmin from 'assets/media/user-admin-preview.png';
import styles from './styles.module.scss';

export const UserAdministrationPreview = () => (
  <CircularImage
    src={userAdmin}
    alt="User admin preview"
    className={styles.img}
  />
);
