import React from 'react';
import * as xlsx from 'xlsx';
import { UploadButton } from 'components/form-elements/UploadButton';
import { TYPE_EXCEL } from 'utils/files';
import styles from './styles.module.scss';

export const UploadExcel = ({ loading = false, onClick }) => {
  const fileInput = React.useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const { result } = e.target;
        const workbook = xlsx.read(result, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = xlsx.utils.sheet_to_json(worksheet);
        if (data.length > 0) {
          onClick(data);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  return (
    <div className={styles.buttonGroup}>
      <UploadButton
        onClick={handleClick}
        loading={loading}
        fillBlue
      />

      <input
        type="file"
        accept={TYPE_EXCEL}
        ref={fileInput}
        onChange={handleChange}
        onClick={(e) => {
          e.target.value = null;
        }}
        className={styles.input}
      />
    </div>
  );
};
