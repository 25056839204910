import React from "react";
import { Draggable } from "components/drag-and-drop";
import { Brikk } from "components/Brikk";
import { SeeMoreButton } from "components/form-elements/buttons/SeeMoreButton";
import { Image } from "components/media/Image";
import { SunFaceIcon } from "assets/icons/brikks/SunFaceIcon";
import brikkPreviewSrc from "./assets/recreation-brikk-preview.png";

export const Recreation = ({ position }) => {
  return (
    <>
      <Draggable position={position}>
        <Brikk disabled={true}>
          <Brikk.Header icon={<SunFaceIcon />} heading="recreationBrikk" />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton disabled={true} brikkNameIntl="recreation" />
          </Brikk.Button>
        </Brikk>
      </Draggable>
    </>
  );
};
