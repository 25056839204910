export const GetLivingInfoActions = {
  GET_LIVING_INFO: '@livingInfo/get',
  GET_LIVING_INFO_SUCCESS: '@livingInfo/get/success',
  GET_LIVING_INFO_FAIL: '@livingInfo/get/fail',
};

export function getLivingInfo() {
  return {
    type: GetLivingInfoActions.GET_LIVING_INFO,
  }
}

export function getLivingInfoSuccess(livingInfo) {
  return {
    type: GetLivingInfoActions.GET_LIVING_INFO_SUCCESS,
    payload: livingInfo,
  }
}

export function getLivingInfoFail(error) {
  return {
    type: GetLivingInfoActions.GET_LIVING_INFO_FAIL,
    payload: error,
  }
}
