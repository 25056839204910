import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { useTenantConfig } from 'global/tenant-config';
import styles from './styles.module.scss';
 
export const MyWorkspacePreview = () => {
  const { tenantConfig } = useTenantConfig();
  const myWorkspaceImgSrc = tenantConfig.assets.myWorkspaceImg;

  return (
    <div>
      <CircularImage
        src={myWorkspaceImgSrc}
        alt="My workspace image"
      />

      <div className={styles.textLine}>
        <FormattedMessage id="kungsledenOfficeName" />
      </div>

      <div className={styles.textLine}>
        <FormattedMessage id="kungsledenOfficeAddress" />
      </div>
    </div>
  );
};
