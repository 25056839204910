import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import goByBikeSrc from 'assets/media/eden/my-workspace/go-by-bike.jpeg';
import { LinkModal } from 'components/modals/LinkModal';
import { TextButton } from 'components/form-elements/buttons/TextButton';
import { ResourceBookingModal } from 'components/brikks/Office/ResourceBooking/components/ResourceBookingModal';
import styles from '../styles.module.scss';

export const GoByBike = () => (
  <>
    <SemanticImage
      src={goByBikeSrc}
      alt="Go by bike"
      className={styles.image}
    />

    <Text size={16}>
      <FormattedMessage
        id="goByBikeDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
          linkmodal: (...chunks) => (
            <LinkModal
              buttonTriggerModal={TextButton}
              buttonText={chunks}
              brikkModal={ResourceBookingModal}
              modalHeading="resourceBooking"
            />
          ),
        }}
      />
    </Text>
  </>
);
