import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { NetworkIcon } from 'assets/icons/brikks/Network';
import { NetworkPreview } from './components/NetworkPreview';
import { BrikkModal } from 'components/modals/BrikkModal';
import { NetworkModal } from './components/NetworkModal';

export const Network = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Draggable position={position}>
        <Brikk>
          <Brikk.Header
            icon={<NetworkIcon width='17' height='15' color='#2185d0' />}
            heading='networkBrikk'
          />

          <Brikk.Content>
            <NetworkPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              brikkNameIntl='network'
              onClick={() => setIsModalOpen(true)}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading='bookSpace'
        onClose={() => setIsModalOpen(false)}
      >
        <NetworkModal />
      </BrikkModal>
    </>
  );
};
