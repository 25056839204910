import { Tenants } from 'global/tenant-config/tenants';

export const HOME_PAGE = '/';
export const MAIN_PAGE = Object.keys(Tenants).map(key => `/${Tenants[key].tenantId}`);
export const WELCOME_SCREEN = '/welcome';
export const LOGIN_PAGE = '/login';
export const CHANGE_PASS_PAGE = '/change-pass';
export const CHANGE_PASS_CONFIRMATION_PAGE = '/change-pass-confirmation';
export const CREATE_PASS_PAGE = '/create-pass';
export const FORGOT_PASS_PAGE = '/forgot-pass';
export const FORGOT_PASS_VERIFY_PAGE = '/forgot-pass-verify';
export const REGISTER_PAGE = '/register';
export const ACTIVATE_ACCOUNT_PAGE = '/activate-account';

export const ONBOARDING_PAGE = '/:id/onboarding';
export const ONBOARDING_WELCOME = `${ONBOARDING_PAGE}/welcome`;
