import React from 'react';
import styles from './styles.module.scss';
import { Dropdown, Image, Menu } from 'semantic-ui-react';
import classNames from 'classnames';
import userSrc from '../../../assets/media/user.png';
import { useUserAvatar } from 'services/user/avatar/hook';
import { DropdownMenu } from './components/DropdownMenu';
import Popup from 'reactjs-popup';
import { Text } from 'components/typography/Text';
import { useUserDetails } from "services/user/get-details";

export const UserProfile = () => {
  const { userDetails } = useUserDetails();

  const { avatar } = useUserAvatar();

  return (
    <Menu.Menu position='right' className={styles.menu}>
      <div className={styles.userMenu}>
        <Popup
          trigger={(
            <div className={styles.dropdown}>
              <Text size={14} className={classNames(styles.userText, styles.menuText)}>
                {userDetails.givenName} {userDetails.familyName}
                <i className="dropdown icon" />
              </Text>

              <Image
                id="user-avatar"
                alt="user-avatar"
                className={styles.avatar}
                src={avatar || userSrc}
                size='tiny'
                circular
                onError={() => document.getElementById('user-avatar').src = userSrc}
              />
            </div>
          )}
          pointing="top right"
          icon={null}
        >
          <Dropdown.Menu>
            <DropdownMenu />
          </Dropdown.Menu>
        </Popup>
      </div>
    </Menu.Menu>
  );
};
