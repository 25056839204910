import React from 'react';
import styles from './styles.module.scss';
import { FocusableElement } from 'components/FocusableElement';
import classNames from 'classnames';

export const SubItem = ({ children, disabled, onClick}) => {

  const handleOnClick = () => {
    onClick();
  }

  return (
    <FocusableElement
      className={classNames(styles.subItem, disabled && styles.disabled)}
      onClick={!disabled ? () => handleOnClick() : undefined}
    >
      {children}
    </FocusableElement>
  )
}
