import React from 'react';
import { Text as GlobalText } from 'components/typography';
import { FormattedMessage } from 'react-intl';
import { LinkModal } from 'components/modals/LinkModal';
import { ReportIssueModal } from 'components/brikks/Office/ReportIssue/components/ReportIssueModal';
import { TextButton } from 'components/form-elements/buttons/TextButton';

export const Text = ({ intl }) => (
  <GlobalText size={18}>
    <FormattedMessage
      id={intl}
      values={{
        email: (...chunks) => (
          <a
            href={`mailto:${chunks}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {chunks}
          </a>
        ),
        linkmodal: (...chunks) => (
          <LinkModal
            buttonTriggerModal={TextButton}
            buttonText={chunks}
            brikkModal={ReportIssueModal}
            modalHeading="reportIssue"
          />
        ),
      }}
    />
  </GlobalText>
);
