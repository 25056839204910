import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant';
import { getRSSFeedsAPI } from './api';

const RSS_FEEDS = 'rssFeeds';

export function useRSSFeeds() {
  const { tenantId } = useTenantId();
  const { data, isLoading, isError, isFetching } = useQuery([RSS_FEEDS, tenantId], getRSSFeedsAPI);

  return {
    rssFeeds: data,
    isLoading,
    isError,
    isFetching,
  };
}
