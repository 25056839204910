import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { useTenantId } from 'services/user/tenant';
import { updateUserDetailsAPI } from './api';

const USER_DETAILS = 'userDetails';

export function useUpdateUserDetails() {
  const queryClient = useQueryClient();
  const { tenantId } = useTenantId();

  const { openModal } = useModal();

  const { mutate, isLoading, isError } = useMutation(
    (userDetails) => updateUserDetailsAPI(userDetails),
    {
      onSuccess: (data, userDetails) => {
        queryClient.setQueryData([USER_DETAILS, tenantId], userDetails);
      },

      onError: (error, userDetails) => openModal({
        modalType: 'PopupModal',
        modalProps: {
          title: 'updateDetailsModalTitleError',
          description: 'updateDetailsModalDescriptionError',
          showActions: true,
          onRetry: () => mutate(userDetails),
        },
      }),
    },
  );

  return {
    updateUserDetails: mutate,
    isLoading,
    isError,
  };
}
