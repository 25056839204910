import React, { useContext } from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import { TicketContext } from 'components/brikks/Office/Status/components/StatusModal/index';
import { UploadedImage } from 'components/form-elements/MultipleImageUpload/components/UploadedImage';
import { useReportFiles } from 'services/reports/get-report-files';
import styles from './styles.module.scss';
import Loader from "components/loaders/PageLoader";
import { useQueryClient } from 'react-query'

export const Documentation = () => {
  const { selectedTicket } = useContext(TicketContext);
  const { reportFiles, isLoading } = useReportFiles(selectedTicket);

  const queryClient = useQueryClient();

  return (
    <ShouldRender condition={!!selectedTicket}>
      <Text
        size={18}
        bold
      >
        Re: {selectedTicket?.name}
      </Text>

      <Text
        size={14}
        className={styles.description}
      >
        {selectedTicket?.description}
      </Text>

      <ShouldRender condition={isLoading}>
        <Loader />
      </ShouldRender>

      <ShouldRender condition={!!reportFiles && !isLoading}>
        {selectedTicket?.files.map((image) => (
          <UploadedImage
            key={image.id}
            name={image.filename}
            size={image.fileSize}
            content={`data:image/jpeg;base64,${queryClient.getQueryData(['reportFile', image.id])}`}
            remove={false}
          />
        ))}
      </ShouldRender>
    </ShouldRender>
  );
};
