import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import theBuildingSrc from 'assets/media/eden/my-workspace/the-building.jpeg';
import styles from '../styles.module.scss';

export const TheBuilding = ({ title }) => (
  <>
    <SemanticImage
      src={theBuildingSrc}
      alt="The Building"
      className={styles.image}
    />{title}

    <Text size={16}>
      <FormattedMessage
        id="theBuildingDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    </Text>
  </>
);
