import { useQuery } from 'react-query';
import { useTenantId } from 'services/user/tenant/hook';
import { getUsersTemplateAPI } from './api';

const USERS_TEMPLATE = 'usersTemplate';

export function useUsersTemplate() {
  const { tenantId } = useTenantId();

  const { data, isLoading, isError } = useQuery(
    [USERS_TEMPLATE, tenantId],
    () => getUsersTemplateAPI(),
  );

  return {
    usersTemplate: data,
    isLoading,
    isError,
  };
}
