import greenBg from 'assets/media/green-bg.png';
import edenBlueBg from 'assets/media/eden/backgrounds/blue-bg.svg';
import edenYellowBg from 'assets/media/eden/backgrounds/yellow-bg.svg';
import edenOrangeBg from 'assets/media/eden/backgrounds/orange-bg.svg';
import edenGreenBg from 'assets/media/eden/backgrounds/green-bg.svg';
import wg1Bg from 'assets/media/wg1/background.png';
import consulenceBg from 'assets/media/consulence/background.png';
import viaeuropaBg from 'assets/media/viaeuropa/background.png';
import brikksBg from 'assets/media/brikks/background.png';

import { MultiGrid } from 'services/multi-grid/multiGrid';

export const GREEN_BG = greenBg;
export const EDEN_BLUE_BG = edenBlueBg;
export const EDEN_YELLOW_BG = edenYellowBg;
export const EDEN_ORANGE_BG = edenOrangeBg;
export const EDEN_GREEN_BG = edenGreenBg;
export const WG1_BG = wg1Bg;
export const CONSULENCE_BG = consulenceBg;
export const VIAEUROPA_BG = viaeuropaBg;
export const BRIKKS_BG = brikksBg;

export const defaultBrikksOrder = {
  [MultiGrid.Office]: [],
};
