import React from 'react';
import styles from './styles.module.scss';
import { useSideMenu } from 'store/side-menu';   
import { Text } from 'components/typography/Text';
import { FocusableElement } from '../../../../../../FocusableElement';
import { CogIcon } from "assets/icons/CogIcon";

export const Settings = () => {
  const { setIsSideMenuOpen } = useSideMenu();

  return (
    <FocusableElement
      className={styles.settings}
      onClick={() => setIsSideMenuOpen(true)}
    >
      <div className={styles.text}>
        <Text size={14} intl="settings" />
      </div>

      <CogIcon />
    </FocusableElement>
  )
};
