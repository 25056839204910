import React from 'react';
import classNames from 'classnames';
import { Tick } from 'assets/icons/Tick';
import styles from './styles.module.scss';

export const CustomToggle = ({ checked, className }) => {
  return (
    <label className={classNames(styles.switch, className)}>
      <input
        type='checkbox'
        checked={checked}
        readOnly
        className={styles.checkbox}
      />
      <span className={styles.toggleThumb}>
        <Tick />
      </span>
    </label>
  );
};
