import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';

export const MainOverlay = ({ intl }) => (
  <div className={styles.overlay}>
    <Text
      className={styles.overlayText}
      fontWeight={400}
      element="h1"
      size={32}
      white
      intl={intl}
    />
  </div>
);
