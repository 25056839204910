import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { MyHomeIcon } from 'assets/icons/brikks/MyHomeIcon';
import { BrikkModal } from 'components/modals/BrikkModal';
import { OfficeInfoModal } from './components/OfficeInfoModal';
import { MyWorkspacePreview } from './components/MyWorkspacePreview';

export const MyWorkspace = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  return (
    <>
      <Draggable position={position}>
        <Brikk>
          <Brikk.Header
            icon={<MyHomeIcon />}
            heading="myWorkspaceBrikk"
          />

          <Brikk.Content>
            <MyWorkspacePreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="myWorkspaceInfo"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="myWorkspaceInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <OfficeInfoModal />
      </BrikkModal>
    </>
  );
};
