export const ADMIN = 'admin';
export const USER = 'user';

export const ROLES = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'user',
    label: 'User',
  },
];
