import React from 'react';
import styles from './styles.module.scss';

export const Wg1Icon = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width="85" height="37" viewBox="0 0 85 37">
        <text 
            id="WG1" 
            transform="translate(0 28)"  
            fontSize="37" 
            fontFamily="Helvetica"
            className={styles.text}
        >
            <tspan 
                x="0" 
                y="0"
            >
                WG1
            </tspan>
         </text>
</svg>
);
}
