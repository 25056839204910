import React from "react";

export const UsersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.657"
      viewBox="0 0 20 20.657"
    >
      <g
        transform="translate(0 0.002)"
      >
        <path
          d="M11.811,10.009a.18.18,0,0,1-.012-.336,2.311,2.311,0,1,0-1.809,0,.18.18,0,0,1-.012.336A2.848,2.848,0,0,0,8.05,12.7v2.133a.356.356,0,0,0,.355.356H9.04a.177.177,0,0,1,.177.163l.256,3.066a.355.355,0,0,0,.354.325H11.96a.355.355,0,0,0,.354-.325l.256-3.066a.177.177,0,0,1,.177-.163h.635a.356.356,0,0,0,.355-.356V12.7A2.848,2.848,0,0,0,11.811,10.009Z"
          transform="translate(-2.254 -1.466)"
        />

        <path
          d="M3.334,4.057a.18.18,0,0,1-.016-.332,1.956,1.956,0,1,0-1.656,0,.18.18,0,0,1-.015.332A2.493,2.493,0,0,0,0,6.4V7.466a.36.36,0,0,0,.356.356h.59a.177.177,0,0,1,.176.158l.3,2.727a.356.356,0,0,0,.354.316H3.2a.356.356,0,0,0,.354-.316l.3-2.727a.177.177,0,0,1,.176-.158h.589a.356.356,0,0,0,.356-.356V6.4A2.491,2.491,0,0,0,3.334,4.057Z"
          transform="translate(0 0)"
        />

        <path
          d="M20.421,4.057a.18.18,0,0,1-.015-.332,1.956,1.956,0,1,0-1.656,0,.18.18,0,0,1,.1.171.178.178,0,0,1-.118.161A2.492,2.492,0,0,0,17.09,6.4V7.466a.36.36,0,0,0,.356.356h.588a.177.177,0,0,1,.177.158l.3,2.727a.356.356,0,0,0,.354.316h1.422a.356.356,0,0,0,.354-.316l.3-2.727a.177.177,0,0,1,.176-.158h.589a.356.356,0,0,0,.355-.356V6.4A2.491,2.491,0,0,0,20.421,4.057Z"
          transform="translate(-4.785 0)"
        />

        <path
          d="M11.811,10.009a.18.18,0,0,1-.012-.336,2.311,2.311,0,1,0-1.809,0,.18.18,0,0,1-.012.336A2.848,2.848,0,0,0,8.05,12.7v2.133a.356.356,0,0,0,.355.356H9.04a.177.177,0,0,1,.177.163l.256,3.066a.355.355,0,0,0,.354.325H11.96a.355.355,0,0,0,.354-.325l.256-3.066a.177.177,0,0,1,.177-.163h.635a.356.356,0,0,0,.355-.356V12.7A2.848,2.848,0,0,0,11.811,10.009Z"
          transform="translate(-2.254 -1.466)"
        />

        <path
          d="M3.334,4.057a.18.18,0,0,1-.016-.332,1.956,1.956,0,1,0-1.656,0,.18.18,0,0,1-.015.332A2.493,2.493,0,0,0,0,6.4V7.466a.36.36,0,0,0,.356.356h.59a.177.177,0,0,1,.176.158l.3,2.727a.356.356,0,0,0,.354.316H3.2a.356.356,0,0,0,.354-.316l.3-2.727a.177.177,0,0,1,.176-.158h.589a.356.356,0,0,0,.356-.356V6.4A2.491,2.491,0,0,0,3.334,4.057Z"
          transform="translate(0 0)"
        />

        <path
          d="M20.421,4.057a.18.18,0,0,1-.015-.332,1.956,1.956,0,1,0-1.656,0,.18.18,0,0,1,.1.171.178.178,0,0,1-.118.161A2.492,2.492,0,0,0,17.09,6.4V7.466a.36.36,0,0,0,.356.356h.588a.177.177,0,0,1,.177.158l.3,2.727a.356.356,0,0,0,.354.316h1.422a.356.356,0,0,0,.354-.316l.3-2.727a.177.177,0,0,1,.176-.158h.589a.356.356,0,0,0,.355-.356V6.4A2.491,2.491,0,0,0,20.421,4.057Z"
          transform="translate(-4.785 0)"
        />
      </g>
    </svg>
  );
};
