import React, { createContext, useState } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import serviceReportSrc from 'assets/media/service-report.png';
import { ReportIssueInfo } from './components/ReportIssueInfo';

export const ReportIssueModalContext = createContext();

export const ReportIssueModal = ({ selectedItem }) => {
  const [activeMenu, setActiveMenu] = useState(selectedItem || null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  return (
    <ReportIssueModalContext.Provider
      value={{ activeMenu, setActiveMenu, activeSubMenu, setActiveSubMenu }}
    >
      <Grid brikkLayout>
        <IntlPageTitle intlTitle='serviceReport' />

        <Grid.Column>
          <Brikk.Heading main heading='reportIssue'>
            <ModalIntro>
              <ModalIntro.Image src={serviceReportSrc} alt='Service report' />

              <ModalIntro.Text intl='serviceReportDescription' />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <ReportIssueInfo activeMenu={activeMenu} />
      </Grid>
    </ReportIssueModalContext.Provider>
  );
};
