import { getRequest, postRequest } from 'utils/http';
import {
  AWS_REGION,
  BID_AGGREGATOR_BASE_URL, BID_AGGREGATOR_POLICY, BID_AGGREGATOR_PROVIDER,
  CLIENT_ID, TENANT_ID,
} from 'global/environment';
import { options } from 'global/bankID';
import CryptoJS from 'crypto-js';
import AWS from 'aws-sdk';
import { getUsernameFromPersonalNumber } from './getUsernameFromPersonalNumber';

export async function checkAccountState(personalNumber) {
  const url = `${BID_AGGREGATOR_BASE_URL}/users/resolvable/${getUsernameFromPersonalNumber(personalNumber)}`;
  return getRequest(url, options);
}

export async function initiateBankIDLoginAPI(personalNumber) {
  const authURL = `${BID_AGGREGATOR_BASE_URL}/bid/auth`;

  let payload = {
    personalNumber: personalNumber,
    requirement: {
      certificatePolicies: [BID_AGGREGATOR_POLICY]
    }
  };

  return postRequest(authURL, payload, options);
}

export async function loginWithBankIDAPI(initResponse, personalNumber) {
  try {
    const responseAsStr = CryptoJS.enc.Utf8.parse(JSON.stringify(initResponse));
    const responseAsBase64 = CryptoJS.enc.Base64.stringify(responseAsStr);

    const cisp = new AWS.CognitoIdentityServiceProvider({
      region: AWS_REGION,
    });

    const username = getUsernameFromPersonalNumber(personalNumber);

    let authArgs = {
      AuthFlow: "CUSTOM_AUTH",
      ClientId: CLIENT_ID,
      AuthParameters: {
        USERNAME: username
      }
    };

    const rv = await cisp.initiateAuth(authArgs).promise();

    if ("ChallengeName" in rv && rv.ChallengeName === "PERSONAL_NUMBER") {
      let respondArgs = {
        ChallengeName: "CUSTOM_CHALLENGE",
        ClientId: CLIENT_ID,
        Session: rv.Session,
        ChallengeResponses: {
          ANSWER: personalNumber,
          USERNAME: rv.ChallengeParameters.USERNAME
        },
        ClientMetadata: {
          "bid:response": responseAsBase64,
          "bid:rp-name": BID_AGGREGATOR_PROVIDER,
          "bre:tenant-id": TENANT_ID,
        }
      };

      return cisp.respondToAuthChallenge(respondArgs).promise();
    }

    return Promise.reject('genericErrorMessage');
  } catch (e) {
    return Promise.reject('genericErrorMessage');
  }
}
