// eslint-disable-next-line max-len
import { Manuals } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/components/HQWorkspaceInfo/components/KGLOfficeTab/Manuals';

export const OfficeOptions = [
  {
    menu: 'yourOffice',
    submenus: [
      { title: 'officeFloorPlan', content: null },
      { title: 'zonesNames', content: null },
    ],
  },
  {
    menu: 'comfortRulesInOffice',
    submenus: [],
  },
  {
    menu: 'howToUseOffice',
    submenus: [
      { title: 'AlarmSytemWorks', content: null },
      { title: 'controlMusic', content: null },
      { title: 'AVequipmentWork', content: null },
    ],
  },
  {
    menu: 'manuals',
    content: Manuals,
    submenus: [],
  },
];
