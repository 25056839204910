import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import restaurantSrc from 'assets/media/eden/my-workspace/restaurant.jpeg';
import styles from '../styles.module.scss';

export const Restaurant = () => (
  <>
    <SemanticImage
      src={restaurantSrc}
      alt="Restaurant"
      className={styles.image}
    />

    <Text size={16}>
      <FormattedMessage
        id="restaurantDescription"
        values={{
          bold: (...chunks) => (
            <Text
              size={16}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    </Text>
  </>
);
