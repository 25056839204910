// eslint-disable-next-line max-len
import { Restaurant } from 'components/brikks/Office/MyWorkspace/components/OfficeInfoModal/components/HQWorkspaceInfo/components/KGLGeneralInfoTab/Restaurant';

export const GeneralInfoOptions = [
  {
    menu: 'aboutTheBuilding',
    submenus: [
      { title: 'kglVision', content: null },
      { title: 'firstSymbioticOfficeKGL', content: null },
      { title: 'neuroDesign', content: null },
      { title: 'ourWorkingApproach', content: null },
    ],
  },
  {
    menu: 'areaMapsAndInfo',
    submenus: [
      { title: 'foodRestaurantCafe', content: Restaurant },
      { title: 'recreationGyms', content: null },
      { title: 'howToGetHere', content: null },
      { title: 'parkingSpaces', content: null },
    ],
  },
];
