import { SendIssue } from './components/SendIssue';

export const ReportIssueMenus = [{
  menu: 'office',
  content: SendIssue,
  submenus: [],
  subIntl: 'officeContent',
},
{
  menu: 'openSpace',
  content: SendIssue,
  submenus: [],
  subIntl: 'openSpaceContent',
},
{
  menu: 'conferenceRoom',
  content: SendIssue,
  submenus: [],
  subIntl: 'conferenceRoomContent',
},
];
