import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Item } from './Item';
 
export const Manuals = () => (
  <Grid
    twoEqualColumns
    style={{ 
      gridGap: 15, 
      paddingLeft: 5, 
      paddingRight: 5, 
      paddingBottom: 5, 
      paddingTop: 0, 
    }}
  >
    <Item
      intl="anvandarguideMobilapp"
      name="Användarguide mobilapp.pdf"
    />
    <Item
      intl="entryfyApp"
      name="Entryfy app.pdf"
    />
    <Item
      intl="kortguide"
      name="Kortguide.pdf"
    />
  </Grid>
);
