import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NetworkIcon } from 'assets/icons/brikks/Network';
import { CustomToggle } from 'components/form-elements/toggles/CustomToggle';
import { Text } from 'components/typography';
import styles from './styles.module.scss';

export const NetworkPreview = () => (
  <div>
    <div className={styles.networkIconStyle}>
      <NetworkIcon
        width="22"
        height="21"
      />
      <Text
        bold
        xxl1
      >
        <FormattedMessage id="wifi" />
      </Text>
    </div>
    <div className={styles.info}>
      <div>
        <FormattedMessage id="userId" />:{' '}
        <span className={styles.infoItem}>jasmin.persson@gnail.com</span>
      </div>
      <div>
        <FormattedMessage id="networkPassword" />:{' '}
        <span className={styles.infoItem}>*********</span>
      </div>
    </div>
    <div className={styles.password}>
      <FormattedMessage id="showPassword" />
      <CustomToggle checked={false} />
    </div>
  </div>
);
