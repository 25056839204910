import React from 'react';
import { Text } from 'components/typography/Text';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import KungsledenSrc from 'assets/media/kungsleden.png';
import styles from './styles.module.scss';

export const TicketItem = ({ ticket, onClick }) => {
  
  const isTicketActive = ticket.statusName !== 'Kvitterad';

  return (
    <div
      className={styles.ticket}
      onClick={onClick}
    >
      <CircularImage
        className={styles.logo}
        alt="Logo"
        src={KungsledenSrc}
      />

      <div className={styles.text}>
        <Text
          size={16}
          bold
        >
          Kungsleden
        </Text>

        <Text
          size={16}
          bold
        >
          Re: {ticket.name}
        </Text>
          
        {
            isTicketActive
            && (
            <Text
              size={14}
            >
              Service Ticket: {ticket.orderNumber}
            </Text>
            )
          }

        <Text
          size={14}
        >
          Status: {ticket.statusName}
        </Text>
      </div>
    </div>
  );
};
