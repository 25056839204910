import React from "react";
import styles from "./styles.module.scss";

export const Tick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 24 24"
      className={styles.tick}
    >
      <path d="M10 15.586L6.707 12.293 5.293 13.707 10 18.414 19.707 8.707 18.293 7.293z"></path>
    </svg>
  );
};
