import { combineReducers } from 'redux';
import { userAvatarReducer } from 'services/user/avatar/reducer';
import { bankIDLoginReducer } from 'services/auth/bank-id-login/reducer';
import { confirmEmailReducer } from 'services/auth/confirm-email/reducer';
import { loginWithEmailReducer } from 'services/auth/login-email/reducer';
import { initPassResetReducer } from 'services/auth/init-pass-reset/reducer';
import { resetPassReducer } from 'services/auth/reset-pass/reducer';
import { floorPlanReducer } from 'services/living-info/floor-plan/reducer';
import { livingInfoReducer } from 'services/living-info/living-info/reducer';
import { localeReducer } from 'services/user/locale/reducer';
import { bankIDSigningReducer } from 'services/auth/bank-id-signing/reducer';
import { bankIDOrderStatusReducer } from 'services/auth/bank-id-order-status/reducer';
import { multiGridReducer } from 'services/multi-grid/reducer';

export const rootReducer = combineReducers({
  userAvatar: userAvatarReducer,
  bankIDLogin: bankIDLoginReducer,
  bankIDSigning: bankIDSigningReducer,
  confirmEmail: confirmEmailReducer,
  loginWithEmail: loginWithEmailReducer,
  initPassReset: initPassResetReducer,
  resetPass: resetPassReducer,
  floorPlan: floorPlanReducer,
  livingInfo: livingInfoReducer,
  locale: localeReducer,
  bankIDOrderStatus: bankIDOrderStatusReducer,
  multiGrid: multiGridReducer,
});
