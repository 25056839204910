import { takeLatest, call, put } from 'redux-saga/effects';
import { FloorPlanActions, getFloorPlanFail, getFloorPlanSuccess } from './actions';
import { getApartmentDrawingAPI } from './api';
import { AppTracking } from 'global/appTracking';

function* getFloorPlan() {
  try {
    let { data } = yield call(getApartmentDrawingAPI);

    if (data.type === 'buffer') {
      data.link = 'data:image/jpeg;base64, ' + data.link;
    }

    yield put(getFloorPlanSuccess(data));
  } catch (e) {
    yield put(getFloorPlanFail(e.message));
  }
}

function downloadFloorPlan({ payload }) {
  const downloadLink = document.createElement('a');
  const fileName = 'floorPlan.jpeg';

  downloadLink.href = payload;
  downloadLink.download = fileName;
  downloadLink.click();

  AppTracking.downloadEvent({ action: 'Download Floor Plan' })
}

export const floorPlanEffects = [
  takeLatest(FloorPlanActions.GET_FLOOR_PLAN, getFloorPlan),
  takeLatest(FloorPlanActions.DOWNLOAD_FLOOR_PLAN, downloadFloorPlan),
];
