import React from 'react';
import { Form as FormLayout } from 'semantic-ui-react';

export const Form = ({ children, className, onSubmit }) => (
  <FormLayout
    as="form"
    className={className}
    onSubmit={e => {
      e.preventDefault();
      onSubmit();
    }}
  >
    {children}
  </FormLayout>
);
