import React from 'react';
import { Text } from 'components/typography/Text';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Button = ({ intl, size, className }) => (
  <div className={classNames(styles.button, className)}>
    <Text
      dark
      bold
      size={size}
      intl={intl}
    />
  </div>
);
