import React from 'react';
import { useAssets } from 'services/assets/get-assets';
import { PDFPreview } from 'components/media/PDFPreview';

export const Item = ({ intl, name }) => {

  const { assets } = useAssets();
  const url = assets?.manuals?.find(asset => asset.name === name)?.url;

  return (
    <PDFPreview 
      key={name}
      name={intl}
      downloadName={name}
      url={url}
      showInDocuments
    />  
  );
};
