export const FloorPlanActions = {
  GET_FLOOR_PLAN: '@floorPlan/get',
  GET_FLOOR_PLAN_SUCCESS: '@floorPlan/get/success',
  GET_FLOOR_PLAN_FAIL: '@floorPlan/get/fail',

  DOWNLOAD_FLOOR_PLAN: '@floorPlan/download',
};

export function getFloorPlan() {
  return {
    type: FloorPlanActions.GET_FLOOR_PLAN,
  }
}

export function getFloorPlanSuccess(floorPlan) {
  return {
    type: FloorPlanActions.GET_FLOOR_PLAN_SUCCESS,
    payload: floorPlan,
  }
}

export function getFloorPlanFail(error) {
  return {
    type: FloorPlanActions.GET_FLOOR_PLAN_FAIL,
    payload: error,
  }
}

export function downloadFloorPlan(floorPlan) {
  return {
    type: FloorPlanActions.DOWNLOAD_FLOOR_PLAN,
    payload: floorPlan,
  }
}
