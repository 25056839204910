import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import accessApp from 'assets/media/eden/access-app/access-app.png';
import styles from './styles.module.scss';

export const AccessAppPreview = () => (
  <div>
    <CircularImage
      src={accessApp}
      alt="Access app image"
      className={styles.imageBg}
    />

    <div className={styles.textLine}>
      <FormattedMessage id="entryfy" />
    </div>

  </div>
);
