import { takeLatest, call, put } from 'redux-saga/effects';
import { getLivingInfo, GetLivingInfoActions, getLivingInfoFail, getLivingInfoSuccess } from './actions';
import { getLivingInfoAPI } from './api';
import { LivingInfo } from './models/LivingInfo';

function* getLivingInfoEffect() {
  try {
    const livingInfo = yield call(getLivingInfoAPI);
    yield put(getLivingInfoSuccess(new LivingInfo(livingInfo)));
  } catch (e) {
    if (e.timeout) {
      yield put(getLivingInfo());
    } else {
      yield put(getLivingInfoFail(e.message));
    }
  }
}

export const livingInfoEffects = [
  takeLatest(GetLivingInfoActions.GET_LIVING_INFO, getLivingInfoEffect),
];
