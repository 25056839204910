function validateForm(event, state) {

    let error = false;

    // clear all error messages
    const inputs = document.querySelectorAll('.field.error');
    for (let i = 0; i < inputs.length; i++) {
        if (!inputs[i].classList.contains("error")) {
            inputs[i].classList.remove("error");
        }
    }

    if (state.hasOwnProperty("username") && state.username === "") {
        document.getElementById("username").closest('.field').classList.add('error');
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("firstname") && state.firstname === "") {
        document.getElementById("firstname").closest('.field').classList.add('error');
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("lastname") && state.lastname === "") {
        document.getElementById("lastname").closest('.field').classList.add('error');
        error = true;
        return error;
    }
    if (state.hasOwnProperty("email") && state.email === "") {
        document.getElementById("email").closest('.field').classList.add('error');
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("verificationCode") &&
        state.verificationCode === ""
    ) {
        document.getElementById("verificationcode").closest('.field').classList.add('error');
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("password") && state.password === "") {
        document.getElementById("password").closest('.field').classList.add('error');
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("oldPassword") && state.oldPassword === "") {
        state.setPasswordError("oldPassword");
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("newPassword") && state.newPassword === "") {
        state.setPasswordError("newPassword");
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("confirmPassword") && state.confirmPassword === "") {
        state.setPasswordError("confirmPassword");
        state.setBlankfieldError(true);
        error = true;
        return error;
    }
    if(state.hasOwnProperty("oldPassword") && !new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/gm).test(state.oldPassword)) {
        state.setPasswordError("oldPassword");
        state.setInvalidCurrentPasswordError(true);
        error = true;
        return error;
    }
    if(state.hasOwnProperty("newPassword") && !new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/gm).test(state.newPassword)) {
        state.setPasswordError("newPassword");
        state.setInvalidPasswordError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("password") &&
        state.hasOwnProperty("confirmPassword") &&
        state.password !== state.confirmPassword
    ) {
        state.setPasswordMatchError(true);
        error = true;
        return error;
    }
    if (state.hasOwnProperty("newPassword") &&
        state.hasOwnProperty("confirmPassword") &&
        state.newPassword !== state.confirmPassword
    ) {
        state.setPasswordMatchError(true);
        error = true;
        return error;
    }
}

export default validateForm;
