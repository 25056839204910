import React from 'react';
import { MultiTabMenu } from 'components/MultiTabMenu';
import { Documentation, Messaging } from './components';

export const Messages = () => (
  <MultiTabMenu
    title="messages"
    defaultTab="documents"
    tabs={{
      messages: {
        title: 'messages',
        component: Messaging,
        menus: [],
      },
      documents: { 
        title: 'documents', 
        component: Documentation,
        menus: [] },
    }}
  />
);
