import React from 'react';
import { Onboarding } from 'pages/onboarding/Onboarding';
import { Route } from 'react-router-dom';
import { HQOnboardingRoutes } from './routes';
import { Welcome } from './components/Welcome';
import { CheckYourInfo } from './components/CheckYourInfo';

export const HQOnboarding = () => (
  <Onboarding>
    <Route
      exact 
      path={HQOnboardingRoutes.WELCOME} 
      component={Welcome} 
    />

    <Route
      exact
      path={HQOnboardingRoutes.YOUR_INFO}
      component={CheckYourInfo}
    />
  </Onboarding>
);
